import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditPhysioArrivalConsultation.scss'
import { AyurvedaArrivalConsultationField, PhysioArrivalConsultationField, PhysioArrivalConsultationJSON, TCMArrivalConsultationField } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { Slider } from '@mui/material'
import CheckBox from '../../../../../components/CheckBox/CheckBox'
import RadioButton from '../../../../../components/RadioButton/RadioButton'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="d-flex flex-wrap align-items-stretch justify-content-start" key={content?.data?.summary_assessment_treatment_plan?.objectives?.length}>
            {
                content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                    return (
                        <div className="objective-box p-2" key={subindex}>
                            <div className='border rounded'>
                                <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                    Objective {(subindex+1)?.toString()?.padStart(2, 0)}
                                    <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                        <RemoveBtn/>
                                    </div>
                                </div>
                                <div className="p-3">
                                {
                                    PhysioArrivalConsultationField?.objectives?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="title mt-2">{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                        row={2}
                                                        value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className="p-2 w-50 "  onClick={handleAddMore}>
                <div className="add-more ">
                    Add More Objectives
                </div>
            </div>
        </div>
    )
}


export const AddEditPhysioArrivalConsultation = ({content, setContent, uploadComponent})=>{
  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, PhysioArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }



    return (
      <div className='physio-arrival-consultation-form'>
          <>
          <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By</div>
                <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  />
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="border w-100 mt-5 rounded systematic">
                <div className='p-3'>
                    <div className="sub-heading my-2">
                    Numeric Pain Rating Scale (NPRS) 
                    </div>
                    <hr />
                    <div className="row title">
                        <div className="col-3"></div>
                        <div className="col-3">Pain Level <div className="fw-normal text-muted">(0 to 10: 0 = No Pain, 5 = Moderate Pain, 10 = Worst Possible Pain)</div> </div>
                        <div className="col-6">Remarks</div>
                    </div>
                    <hr />
                    <div className='py-3'>
                        {
                            PhysioArrivalConsultationField?.numericPainRating?.map((item, index)=>{
                                return (
                                    <div className="row ">
                                        <div className="col-3 fw-semi-bold">{item.label}</div>
                                        <div className="col-3 pe-5">
                                        <Slider
                                            aria-label="range"
                                            defaultValue={content?.data?.numeric_pain_rating?.[item.key]?.value || 0}
                                            shiftStep={1}
                                            valueLabelDisplay="auto"
                                            step={1}
                                            onChange={(e, value) =>setKey(["numeric_pain_rating", item.key, "value"], value)}
                                            min={0}
                                            size='small'
                                            marks
                                            max={10}
                                            style={{color: '#74613C'}}
                                            />
                                        </div>
                                        <div className="col-6">
                                        <TextArea
                                        row={2}
                                            placeholder={"Remarks "}
                                            onChange={value =>setKey(["numeric_pain_rating", item.key, "remarks"], value)}
                                            value={content?.data?.numeric_pain_rating?.[item.key]?.remarks || ''}
                                        />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='p-3'>
                    <div className="sub-heading mt-2">
                    Pain Symptom (Highest score1) 
                    </div>
                    <div>
                        {
                            PhysioArrivalConsultationField?.painSymptoms?.map((item, index)=>{
                                return (
                                    <div className='mt-3' key={index}>
                                        <div className="fw-semi-bold">{item.label}</div>
                                        <div>
                                        {
                                            item.options?.map((subItem, subIndex)=>{
                                                return (
                                                    <div className='d-flex' key={subIndex}>
                                                        <RadioButton
                                                            label={subItem}
                                                            name={item.key}
                                                            value={subItem}
                                                            checked={content?.data?.pain_symptom?.[item.key]===subItem}
                                                            onChange={(e)=>{setKey(["pain_symptom", item.key], e.target.value)}}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='px-3 py-0 row'>
                    <div className="col-3 sub-heading mt-2">
                    Activities that relieves symptoms 
                    </div>
                    <div className='col-9'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["activities_that_relives_symptoms"], value)}
                            value={content?.data?.activities_that_relives_symptoms || ''}
                        />
                    </div>
                </div>
                <div className='p-3 row'>
                    <div className="col-3 sub-heading mt-2">
                    Activities that increases symptoms 
                    </div>
                    <div className='col-9'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["activities_that_increases_symptoms"], value)}
                            value={content?.data?.activities_that_increases_symptoms || ''}
                        />
                    </div>
                </div>
                <div className='p-3'>
                    {
                        PhysioArrivalConsultationField?.aboutPain?.map((item, index)=>{
                            return (
                                <div className='mt-4' key={index}>
                                    <div className="fw-semi-bold mb-2">{item.label}</div>
                                    <div>
                                    {
                                        item.options?.map((subItem, subIndex)=>{
                                            return (
                                                <div className='d-flex mb-1' key={subIndex}>
                                                    <RadioButton
                                                        label={subItem}
                                                        name={item.key}
                                                        value={subItem}
                                                        checked={content?.data?.pain_symptom?.[item.key]===subItem}
                                                        onChange={(e)=>{setKey(["pain_symptom", item.key], e.target.value)}}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <div className='p-3'>
                    <div className="sub-heading text-secondary text-uppercase my-2">
                    Pain and Functional Assessment Details
                    </div>
                    <div>
                        {
                            PhysioArrivalConsultationField?.painAndFunctionalAssessment?.map((item, index)=>{
                                return (
                                    <div className=' row' key={index}>
                                        <div className="col-3 sub-heading mt-2">
                                        {item.label}
                                        </div>
                                        <div className='col-9'>
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["pain_and_functional_assessment", item.key], value)}
                                                value={content?.data?.pain_and_functional_assessment[item.key] || ''}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='p-3'>
                        <div className="sub-heading text-uppercase text-secondary my-4">
                        Functional Assessment
                        </div>
                        <div>
                            {
                                PhysioArrivalConsultationField?.functionalAssessment?.map((item, index)=>{
                                    return (
                                        <div className='row' key={index}>
                                            <div className="col-3 sub-heading mt-2">
                                            {item.label}
                                            </div>
                                            <div className='col-9'>
                                                <TextArea
                                                    row={3}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["functional_assessment", item.key], value)}
                                                    value={content?.data?.functional_assessment[item.key] || ''}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                </div>
            </div>
            <div className="border w-100 rounded mt-5 summary">
                <div className="heading py-3">
                    SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='p-3'>
                    <div className="sub-heading py-4">
                        Primary objectives for improvement
                    </div>
                    <Objectives
                        content={content}
                        removeObjective={removeObjective}
                        setKey={setKey}
                        handleAddMore={handleAddMore}
                    />
                </div>

            </div>
          </>
          <div className="w-50">
          {uploadComponent}
          </div>
      </div>
    )
}