import React from 'react';
import * as routes from './route'
import {Navigate} from 'react-router-dom'

const useAuth = () => {
  try {
    const isAuthenticated = localStorage.getItem('IsLogin');
    return Boolean(isAuthenticated === 'true');
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};

const  ProtectedRoute = ({children}) => {
  const auth=useAuth();
  return auth? children : <Navigate to={routes.LOGIN_WITH_MOBILE_ENDPOINT}/>
}

export default ProtectedRoute;