import React, { useEffect, useState } from 'react'
import checkedBtn from '../../../../../assets/images/checked.svg'
import uncheckedBtn from '../../../../../assets/images/unchecked.svg'

const CheckBoxOption = ({ value, onPress, checked }) => {
  const [isChecked, setIsChecked] = useState(checked || false)

  return (
    <div className="value d-flex mb-2" >
      <span className='me-2' style={{cursor: 'pointer'}} onClick={() => {
          setIsChecked((prevState) => !prevState);
          onPress(!isChecked);
        }}>
        {isChecked ? <img src={checkedBtn}/>: <img src={uncheckedBtn}/> }
      </span>
      <label htmlFor="" style={{fontWeight: isChecked? 550 : 400}}>{value}</label>
    </div>
  )
}

function CheckBoxOptions({options, onSelect, answer, detectChange, setDetectChange}) {
  const [selected, setSelected] = useState([])

  const onCheck = (checkedStatus, value) =>{
    let selectedItems = [...selected]
    if (checkedStatus) {
      selectedItems.push(value)
      setSelected(selectedItems)
    } else {
      selectedItems = selectedItems?.filter(e => e !== value)
      setSelected(selectedItems)
    }
    onSelect(selectedItems)
  }

  useEffect(() => {
    if (answer?.options?.length) {
      setSelected(answer?.options);
      onSelect(answer?.options);
    }
  }, [])

  return (
    <div className="check-box-options">
      {
          options.length
          &&
          options?.map((option, index)=>{
            let value = option?.ans || option
            return (
              <CheckBoxOption 
                key={index} 
                value={value} 
                checked={answer?.options?.includes(value)}
                onPress={(checkedStatus)=>{onCheck(checkedStatus, value); setDetectChange(!detectChange)}}
              />
            )
          })
      }
    </div> 
  )
}

export default CheckBoxOptions