import { CLEAR_ACTIVITY_DETAILS, GET_ACTIVITY_DETAILS, GET_ACTIVITY_LIST_BY_DATE, GET_INTERESTED_USER_LIST } from '../../constants';

export function getActivityListByDate(data) {
    return {
        type: GET_ACTIVITY_LIST_BY_DATE,
        data
    };
}

export function getActivityDeatils(data) {
    return {
        type: GET_ACTIVITY_DETAILS,
        data
    };
}

export function clearActivityDeatils(data) {
    return {
        type: CLEAR_ACTIVITY_DETAILS,
        data
    };
}

export function getIntersestedUserList(data) {
    return {
        type: GET_INTERESTED_USER_LIST,
        data
    };
}