import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import SessionsTab from './SessionsTab/SessionsTab';
import './Sessions.scss'
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getDayWiseUserSchedule } from '../../redux/actions/GuestDataActions/SessionsActions';

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Sessions() {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get("tab") ? parseInt(searchParams.get("tab")) : 0
  const [value, setValue] = React.useState(currentTab);
  const location = useLocation();
  const { guestId, userProgramId, type} = location.state;
  const dispatch = useDispatch()


  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "15px",
    fontFamily: "'Montserrat', sans-serif",
  
    "&.Mui-selected": {
      color: "#74613C",
      fontWeight: 600
    }
  });


  const handleTabSelect = (event, index) => {
    setValue(index);
};



  React.useEffect(()=>{
    if(guestId && userProgramId){
        dispatch(getDayWiseUserSchedule({guestId, userProgramId}))
    }
  },[])

  return (
    <>
        <div className='session-container'>
            <div className="container mt-3">
                
                <div className="guests-content">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} 
                            
                              onChange={handleTabSelect} 
                              aria-label="basic tabs example" 
                              variant="fullWidth"
                              TabIndicatorProps={{
                                  style: {
                                  backgroundColor: "#74613C",
                                  }
                              }}
                              >
                            <StyledTab value={0} label="Sessions" {...a11yProps(0)} />
                            <StyledTab value={1} label="Sessions Notes" {...a11yProps(1)} />
                            <StyledTab value={2} label="Sessions Rating" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <SessionsTab currentTab={0} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <SessionsTab currentTab={1}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <SessionsTab currentTab={2} />
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
        </div>
    </>
    
  );
}