import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { viewFullWellbeingReport } from '../../../redux/actions/GuestDataActions/WellbeingReportActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { styled } from "@mui/material/styles";
import './ViewFullWellbeingReport.scss'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Options = ({ data }) => {
  const options = JSON.parse(data);
  return (
      <>
          {options?.data?.length
            ? options?.data?.map((option, optionIndex) => {
                return (
                  <div key={optionIndex}>
                    <span>
                      <span style={{fontWeight: 550}}>{option?.option_tag_name ? `${option.option_tag_name}: ` : '- '}</span>
                      {option?.options?.length
                          ? option?.options?.map(
                            (value, index, { length }) =>
                              <span key={index}>{value} {length - 1 === index ? '' : ' |'} </span>
                        )
                        : ' -'
                      }
                    </span>
                    {option?.user_input?.[0] ?
                        <sapn><br /> - {option?.user_input[0]} </sapn>
                        : null
                    }
                  </div>
                )
              })
              : null
          }
          {options?.any_other_health_condiation ?
            <div style={{fontWeight: 550}}>{"Other"}:
                <span> {options?.any_other_health_condiation}</span>
            </div>
            : null
        }
      </>
  )
}

function ViewFullWellbeingReport() {
  const location = useLocation()
  const [value, setValue] = useState(0);
  const {user_self_assessment_id } = location.state;
  const {fullWellbeingReport, loading} = useSelector(state=> state.wellbeingReportReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",

    "&.Mui-selected": {
      color: "#74613C",
    }
  });

  useEffect(()=>{
    dispatch(viewFullWellbeingReport({is_edit_request: 0,  user_self_assessment_id }))
  },[])
  
  return (
    <div className="view-full-report-container">
      <div className="container h-100">
        <div className="view-full-report-heading">
          View Health Assessment Report
        </div>

        {   
          loading ?
          <div className="loader">
              <CircularProgress sx={{'color': '#74613C'}}/>
          </div>
          :
            
            <div className="pt-4 pb-5">
              <div className="view-full-report-content">
                <Box >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                      ".MuiTabs-scrollButtons.Mui-disabled": {
                        opacity: 0.3
                      }
                    }}
                    TabIndicatorProps={{
                      style: {
                      backgroundColor: "#74613C",
                      }
                    }}
                  >
                    {
                      fullWellbeingReport?.length &&
                      fullWellbeingReport.map((item, index)=>(
                        <StyledTab label={item.section_name} {...a11yProps(index)} key={index}/>
                      ))
                    }
                  </Tabs>
                </Box>
                <Box>
                {
                  fullWellbeingReport?.length &&
                  fullWellbeingReport.map((section, index)=>(
                    <CustomTabPanel value={value} index={index} key={index}>
                      <div className="report-tab">
                        {
                          section.question_answer && section.question_answer.length && section.question_answer.map((item, index)=>(
                            <React.Fragment key={index}>
                              <div className="question-box">
                                <div className="question">
                                  {item.question}
                                </div>
                                <div className="answer">
                                  {
                                    item.answer && item?.answer?.[0] === '{' && item.answer?.at?.(-1) === '}'
                                    ?
                                    <Options data={item.answer}/>
                                    :
                                    <>- {item.answer}</>
                                  }
                                  
                                </div>
                              </div>
                              {(section.question_answer.length-1 !== index) && <div className="hr"></div>}
                            </React.Fragment>
                          ))
                        }
                      </div>
                  </CustomTabPanel>
                  ))
                }
                </Box>
                <div className="go-back" onClick={()=>navigate(-1)} >GET REPORT</div>
              </div>
            </div>
          }
      </div>
    </div>
  )
}

export default ViewFullWellbeingReport