import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary,  } from '@mui/material'
import moment from "moment";
import arrowDownIcon from '../../../assets/images/arrow-down-brown.svg'
import defaultUser from '../../../assets/images/defaultUser.png'
import arrowIcon from '../../../assets/images/arrow.svg'
import reportIcon from '../../../assets/images/report.svg'
import assessmentIcon from '../../../assets/images/assessment.svg'
import dietIcon from '../../../assets/images/prepratory-diet.svg'
import notestIcon from '../../../assets/images/notes.svg'
import sessiontIcon from '../../../assets/images/session.svg'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import getPermissions from "../../../utils/helpers/getPermissions"
import './Guest.scss'
import * as routes from '../../../routes/route'
import { capitalize } from '../../../utils/helpers/universalFunctions';
import { useDispatch, useSelector } from 'react-redux';

const statusClasses=['fade', 'brown', 'green']

function Guest({item, type}) {

    const [toggle, setToggle] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const navigate = useNavigate()
    const checkIn = moment(item?.check_in).format('MMM DD, dddd')
    const checkOut = moment(item?.check_out).format('MMM DD, dddd')
    const nights = moment(item?.check_out).diff(moment(item?.check_in), 'day')
    const [modal, setModal] =useState(false)
    const dispatch = useDispatch()

    const ITEMS = [
        {
            title: 'Pre Arrival Consultation',
            image: assessmentIcon,
            status: item?.is_prelim_assessment_completed,
            path: routes.VIEW_PRELIM_ASSESMENT_ENDPOINT,
            permissions: getPermissions("prelim_assessment"),
            showToNonWellnessUser: false
        },
        {
            title: 'Health Assessment Report',
            image: reportIcon,
            status: item?.is_wellbing_report_completed,
            path: routes.VIEW_WELLBEING_REPORT_ENDPOINT,
            permissions: getPermissions("wellbeing_report"),
            showToNonWellnessUser: false
        },
        {
            title: 'Preparatory Diet',
            image: dietIcon,
            status: item?.is_preparatory_diet_completed,
            path: routes.VIEW_PREPARATORY_DIET_ENDPOINT,
            permissions: getPermissions("preparatory_diet"),
            showToNonWellnessUser: false
        },
        {
            title: 'Consultant Recommendations',
            image: notestIcon,
            status: item?.is_consultation_notes_filed,
            path: routes.VIEW_CONSULTATION_NOTES_ENDPOINT,
            permissions: getPermissions("consultation_notes"),
            showToNonWellnessUser: true
        },
        {
            title: 'Sessions',
            image: sessiontIcon,
            status: item?.is_session_filed,
            path: routes.VIEW_SESSION_ENDPOINT,
            permissions: getPermissions("session_module"),
            showToNonWellnessUser: true
        }
    ]

    const iconNavigation = (path, data)=>{
        let params={ 
            guestId: data?.guest_id, 
            userProgramId: data?.user_program_id, 
            type: type, 
            is_self_assessment_enabled: data?.is_self_assessment_enabled, 
            is_self_assessment_active: data?.is_self_assessment_active
        }
        navigate(path, {state:{...params}})
    }



    useEffect(()=>{
        
        if(item.profile_pic?.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, item.profile_pic);
    
                getDownloadURL(imageRef).then((url) => {
                    setImageUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){
                
            }
        }
  }, [item]);
  
  
  return (
    <div className='guest-container'>
        <div className='guest-data'>
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className='profile-img'>
                        <img src={!item.profile_pic ? defaultUser : imageUrl } alt=""  width={"100%"}/>
                    </div> 
                    <div className='profile-content'>
                        <div className="guest-name">{capitalize(item.full_name)}</div>
                        <div className="guest-order">{item.program_title}</div>
                        <div>
                            <div className='d-flex'>
                                <div className="guest-check">Check-In: &nbsp;</div>
                                <div className="date-time">{checkIn}</div>
                            </div>
                            <div className='d-flex'>
                                <div className="guest-check">Check-Out: &nbsp;</div>
                                <div className="date-time">{`${checkOut} (${nights} Nights)`}</div>
                            </div>  
                            <div className='d-flex'>
                                <div className="guest-check">Room No.: &nbsp;</div>
                                <div className="date-time">{item.room_no || '-'}</div>
                            </div>  
                            <div className='d-flex'>
                                <div className="guest-check">Lead Doctor: &nbsp;</div>
                                <div className="date-time">{item.assign_lead_full_name || '-'}</div>
                            </div>  
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        <Accordion onChange = {(e,expanded) => {
            if(expanded){
                setToggle(true)
            }else{
                setToggle(false)
            }
        }}>
            <AccordionSummary
            expandIcon={<img src={arrowDownIcon}/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            // disabled={!item?.is_self_assessment_enabled}
            >
            <div className='accordian-title'>
                {toggle && <span className='status'>Status: &nbsp;</span> }
                {item?.is_self_assessment_enabled ? item.status : 'Non Wellness Guest'}
            </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="d-flex justify-content-between mb-4 guest-login-data">
                    <div>
                        <span className='title'>Welcome Email Sent: </span>
                        <span>{item?.welcome_email_sent ? moment(item?.welcome_email_sent).format('DD MMM, YYYY') : '-'}</span>
                    </div>
                    <div>
                        <span className='title'>Last Login: </span>
                        <span>{item?.last_login_date ? moment(item?.last_login_date).format('DD MMM, YYYY hh:mm A') : '-'}</span>
                    </div>
                </div>
            <div className='d-flex justify-content-center flex-wrap task-container mb-4'>
               
            {
                ITEMS.map((box, index)=>{
                    return(
                        <React.Fragment key={index}>
                            <div className="icon-box" onClick={box.permissions === "hide" || (!box.showToNonWellnessUser && !item?.is_self_assessment_enabled) ? undefined : ()=>iconNavigation(box.path, item)} >
                                <div className={`icon ${box.permissions === "hide" || (!box.showToNonWellnessUser && !item?.is_self_assessment_enabled) ? 'disable-icon' : ''} ${!box.showToNonWellnessUser && !item?.is_self_assessment_enabled  ? '' : statusClasses[box.status]}`}>
                                    <img src={box.image} alt="" />
                                </div>
                                <div className={`title ${box.permissions === "hide" || (!box.showToNonWellnessUser && !item?.is_self_assessment_enabled) ? "opacity-50": ''}`}>{box.title}</div>
                            </div>

                            {(index !== ITEMS.length -1) && <div className="arrow"> <img src={arrowIcon} width={"20px"} alt="" /></div>}
                        </React.Fragment>
                    )
                })
            }
    
            </div>
            </AccordionDetails>
        </Accordion>
        
    </div>
  )
}

export default Guest