import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditTCMDepartureConsultation.scss'
import { ADD, TCMDepartureConsultationField, TCMDepartureConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import { ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { CircularProgress } from '@mui/material'

const RecomNFollowUp = ({ data, removeObjective, setKey }) => {
    return <div key={data.length}>
        {data.map((item, index) => {
            return (
                <div className="row mb-2" key={index}>
                    <div className='col-2'>
                        <TextArea
                            key={index} 

                            row={2}
                            placeholder={"Enter "}
                            onChange={value => setKey(["recommendations_and_follow_ups", index, "recommendation"], value)}
                            value={item?.recommendation || ''} />
                    </div>
                    <div className='col-4'>
                        <TextArea
                            key={index}
                            row={2}
                            placeholder={"Enter "}
                            onChange={value => setKey(["recommendations_and_follow_ups", index, "details"], value)}
                            value={item?.details || ''} />
                    </div>
                    <div className='col-2 pt-2'>
                        <TimeSelector
                            width={'100%'}
                            ampm={true}
                            time={getDateObjectFromTimeString(item?.time) || ''}
                            setTime={(value) => setKey(["recommendations_and_follow_ups", index, "time"], moment(new Date(value)).format('hh:mm A'))}

                        />
                    </div>
                    <div className='col-2'>
                        <TextArea
                            key={index}
                            row={2}
                            placeholder={"Enter "}
                            onChange={value => setKey(["recommendations_and_follow_ups", index, "duration"], value)}
                            value={item?.duration || ''} />
                    </div>
                    <div className='col-2 align-items-center d-flex'>
                        <div className="flex-grow-1 me-3">
                            <TextArea
                                key={index}
                                row={2}
                                placeholder={"Enter "}
                                onChange={value => setKey(["recommendations_and_follow_ups", index, "frequency"], value)}
                                value={item?.frequency || ''} />
                        </div>
                        <div className='remove-btn' onClick={() => removeObjective(index)}>
                            <RemoveBtn />
                        </div>
                    </div>
                </div>
            )
        })}
    </div>
}

export const AddEditTCMDepartureConsultation = ({ content, setContent, formType, guestId, userProgramId, guestName, uploadComponent }) => {
    const dispatch = useDispatch()
    const { consultationNotesData, loading } = useSelector(state => state.consultationNotesReducer)

    const setKey = (keys, value) => {
        setContent(prev => {
            let updatedData = { ...prev }
            keys.reduce((acc, key, index) => {
                if (index === keys.length - 1) {
                    acc[key] = value;
                } else {
                    if (Array.isArray(acc[key])) {
                        acc[key] = [...acc[key]]
                    } else {
                        acc[key] = { ...acc[key] };
                    }
                }
                return acc[key];
            }, updatedData.data);
            return updatedData;
        })
    }


    const removeObjective = (index) => {
        setContent(prev => {
            const updatedData = {
                ...prev,
                data: {
                    ...prev.data,
                    recommendations_and_follow_ups: prev.data.recommendations_and_follow_ups.filter((_, i) => i !== index)
                }
            };
            return updatedData;
        });
    }

    const handleAddMore = () => {
        setContent(prev => {
            let updatedData = { ...prev }
            updatedData.data.recommendations_and_follow_ups = [...updatedData.data.recommendations_and_follow_ups, TCMDepartureConsultationJSON.data.recommendations_and_follow_ups[0]]
            return updatedData;
        })
    }

    useEffect(() => {
        if (guestId && userProgramId && formType === ADD) {
            dispatch(getConsultationNotes({ guestId, userProgramId, noteType: "Tcm Arrival Consultation" }))
        }
    }, [])

    useEffect(() => {
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if (notes_detail && formType === ADD) { //have dummy JSON
            setContent(prev => {
                let newData = JSON.parse(JSON.stringify(prev));
                newData.data.programme_and_treatment_plan = {
                    "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
                }
                for (let key in notes_detail.data.systemic_assessment.doctor_examination) {
                    newData.data.health_assessment[key].arrival_reading = notes_detail?.data.systemic_assessment.doctor_examination[key]
                }
                return newData;
            })
        }
    }, [consultationNotesData])

    return (
        <div className='tcm-departure-consultation-form'>
            {
                loading ?
                    <div className="loader mx-auto">
                        <CircularProgress sx={{ 'color': '#74613C' }} size={20} />
                    </div>
                    :
                    <>
                        <>
                            <div className="d-flex py-4 mb-2 border-top rounded justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="title text-secondary me-3">Conducted By</div>
                                    <TextField
                                        name={'data.conducted_by'}
                                        width={'300px'}
                                        type={'text'}
                                        placeholder='Enter name'
                                        value={content?.conducted_by || ''}
                                        onChange={e => setContent({ ...content, 'conducted_by': e.target.value })}
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="title text-secondary me-3">Date</div>
                                    <DatePicker
                                        value={content.date ? new Date(content.date) : ''}
                                        onChange={date => setContent({ ...content, 'date': moment(date).format('YYYY-MM-DD') })}
                                        width={'300px'}
                                    />
                                </div>
                            </div>
                            <div className=" w-100 rounded mb-4">
                                <div className="fw-semi-bold">
                                    Dear {guestName},
                                </div>
                                Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s TCM Physician during post-stay online follow-ups.
                            </div>
                            <div className="border w-100 rounded current-dietary-pattern ">
                                <div className="heading py-3">
                                    PROGRAMME & TREATMENT PLAN
                                </div>
                                <div className='p-3'>
                                    {
                                        content?.data?.programme_and_treatment_plan?.objectives?.map((item, index) => {
                                            return (
                                                <div className={index === 0 ? '' : 'mt-5'} key={index}>
                                                    <div className='fw-semibold text-secondary text-uppercase mb-2'> Objective {(index + 1)?.toString()?.padStart(2, 0)}: </div>
                                                    <table className='p-2 w-100'>
                                                        <tbody>
                                                            {
                                                                TCMDepartureConsultationField?.objectives?.map((item, subIndex) => (
                                                                    <tr className='d-flex' key={subIndex}>
                                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{ width: '230px' }}>{item?.label}</td>
                                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="border w-100 mt-5 rounded healthassessment">
                                <div className="heading py-3">
                                    HEALTH ASSESSMENT
                                </div>
                                <div className='p-3'>
                                    <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                                        <div className="mt-2 col-3">Parameter</div>
                                        <div className='col-4'>
                                            On Arrival
                                        </div>
                                        <div className='col-5'>
                                            On Departure
                                        </div>
                                    </div>
                                    {
                                        TCMDepartureConsultationField?.healthAssessment?.map((item, index) => {
                                            return (
                                                <div className="row w-100 mb-3" key={index}>
                                                    <div className="title mt-2 col-3">{item.label}</div>
                                                    <div className='col-4'>
                                                        {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className="data-input">
                                                            <TextArea
                                                                row={3}
                                                                placeholder={"Remarks "}
                                                                onChange={value => setKey(["health_assessment", item.key, "value"], value)}
                                                                value={content?.data?.health_assessment?.[item.key]?.value || ''} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="border w-100 rounded mt-5 ashtavidha">
                                <div className="heading py-3">
                                    RECOMMENDATIONS AND FOLLOW UPS
                                </div>
                                <div className='p-3'>
                                    <div className="border-bottom text-muted fw-semibold mb-3 py-3 row">
                                        <div className="col-2">Recommendations</div>
                                        <div className="col-4">Details</div>
                                        <div className="col-2">Time of practice</div>
                                        <div className="col-2">Duration/Repetition</div>
                                        <div className="col-2">Frequency</div>
                                    </div>
                                    <RecomNFollowUp data={content.data.recommendations_and_follow_ups} removeObjective={removeObjective} setKey={setKey} />
                                    <div>
                                        <div className='cursor-pointer mx-auto' style={{width: 'fit-content'}} onClick={() => handleAddMore()}>
                                            <Add />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                                <div className="heading py-3">
                                    FOLLOW UP PROGRAMME
                                </div>
                                <div className=' '>
                                    <div className='d-flex'>
                                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{ width: '230px' }}>Follow up Consultation:</div>
                                        <div className="text-break flex-grow-1 border p-2 white-space">{'Within 30 days of departure'}</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{ width: '230px' }}>Follow up Visit (Programme):</div>
                                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{ width: '230px' }}>Follow up Visit (Timeline):</div>
                                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <div className="w-50">
                            {uploadComponent}
                        </div>
                    </>
            }
        </div>
    )
}