import moment from 'moment'
import React from 'react'
  

export const ViewPostStayConsultation = ({content, guestName})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{content.conducted_by || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>

            <div className='border rounded overflow-hidden my-3'>
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                PROGRAMME & TREATMENT PLAN
                </div>
                <div className='p-3'>
                    <table className='p-2 w-100'>
                        <tbody>
                        {
                            content?.data?.objectives?.map((item, index)=>{
                                    return (
                                        <tr className='d-flex' key={index}>
                                            <td className=" border fw-semibold text-secondary text-uppercase p-2 mr-2 flex-shrink-0" style={{width: '150px'}}>Objective {(index+1)?.toString()?.padStart(2,0)}: </td>
                                            <td className="text-break border flex-grow-1 p-2 white-space">{item|| '-'}</td>
                                        </tr>
                                    )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='border rounded my-5 overflow-hidden'>
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                    HEALTH ASSESSMENT
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className="p-2 border col-3">Parameter</div>
                    <div className='p-2 border col-4'>
                        On Departure
                    </div>
                    <div className='p-2 border col-5'>
                        At Post Stay Consultation
                    </div>
                </div>
                    {
                        content?.data?.health_assessment?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3">{item?.parameter || '-'}</div>
                                    <div className='border border-bottom-0 p-2 col-4'>
                                        {item?.on_departure || '-'}
                                    </div>
                                    <div className='border border-bottom-0  p-2 col-5'>
                                        {item?.psc || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>

            <div className='border rounded my-5 overflow-hidden'>
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                CONCERNS/ CHALLENGES
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className="p-2 border col-3">Parameter</div>
                    <div className='p-2 border col-4'>
                        Details
                    </div>
                    <div className='p-2 border col-5'>
                        Recommendations/ Changes
                    </div>
                </div>
                    {
                        content?.data?.concerns_challenges?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0  p-2 col-3">
                                        {item?.parameter || '-'}
                                    </div>
                                    <div className='border border-bottom-0  p-2 col-4'>
                                        {item?.details || '-'}
                                    </div>
                                    <div className='border border-bottom-0  p-2 col-5'>
                                        {item?.changes || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>


                
            <div className='border rounded my-5 overflow-hidden'>
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                RECOMMENDATIONS AND FOLLOW UPS FOR NEXT 2 MONTHS
                </div>
                <div className=''>
                    <div className="row m-0 fw-semibold" >
                    <div className="p-2 border col-2">Recommendations</div>
                    <div className="p-2 border col-4">Details</div>
                        <div className='p-2 border col-2'>
                        Time of practice
                        </div>
                        <div className="p-2 border col-2">Duration/Repetition</div>
                        <div className='p-2 border col-2'>
                            Frequency
                        </div>
                    </div>
                    {
                        content?.data?.recommendation_and_follow_ups?.map((item, index)=>{
                            return (
                            <div key={index}>
                                <div className="row m-0 w-100" >
                                    <div className="title border border-bottom-0   p-2 col-2">
                                    {item?.recommendations || '-'}
                                    </div>
                                    <div className='border border-bottom-0   col-4 p-2'>
                                        <div className="data-input">
                                        {item?.details || '-'}
                                        </div>
                                    </div>
                                    <div className='border border-bottom-0   col-2 p-2 '>
                                        {item?.time || '-'}
                                    </div>
                                    <div className='border border-bottom-0   col-2 p-2'>
                                        <div className="data-input">
                                        {item?.duration || '-'}
                                        </div>
                                    </div>
                                    <div className='border border-bottom-0   col-2 p-2'>
                                        <div className="data-input  d-flex align-items-center">
                                        {item?.frequency || '-'}
                                        </div>
                                    </div>
                                </div>
                                <div>
                            </div>
                            </div>
                            )
                        })
                    }
                    </div>
            </div>

            <div className="border rounded my-3 overflow-hidden">
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                FOLLOW UP PROGRAMME
                </div>
                <div className=' '>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{'Within 30 days of departure'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.follow_up_programme?.programme || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.follow_up_programme?.timeline || '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
