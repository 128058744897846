import React from 'react'
import { MenuItem, Select } from '@mui/material';
import InputBox from '../InputBox/InputBox'
import countries from '../../../utils/countries'
import {ReactComponent as ArrowIcon} from '../../../assets/images/arrow-down-grey.svg'
import './PhoneInput.scss'

export default function PhoneInput ({countryData, setCountryData, testID}){

    
    const handleChange = (event) => {
      setCountryData(countries[event.target.value]);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};


  return (
    <div>
      <div className="d-flex w-100">
        <div id='country-select' className='me-2'>
          <Select
            value={countryData.id-1}
            displayEmpty
            onChange={handleChange}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            MenuProps={MenuProps}
            IconComponent={ArrowIcon}
            renderValue={()=> countryData.phonecode}
        >
          
            {countries.map((country, index) => (
                <MenuItem key={index} value={index}>
                  {country.phonecode}
                </MenuItem>

            ))}
          </Select>
        </div>
        <div className='flex-fill'>
          <InputBox type="tel" name="mobileNo" placeholder="Mobile Number" countryCode={countryData.phonecode} testID={testID}/>  
        </div>
      </div>
     
    </div>
  )
}
