import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import downloadBtn from '../../assets/images/downloadIcon.png'
import deleteBtn from '../../assets/images/delete.svg'
import editBtn from '../../assets/images/edit.svg'
import './PreparatoryDiet.scss'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import { CircularProgress } from '@mui/material'
import * as routes from '../../routes/route'
import GuestDetailsHeader from '../../components/GuestDetailsHeader/GuestDetailsHeader'
import {deletePreparatoryDiet, getPreparatoryDiet, updatePreparatoryDietVisibleStatus} from '../../redux/actions/GuestDataActions/PreparatoryDietActions'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import openEyeBtn from '../../assets/images/eye-open.svg'
import closeEyeBtn from '../../assets/images/eye-close.svg'
import ChangeVisibilityModal from '../../components/ChangeVisibilityModal/ChangeVisibilityModal'

function PreparatoryDiet() {
    const location = useLocation();
    const { guestId, userProgramId, type} = location.state;
    const preparatoryDietData = useSelector(state=> state.preparatoryDietReducer)
    const [deleteModal, setDeleteModal] = useState(false)
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const canWrite = (permissions?.preparatory_diet?.permissions || '').includes('write')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [eye, setEye] = useState(false)
    const [visibilityModal, setVisibilityModal] =useState(false)


    const deleteAssessment  = ()=>{
        dispatch(deletePreparatoryDiet({userProgramId, type}))
        setDeleteModal(false)
    }

    const handleEye = ()=>{
        setEye(!eye); 
        dispatch(updatePreparatoryDietVisibleStatus({userProgramId, visibleStatus: !eye}))
        setVisibilityModal(false)
    }

    useEffect(()=>{
        if(guestId && userProgramId){
            dispatch(getPreparatoryDiet({guestId, userProgramId, defaultDietFlag: 0}))
        }
    },[])

    useEffect(()=>{
        if(preparatoryDietData.preparatoryDietData?.is_preparatory_diet_visible){
          setEye(true)
        }
      },[preparatoryDietData.preparatoryDietData])

  return (
        <>
            <div className="view-preparatory-diet-container">
                <div className="container h-100">
                    <div className="view-preparatory-diet-heading">
                        Preparatory Diet
                    </div>

                    {   loading && (guestId !== guestDetails?.user_id || !guestDetails) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                        <div className="pt-4 pb-5">
                            <div className="view-preparatory-diet-content">
                                <GuestDetailsHeader guestId={guestId} />
                                {
                                    preparatoryDietData?.loading ? 
                                    <div className="loader">
                                        <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                                    </div>
                                    :
                                    <>
                                        {
                                            (!preparatoryDietData?.preparatoryDietData?.preparatory_diet) ?
                                            
                                            <div className="guest-add">
                                                {
                                                    canWrite
                                                    ?
                                                    <div data-testid="add-btn" className="add" onClick={()=>navigate(routes.EDIT_PREPARATORY_DIET_ENDPOINT, {state: { guestId, userProgramId , defaultDietFlag: 1, type}})}>
                                                        <img src={downloadBtn} width={40} alt="" />
                                                        <div className='text'>Load Default Diet</div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            :
                                            (preparatoryDietData?.preparatoryDietData) ?

                                            <div className='dietData'>
                                                <div className="last-update">
                                                    <div className='date'>Last Update On: {moment(preparatoryDietData?.preparatoryDietData?.updation_datetime).format('MMM DD, YYYY')}</div>
                                                    {
                                                        canWrite 
                                                        ?
                                                        <div className="icons">
                                                            <div className="vhr"></div>
                                                            <img src={eye? openEyeBtn : closeEyeBtn} alt="eye-btn" className="" onClick={()=>setVisibilityModal(true)}/>
                                                            {/* <img src={editBtn} alt="edit-btn" className="" onClick={()=>navigate(routes.EDIT_PREPARATORY_DIET_ENDPOINT, {state: { guestId, userProgramId , defaultDietFlag: 0, type}})}/> */}
                                                            <img src={deleteBtn} alt="del-btn" className="" onClick={()=>setDeleteModal(true)}/>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>

                                                <div className="hr"></div>

                                                {/* <iframe width={"100%"} height={"100%"} srcDoc={preparatoryDietData?.preparatoryDietData?.preparatory_diet}/> */}
                                                <div dangerouslySetInnerHTML={{__html: preparatoryDietData?.preparatoryDietData?.preparatory_diet}}></div>
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }                
                </div>
            </div>
            <ChangeVisibilityModal
                warnModal={visibilityModal} 
                setWarnModal={setVisibilityModal} 
                onYes={handleEye}
                hide={eye}
                />
            <DeleteModal 
                deleteModal={deleteModal} 
                setDeleteModal={setDeleteModal}
                name="prepratory diet"
                onDelete = {deleteAssessment }
            />
        </>
  )
}

export default PreparatoryDiet