import * as type from '../../constants';

const initialState = {
  userData: null,
  loading: false,
  error: null,
}

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOGIN_REQUEST_START:
      return {
        ...state,
        loading: true,
      }
    case type.LOGIN_REQUEST_END:
      return {
        ...state,
        loading: false,
      }
    case type.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        error: null
      }

    case type.CLEAR_USERDATA:
      return {
        ...state,
        userData: null,
        error: null
      }

    case type.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}