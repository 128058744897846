import React, { useEffect, useState } from 'react'
import defaultUser from '../../../assets/images/defaultUser.png'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import * as routes from '../../../routes/route'

function Guest({item, guestType}) {

    const [imageUrl, setImageUrl] = useState(null);
    const navigate = useNavigate()


    useEffect(()=>{
        
        if(item.profile_pic.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, item.profile_pic);
    
                getDownloadURL(imageRef).then((url) => {
                    setImageUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){
                
            }
        }
  }, [item]);
  
  
  return (
    <div className='searched-guest-container'>
        <div className='guest-data'>
            <div className="d-flex">
                <div className='profile-img'>
                    <img src={!item.profile_pic ? defaultUser : imageUrl } alt=""  width={"100%"}/>
                </div> 
                <div className='profile-content'>
                    <div className="guest-name">{item.first_name} {item.last_name}</div>
                    <div className='d-flex'>
                        <div className="guest-check">Phone Number: &nbsp;</div>
                        <div className="date-time">{item?.phone_number}</div>
                    </div>
                    <div className='text-primary mt-4' style={{fontWeight: 500}} onClick={()=>{navigate(routes.VIEW_PROFILE_ENDPOINT, {state:{user_id: item?.user_id}})}}>View Profile</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Guest