import { 
    ASSESSMENT_SECTIONS,
    DELETE_WELLBEING_REPORT_EDIT_DATA,
    DOWNLOAD_WELLBEING_REPORT_PDF,
    GET_WELLBEING_REPORT_EDIT_DATA,
    OPEN_HEALTH_ASSESSMENT,
    REOPEN_ASSESSMENT_FOR_GUEST,
    UPDATE_WELLBEING_REPORT_DATA,
    VIEW_FULL_WELLBEING_REPORT,
    VIEW_WELLBEING_REPORT, WELLBEING_REPORT_VISIBLE_STATUS
} from '../../constants';


export function viewWellbeingReport(data) {
    return {
        type: VIEW_WELLBEING_REPORT,
        data
    };
}

export function viewFullWellbeingReport(data) {
    return {
        type: VIEW_FULL_WELLBEING_REPORT,
        data
    };
}

export function updateWllbeingReportVisibleStatus(data) {
    return {
        type: WELLBEING_REPORT_VISIBLE_STATUS,
        data
    };
}

export function getWellbeingReportEditData(data) {
    return {
        type: GET_WELLBEING_REPORT_EDIT_DATA,
        data
    };
}

export function delteWellbeingReportEditData() {
    return {
        type: DELETE_WELLBEING_REPORT_EDIT_DATA
    };
}

export function updateWellbeingReport(data) {
    return {
        type: UPDATE_WELLBEING_REPORT_DATA,
        data
    };
}

export function getHealthAssessmentSections() {
    return {
        type: ASSESSMENT_SECTIONS,
    };
}


export function reopenAssessmentForGuest(data) {
    return {
        type: REOPEN_ASSESSMENT_FOR_GUEST,
        data
    };
}

export function downloadWellbeingReport(data) {
    return {
        type: DOWNLOAD_WELLBEING_REPORT_PDF,
        data
    };
}

export function openHealthAssessment(data) {
    return {
        type: OPEN_HEALTH_ASSESSMENT,
        data
    };
}
