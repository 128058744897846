import React from 'react'
import anandaLogo from "../../assets/images/ananda-spa-logo-vector.svg"
import "./FormLayout.scss"

function FormLayout({title, children}) {
  return (
    <div className='container-fluid px-4'>
      <div className="w-100 text-center">
        <div id="form-container">
          <div className="d-flex flex-column align-items-center">
            <img src={anandaLogo} className="logo" alt="" />
            <div className="content shadow-sm mx-2">
              <div className="w-100">
                <div className="form-heading">
                  {title}
                </div>
                <div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormLayout