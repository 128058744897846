import { GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT, GUEST_LIST_ENDPOINT, QUERY_ENDPOINT, SEARCH_ENDPOINT, STATUS_REPORT_ENDPOINT } from "../routes/route";

export const sidebarData = [
    {
        title: "Home",
        path: '/',
        permissionKey: ""
    },
    {
        title: "Guests",
        path: GUEST_LIST_ENDPOINT,
        permissionKey: "guest_listing"
    },
    {
        title: "Group Activity",
        path: GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT,
        permissionKey: "grop_activity_registration"
    },
    {
        title: "Status Report",
        path: `${STATUS_REPORT_ENDPOINT}?guestStatus=1`,
        permissionKey: ""
    },
    {
        title: "Queries",
        path: QUERY_ENDPOINT,
        permissionKey: "wellbeing_queries"
        
    },
    {
        title: "Search",
        path: SEARCH_ENDPOINT,
    }
]