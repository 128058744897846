import React from 'react'
import arrowRight from "../../assets/images/arrow-right.svg"
import arrowLeft from "../../assets/images/arrow-left.svg"

function Button({type, content, arrow, disabled}) {
    const buttonStyle ={
        backgroundColor: "#74613C",
        padding: "13px 20px",
        border: "none",
        outline: "none",
        borderRadius: "10px",
        color: "white",
        textTransform: "uppercase",
        fontSize: "16px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }

  return (
    <>
      <button type={type} style={buttonStyle} disabled={disabled}>
          {arrow==="left" && <img className='pe-2 g' width={"25px"} src={arrowLeft}/>}
          {content}
          {arrow==="right" && <img className='ps-2 g' width={"25px"} src={arrowRight}/>}
      </button>
    </>
  )
}

export default Button