import { call, put, takeEvery } from "redux-saga/effects";
import { OTP_REQUEST_END, OTP_REQUEST_START, SET_VERIFY_SUCCESS, SHOW_VERIFICATION_PAGE, UPDATE_VERIFY_OTP } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";

export const updateVerifyOTPReq = async({data})=>{
    
    const BODY = {
        "email_id": data.email,
        "phone_no": data.phone,
        "OTP": data.otp
      }
        
    return axiosInstance.put(apiEndpoints.UPDATE_MOBILE_VERIFY_OTP, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                console.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateVerifyOTP({data}){
    try{
        yield put({ type: OTP_REQUEST_START })
        let result = yield call(updateVerifyOTPReq, {data})
        if(result.statusCode === 200){
            yield put({ type: OTP_REQUEST_END });
            notify('success', result.message)
            yield put({ type: SET_VERIFY_SUCCESS });
        }
    }catch(error){
    }
}


function* updateVerifyOTPSaga(){
    yield takeEvery(UPDATE_VERIFY_OTP, updateVerifyOTP)
}

export default updateVerifyOTPSaga;