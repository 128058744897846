import React, { useCallback, useEffect, useState } from 'react'
import './AddNotes.scss'
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditorToolbar, { modules, formats }  from '../../../components/EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill';
import { CircularProgress } from '@mui/material';
import Select from "react-select";
import openEyeBtn from '../../../assets/images/eye-open.svg'
import closeEyeBtn from '../../../assets/images/eye-close.svg'
import { notify } from '../../../utils/notification';
import {addConsultationNotes, getNotesTypeList } from '../../../redux/actions/GuestDataActions/ConsultationNotesActions';
import ChangeVisibilityModal from '../../../components/ChangeVisibilityModal/ChangeVisibilityModal';
import UploadButton from '../components/UploadButton/UploadButton';
import { ADD, DEPARTURE, staticNotesType } from '../Forms/NoteFormsConstants';



function AddNotes() {
  const location = useLocation();
  const { guestId, userProgramId , date , guestName} = location.state;

  const [noteType, setNoteType] = useState('Ayurveda Arrival Consultation')
  const [eye, setEye] = useState(false)
  const [content, setContent] = useState('')
  const [files, setFiles] = useState({newFiles: [], uploadedFiles:[]})
  const [visibilityModal, setVisibilityModal] =useState(false)

  const dispatch = useDispatch()
  const navigate= useNavigate()
  const consultationNotesData = useSelector(state=> state.consultationNotesReducer)
  const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;

  const handleProcedureContentChange = (content, delta, source, editor) => {
    editor.getHTML(); // rich text
    editor.getText(); // plain text
    editor.getLength(); // number of characters
    setContent(content);
  };

  const handleEye = ()=>{
    setEye(prev=> !prev);
    setVisibilityModal(false)
}

  const handleSubmit = ()=>{
    if(!content || !noteType){
      notify('warning', 'Please Fill all Fields')
    }else{
      dispatch(addConsultationNotes({
        guestId,
        userProgramId,
        isVisible: eye,
        content : staticNotesType.find(item => item.title === noteType?.label) ? JSON.stringify(content) : content,
        noteType: noteType?.label,
        files,
        date
      }))

    }
  }

  const handleNoteChange=(e)=>{
    let isJSONNote = staticNotesType.find(item => item.title === e?.label)
    setNoteType(prev=>{
      if(isJSONNote)
        setContent((JSON.parse(JSON.stringify(isJSONNote.json))))
      else
        setContent('')
      setFiles({newFiles: [], uploadedFiles:[]})
      return e;
    })
  }

  const handleBlur = useCallback(() => {
      setContent((prevText) => prevText);
  }, [content]);


  useEffect(()=>{
    dispatch(getNotesTypeList())
  }, [])


  return (
    <>
      <div className="add-notes-container">
        <div className="container h-100">
            <div className="add-notes-heading">
              Consultant Recommendations
            </div>

            <div className="pt-4 pb-5">
              <div className="add-notes-content">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div className="heading">Add Note</div>
                  <div className='d-flex align-items-center'>
                    <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                    <button className="edit-btn" type='submit' onClick={handleSubmit}>
                      {
                        consultationNotesData?.updateLoader ?
                        <CircularProgress sx={{'color': '#fff'}} size={20}/>
                        :'Add'
                      }
                    </button>
                  </div>
                </div>

                <div className="form p-3">
                  <div className="d-flex justify-content-between ">
                    <div className='mb-5'>
                      <div className='title mb-2'>Select Note Type</div>
                      <Select
                          className="name-select"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={
                            consultationNotesData?.notesType?.map(item=> ({
                              label: item.title,
                              value: item.title
                            }))?.filter(item => item.label !=='Dietary Notes' || permissions?.dietary_notes?.permissions?.includes('write'))
                          }
                          placeholder='Select'
                          onChange={handleNoteChange}
                      />
                    </div>
                    <div className='visible-btn' onClick={()=>setVisibilityModal(true)}>
                      <img src={eye ? openEyeBtn : closeEyeBtn} alt="eye-btn" className="me-2"/>
                      <span className="title">{eye ? 'Visible to Guest' : 'Hide from Guest'}</span>
                    </div>
                  </div>

                  {
                    (()=>{
                      let isJSONNote = staticNotesType.find(item => item.title === noteType?.label)
                      let uploadComponent = <UploadButton
                        name={noteType?.label?.replace(/\s+/g, '') || 'default'}
                        files={files}
                        setFiles={setFiles}
                      />
                      return (
                        <>
                          {
                            isJSONNote?
                            React.createElement(isJSONNote.component, {
                              content, setContent, 
                              type: isJSONNote.type, 
                              guestId, 
                              userProgramId, 
                              formType: ADD,
                              uploadComponent,
                              guestName
                            })
                            :
                            <div>
                              <EditorToolbar />
                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleProcedureContentChange}
                                onBlur={handleBlur}
                                placeholder='Enter Keywords ...'
                                formats={formats}
                                modules={modules}
                              />
                              {uploadComponent}
                            </div>
                          }
                        </>
                      )
                    })()
                  }
                </div>
                    
              </div>
            </div>
          </div>
          <ChangeVisibilityModal
            warnModal={visibilityModal} 
            setWarnModal={setVisibilityModal} 
            onYes={handleEye}
            hide={eye}
          />
        </div>
      </>
  )
}

export default AddNotes