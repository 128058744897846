import { DietrayNoteField } from '../NoteFormsConstants'
  

export const ViewDietaryNotes = ({content})=>{
    return (
        <div>
        {
            DietrayNoteField?.map((item, index)=>(
                <div className='d-flex mb-3' key={index}>
                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                    <div className="text-break white-space">{content[item.key] || '-'}</div>
                </div>
                ))
        }
        </div>
    )
}
