import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import megaphoneIcon from '../../assets/images/comment.svg'
import './CommentModal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function CommentModal({warnModal, setWarnModal, onYes, text, hide}) {
  const handleClose = () => setWarnModal(false);

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={warnModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="comment-modal">
            <div className="icon">
              <img src={megaphoneIcon} alt="" />
            </div>
            <div className="title text-center my-3">Comment</div>
            <div className="my-3 text">
                {text}
            </div>
              <div className="hr"></div>
              <div className="d-flex justify-content-center">
                <div className="button" onClick={()=>setWarnModal(false)}>Close</div>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}