import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import emailIcon from '../../assets/images/email.svg'
import arrowLeft from '../../assets/images/arrow-left.svg'
import FormLayout from '../../components/From Layout/FormLayout'
import InputBox from '../../components/Form Components/InputBox/InputBox'
import Button from '../../components/Form Components/Button'
import PhoneInput from '../../components/Form Components/PhoneInput/PhoneInput'
import { updateMobileValidation } from '../../validationSchema/emailMobileSchema'
import {update} from '../../redux/actions/AuthActions/UpdateAction'
import './UpdateMobileNo.scss'
import * as routes from '../../routes/route'


function UpdateMobileNo() {
    const navigate = useNavigate()
    const [countryData, setCountryData] = useState({"id":1,"iso":"IN","phonecode":"+91"});
    const dispatch = useDispatch();
    const {otpFor} = useSelector((state)=> state.verificationReducer)

    useEffect(()=>{
      if(otpFor === "update")
        navigate(routes.VERIFICATION_ENDPOINT);
    },[otpFor])


  return (
    <div id="update-form">
      <FormLayout title="Update Mobile Number">
      
        <div className="muted-text">
            Please enter your current mobile number and email address.
        </div>

        <Formik
          initialValues={{ mobileNo: '', email: ''}}
          validationSchema={updateMobileValidation(countryData.iso)}
          onSubmit={(values,  { resetForm })=>{
            let phone_no = `${countryData.phonecode}-${values.mobileNo}`
            dispatch(update({email_id: values.email, phone_no: phone_no}))
            resetForm()
          }}
        >
          {
            (formik)=>{
              return (
                <Form>
                  <div className="input-container">
                    <PhoneInput countryData={countryData} setCountryData={setCountryData}/>
                    <InputBox type="email" name="email" placeholder="Email Address" icon={emailIcon}/>
                  </div>

                  <div className="navigation">
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <span className='back' onClick={()=>navigate(-1)}> 
                          <img src={arrowLeft} width={"15px"} className='me-1' alt="" /> Back
                      </span>
                      <Button content="continue" type="submit" arrow="right" disabled={!(formik.dirty && formik.isValid)}/>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default UpdateMobileNo