import { useEffect } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import { DietrayNoteField } from '../../NoteFormsConstants'
import './AddEditDietaryNotes.scss'

export const AddEditDietaryNotes = ({content, setContent, uploadComponent})=>{
    
    const setDietNote = (key, value)=>{
        setContent(prev=>{
          let data = { ...prev}
          data[key] = value;
          return data;
        })
      }

    return (
        <div className="dietary-notes-form">
        {
            DietrayNoteField?.map((item, index)=>(
              <div className="data-row" key={index}>
                <div className="title">{item.label}</div>
                <div className='data-input'>
                    {
                    item.type==='input'?
                    <div className='input-container'>
                      <TextField 
                        type='text'
                        placeholder='Enter Keywords' 
                        value={content?.[item.key] || ''} 
                        onChange={e=>setDietNote(item.key, e.target.value)}
                      />
                    </div>
                    :
                    <TextArea
                      placeholder={"Enter Keywords"}
                      onChange={value =>setDietNote(item.key, value)}
                      value={content?.[item.key] || ''}/>
                    }
                </div>
              </div>
            ))
        }
        <div className='w-50'>
        {uploadComponent}
        </div>
        </div>
    )
}