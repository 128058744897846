import React from 'react'
import OTPInput from 'react-otp-input';
import './VerficationCodeInput.scss'

function VerficationCodeInput(props) {

  return (
    <div className='otp-input'>
      <OTPInput
        value={props.otp}
        onChange={props.setOTP}
        inputType={"tel"}
        numInputs={5}
        placeholder={"-----"}
        renderSeparator={<></>}
        renderInput={(props) => <input {...props} />}
        shouldAutoFocus={true}
        />
    </div>
  )
}

export default VerficationCodeInput