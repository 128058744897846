import React, { useEffect, useRef, useState } from 'react'
import Header from '../Dashboard Components/Header/Header'
import { Outlet, useLocation } from 'react-router-dom'
import './DashboardLayout.scss'
import { keyboard } from '@testing-library/user-event/dist/keyboard';

function DashboardLayout() {
  const { pathname, key } = useLocation();
  const navRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [windowSize, windowSizeSet] = useState(null)
  const [state, setState] = useState(false)

  const outsideClickHandler = (e)=>{
    if(navRef.current && state && !navRef.current.contains(e.target) && !hamburgerRef.current.contains(e.target)){
        setState(false)
    }
  }
  const toggleBtn =()=>{
    if(windowSize<992){
      if(state){
        setState(false)
      }else{
        setState(true)
      }
    }
  }

  useEffect(()=>{
    windowSizeSet(window.innerWidth)
    if(window.innerWidth <=992){
      document.addEventListener("click", outsideClickHandler);
      return () => {
          document.removeEventListener("click", outsideClickHandler);
        };
    }else{
      setState(true)
    }
  },[state])


  return (
    <div key={key} className='h-100 d-flex flex-column'>
      <Header toggleBtn={toggleBtn} state={state} navRef={navRef} hamburgerRef={hamburgerRef}/>
      <div className='main-container flex-grow-1'>
        <Outlet/>
      </div>
    </div>
  )
}

export default DashboardLayout