import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_FAILURE, OTP_REQUEST_START, OTP_REQUEST_END, LOGIN_RESEND_OTP } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";

export const loginResendOTPReq = async({data})=>{

    const BODY = data.otp_for === "email" ?
    { otp_for: data.otp_for, email_id: data.email_id} :
    { otp_for: data.otp_for, phone_no: data.phone_no}


    return axiosInstance.post(apiEndpoints.RESEND_OTP, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                console.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* loginResendOTP({data}){
    yield put({ type: OTP_REQUEST_START })
    try{
        let result = yield call(loginResendOTPReq, {data})

        if(result.statusCode === 200){
            yield put({ type: OTP_REQUEST_END });
            notify('success', result.message)        
        }
    }catch(error){
        yield put({ type: LOGIN_FAILURE, message: error.message });
    }
}


function* loginResendOTPSaga(){
    yield takeEvery(LOGIN_RESEND_OTP, loginResendOTP)
}

export default loginResendOTPSaga;