import * as type from '../../constants';

const initialState = {
  logoutData: null,
  loading: false,
  error: null,
}

export default function logoutReducer(state = initialState, action) {
  switch (action.type) {
    case type.LOGOUT_REQUEST_START:
      return {
        ...state,
        loading: true,
      }
    case type.LOGOUT_REQUEST_END:
      return {
        ...state,
        loading: false,
      }
    case type.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        logoutData: action.payload
      }
    case type.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}