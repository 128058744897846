import React, { useCallback, useEffect, useState } from 'react'
import './EditPreparatoryDiet.scss'
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditorToolbar, { modules, formats }  from '../../../components/EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill';
import { getPreparatoryDiet, updatePreparatoryDiet } from '../../../redux/actions/GuestDataActions/PreparatoryDietActions';
import { CircularProgress } from '@mui/material';
import { notify } from '../../../utils/notification';

function EditPreparatoryDiet() {
  const location = useLocation();
  const { guestId, userProgramId , defaultDietFlag, type } = location.state;
  const [content, setContent] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const preparatoryDietData = useSelector(state=> state.preparatoryDietReducer)

  const handleProcedureContentChange = (content, delta, source, editor) => {
    editor.getHTML(); // rich text
    editor.getText(); // plain text
    editor.getLength(); // number of characters
    setContent(content);
  };

  const handleSubmit = ()=>{
    if(!content){
      notify('warning', 'Please Fill all Fields')
    }else{
      dispatch(updatePreparatoryDiet({
        userProgramId,
        content,
        type
      }))
    }
  }

  const handleBlur = useCallback(() => {
      setContent((prevText) => prevText);
  }, [content]);

  useEffect(()=>{
    if(guestId && userProgramId){
        dispatch(getPreparatoryDiet({guestId, userProgramId, defaultDietFlag}))
    }
  },[])

  useEffect(()=>{
    if(preparatoryDietData?.preparatoryDietData?.preparatory_diet){
      setContent(preparatoryDietData?.preparatoryDietData?.preparatory_diet)
    }
  },[preparatoryDietData?.preparatoryDietData])

  return (
    <>
      <div className="edit-preparatory-diet-container">
        <div className="container h-100">
            <div className="edit-preparatory-diet-heading">
              Preparatory Diet
            </div>

            {
              preparatoryDietData?.loading ?
              <div className="loader">
                  <CircularProgress sx={{'color': '#74613C'}}/>
              </div>
              :

              <div className="pt-4 pb-5">
                  <div className="edit-preparatory-diet-content">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <div className="heading">Update Diet</div>
                      <div>
                        <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                        <button className="edit-btn" type='submit' 
                          onClick={handleSubmit}
                        >
                          {
                            preparatoryDietData?.updateLoader ?
                            <CircularProgress sx={{'color': '#fff'}} size={15}/>
                            :'UPDATE'
                          }
                        </button>
                      </div>
                    </div>

                    <div className="form">
                        <div>
                          <EditorToolbar />
                          <ReactQuill
                            theme="snow"
                            value={content}
                            onChange={handleProcedureContentChange}
                            onBlur={handleBlur}
                            placeholder='Enter Keywords ...'
                            formats={formats}
                            modules={modules}
                          />
                        </div>
                    </div>

                  </div>
                </div>
            }
          </div>
        </div>
      </>
  )
}

export default EditPreparatoryDiet