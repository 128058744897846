import {
    CLEAR_SEARCH_GUEST_LIST_RESULT,
    GET_GUEST_LIST, GUEST_NAME_LIST, SEARCH_GUEST_LIST_RESULT, VIEW_GUEST_PROFILE
} from '../../constants';


export function getGuestList(data) {
    return {
        type: GET_GUEST_LIST,
        data
    };
}

export function getGuestNameList(data) {
    return {
        type: GUEST_NAME_LIST,
        data
    };
}

export function getSearchedGuestListResult(data) {
    return {
        type: SEARCH_GUEST_LIST_RESULT,
        data
    };
}


export function clearSearchedGuestListResult(data) {
    return {
        type: CLEAR_SEARCH_GUEST_LIST_RESULT,
        data
    };
}

export function viewGuestProfile(data) {
    return {
        type: VIEW_GUEST_PROFILE,
        data
    };
}
