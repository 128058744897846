import { call, put, takeEvery } from "redux-saga/effects";
import { CLEAR_USERDATA, LOGOUT, LOGOUT_FAILURE, LOGOUT_REQUEST_END, LOGOUT_REQUEST_START, LOGOUT_SUCCESS } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";

export const logoutReq = async()=>{   
    return axiosInstance.get(apiEndpoints.LOGOUT_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                console.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* logout(){
    yield put({ type: LOGOUT_REQUEST_START })
    try{
        yield put({ type: CLEAR_USERDATA});
        let result = yield call(logoutReq)
        yield put({ type: LOGOUT_REQUEST_END }); 

        if(result.statusCode === 200){
            yield put({ type: LOGOUT_SUCCESS, payload: result});  
        }else{
            notify('error', "Unable to logout")
        }
            
    }catch(error){
        yield put({ type: LOGOUT_FAILURE, message: error.message });
    }
}


function* logoutSaga(){
    yield takeEvery(LOGOUT, logout)
}

export default logoutSaga;