import { GUEST_DETAILS, UPDATE_GUEST_DOB } from '../../constants';


export function getGuestDetails(data) {
    return {
        type: GUEST_DETAILS,
        data
    };
}

export function updateGuestDOB(data) {
    return {
        type: UPDATE_GUEST_DOB,
        data
    };
}