import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axiosInstance from "./../../../axios/axiosInstance";
import { notify } from "./../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";



export const getWellbeingReportReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.VIEW_WELLBEING_REPORT_API}?user_id=${parseInt(data.guestId)}&user_program_id=${parseInt(data.userProgramId)}${data?.user_self_assessment_id ? `&user_self_assessment_id=${data?.user_self_assessment_id}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getWellbeingReport({data}){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(getWellbeingReportReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.WELLBEING_REPORT_SUCCESS, data, payload: result.data});
        }else{
            yield put({ type: types.WELLBEING_REPORT_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.WELLBEING_REPORT_FAILURE }); 
    }
}



export const updateWellbeingReportVisibleStatusReq = async({data})=>{
    const BODY = {
        "user_self_assessment_id": data.user_self_assessment_id,
        "visible_status": data.visible_status ? 1 : 0
    }
    return axiosInstance.put(apiEndpoints.WELLBEING_REPORT_VISIBLE_STATUS_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateWellbeingReportVisibleStatus({data}){
    try{
        yield call(updateWellbeingReportVisibleStatusReq, {data})
    }catch(error){
    }
}

export const viewFullAssessmentReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.VIEW_FULL_WELLBEING_REPORT_API}?is_edit_request=${data.is_edit_request}&user_self_assessment_id=${parseInt(data.user_self_assessment_id )}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* viewFullAssessment({data}){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(viewFullAssessmentReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.FULL_WELLBEING_REPORT_SUCCESS, payload: result.data});
        }else{
            yield put({ type: types.FULL_WELLBEING_REPORT_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.FULL_WELLBEING_REPORT_FAILURE }); 
    }
}

export const getWellbeingReportEditDataReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_WELLBEING_REPORT_EDIT_DATA_API}?is_edit_request=${data.is_edit_request}&user_self_assessment_id=${parseInt(data.user_self_assessment_id )}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getWellbeingReportEditData({data}){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(getWellbeingReportEditDataReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_WELLBEING_REPORT_EDIT_DATA_SUCCESS, payload: result.data});
        }else{
            yield put({ type: types.GET_WELLBEING_REPORT_EDIT_DATA_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.GET_WELLBEING_REPORT_EDIT_DATA_FAILURE }); 
    }
}

export const updateWellbeingReportDataReq = async({data})=>{
    const BODY = {
        "user_id": data.user_id,
        "user_self_assessment_id": data.user_self_assessment_id,
        "section_id": data.section_id,
        "position": data.position,
        "update_assessment": data.update_assessment
      }

    return axiosInstance.put(apiEndpoints.UPDATE_WELLBEING_REPORT_DATA_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateWellbeingReportData({data}){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(updateWellbeingReportDataReq, {data})
        if(result.statusCode === 200){
            if(result.data.final_submit)
                yield put({ type: types.FINAL_UPDATE_WELLBEING_REPORT_DATA_SUCCESS});
            else
                yield put({ type: types.UPDATE_WELLBEING_REPORT_DATA_SUCCESS, payload: result.data});
        }else{
            yield put({ type: types.UPDATE_WELLBEING_REPORT_DATA_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.UPDATE_WELLBEING_REPORT_DATA_FAILURE }); 
    }
}

function* deleteWellbeingReportEditData(){
    yield put({ type: types.CLEAR_WELLBEING_REPORT_EDIT_DATA })
}

export const getAssessmentSectionReq = async()=>{

    return axiosInstance.get(apiEndpoints.ASSESSMENT_SECTIONS_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getAssessmentSection(){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(getAssessmentSectionReq)
        if(result.statusCode === 200){
            yield put({ type: types.SET_ASSESSMENT_SECTIONS, payload: result.data});
        }else{
            yield put({ type: types.UPDATE_WELLBEING_REPORT_DATA_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.UPDATE_WELLBEING_REPORT_DATA_FAILURE }); 
    }
}

export const reopenAssessmentReq = async({data})=>{
    const BODY = {
        "user_program_id": data.user_program_id,
        "guest_user_id": data.guest_user_id,
        "flag": data.flag,
        "fill_assessment_bydate": data.fill_assessment_bydate,
        "reopened_sections_ids": data.reopened_sections_ids,
        "reopened_sections_positions": data.reopened_sections_positions
      }

      return axiosInstance.post(apiEndpoints.REOPEN_ASSESSMENT_FOR_GUEST_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                notify('warning', errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* reopenAssessment({data}){
    try{
        yield put({ type: types.WELLBEING_REPORT_LOADING })
        let result = yield call(reopenAssessmentReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.CLEAR_WELLBEING_REPORT })
            window.history.back();
            window.history.back();
            notify('success', result.message)
        }else{
            yield put({ type: types.WELLBEING_REPORT_FAILURE }); 
        }    
    }catch(error){
        yield put({ type: types.WELLBEING_REPORT_FAILURE }); 
    }
}


export async function downloadWellbeingReportReq(action) {
        return axiosInstance.get(`${apiEndpoints.DOWNLOAD_WELLBEING_REPORT_PDF_API}?user_id=${action.data?.guestId}&user_program_id=${action.data?.userProgramId}${action.data?.user_self_assessment_id? `&user_self_assessment_id=${action.data.user_self_assessment_id}`: ''}`, {
            responseType: 'arraybuffer',
        })
            .then(response => {
                return response;
            }).catch((errors) => {
                console.log("errors", errors);
                if (errors.response?.data.statusCode === 400) {
                    console.log(errors.response.data.message);
                } else if(errors.response.data.statusCode === 401){
                    logOut();
                } else {
                    notify('warning', "Something went wrong");
                }
                return errors
            })
    }
    

function* downloadWellbeingReport(action) {
    try {
        const result = yield call(downloadWellbeingReportReq, action);
        if (result.data instanceof ArrayBuffer) {
            action.data?.onSuccessCallback?.(result.data)
            yield put({ type: types.DOWNLOAD_WELLBEING_REPORT_PDF_SUCCESS }); 

        }else{
            notify('Unable to download sheet.')
            yield put({ type: types.DOWNLOAD_WELLBEING_REPORT_PDF_FALIURE }); 

        }
    } catch (e) {
        yield put({ type: types.DOWNLOAD_WELLBEING_REPORT_PDF_FALIURE }); 

    }
}


export const openHealthAssessmentReq = async({data})=>{
    const BODY = { 
        "user_program_id" : data.userProgramId, 
        "status" : data.status
    }

    return axiosInstance.put(apiEndpoints.OPEN_HEALTH_ASSESSMENT_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* openHealthAssessment({data}){
    try{
        let result = yield call(openHealthAssessmentReq, {data})
        if(result.statusCode === 200){
            notify('success', 'Health Assessment opened successfully')
            yield put({ type: types.OPEN_HEALTH_ASSESSMENT_SUCCESS, payload: result.data});
            data?.onSuccessCallback?.();
        }else{
            yield put({ type: types.OPEN_HEALTH_ASSESSMENT_FALIURE }); 
        }    
    }catch(error){
        yield put({ type: types.OPEN_HEALTH_ASSESSMENT_FALIURE }); 
    }
}


function* wellbeingReportSaga(){
    yield takeLatest(types.VIEW_WELLBEING_REPORT, getWellbeingReport)
    yield takeEvery(types.WELLBEING_REPORT_VISIBLE_STATUS, updateWellbeingReportVisibleStatus)
    yield takeEvery(types.VIEW_FULL_WELLBEING_REPORT, viewFullAssessment)
    yield takeEvery(types.GET_WELLBEING_REPORT_EDIT_DATA, getWellbeingReportEditData)
    yield takeEvery(types.UPDATE_WELLBEING_REPORT_DATA, updateWellbeingReportData)
    yield takeEvery(types.DELETE_WELLBEING_REPORT_EDIT_DATA, deleteWellbeingReportEditData)
    yield takeEvery(types.ASSESSMENT_SECTIONS, getAssessmentSection)
    yield takeEvery(types.REOPEN_ASSESSMENT_FOR_GUEST, reopenAssessment)
    yield takeEvery(types.DOWNLOAD_WELLBEING_REPORT_PDF, downloadWellbeingReport)
    yield takeEvery(types.OPEN_HEALTH_ASSESSMENT, openHealthAssessment)
}


export default wellbeingReportSaga;