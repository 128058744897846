import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import GuestTab from './GuestTab/GuestTab';
import searchIcon from '../../assets/images/search.svg'
import chatIcon from '../../assets/images/chat.png'
import groupIcon from '../../assets/images/group.svg'
import './GuestList.scss'
import { Link } from 'react-router-dom';
import { GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT, QUERY_ENDPOINT, SEARCH_ENDPOINT, STATUS_REPORT_ENDPOINT } from '../../routes/route';
import Select from "react-select";
import { getGuestList } from '../../redux/actions/GuestListActions/GuestListAction';
import { useDispatch, useSelector } from 'react-redux';
import getPermissions from '../../utils/helpers/getPermissions';

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Guests() {
  const currentTab = [0, 1, 2, 3].includes(parseInt(localStorage.getItem('activeGuestListTab'))) ? parseInt(localStorage.getItem('activeGuestListTab')) : (getPermissions("tentative_guest") === "full_write" ? 0 : 1)
  const [value, setValue] = React.useState(currentTab);

  const assignedTo = useSelector(state=> {
    return currentTab === 0 && getPermissions("tentative_guest") === "full_write"
      ? state.guestListReducer.tentativeGuest.assigned_to
      :currentTab === 1
        ? state.guestListReducer.upcomingGuest.assigned_to
        : currentTab === 2
            ? state.guestListReducer.activeGuest.assigned_to
            : state.guestListReducer.completedGuest.assigned_to
  })
  // const [assignedTo, setAssignedTo] = React.useState(defaultAssignedTo);

  const dispatch = useDispatch()

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",

    "&.Mui-selected": {
      color: "#74613C",
    }
  });

  const assignedOptions=[
    {label: 'All', value: 0},
    {label: 'Assigned To Me', value: 1},
  ]

  const handleTabSelect = (event, index) => {
    // setAssignedTo(0)
    localStorage.setItem('activeGuestListTab', index);
    setValue(index);
};


  return (
    <>
        <div className='guests-container'>
            <div className="container">
                <div className="guest-heading">
                    <div>Guests</div>

                    <div className='float-right'>
                      <div className="filter d-flex m-2 justify-content-end">
                        <div className="title me-5">Assigned To:</div>
                        {/* <div className="value d-flex">
                            <div className='d-flex mb-1 checkbox-input'>
                                <div>
                                    <input type="radio" name="" id="" 
                                        className='me-2' 
                                        checked={assignedTo === 0 ? true : false}
                                        onChange={()=>{setAssignedTo(0);}}
                                        data-testid="all_sections"
                                    />
                                </div>
                                <label htmlFor="" className={assignedTo === 0 ? "fw-600" : "fw-400"}>All</label>
                            </div>
                            <div className='d-flex mb-1 checkbox-input'>
                                <div>
                                    <input type="radio" name="" id="" 
                                        className='me-2' 
                                        checked={assignedTo === 1 ? true : false}
                                        onChange={()=>{setAssignedTo(1);}}
                                        data-testid="active_sections"
                                    />
                                </div>
                                <label htmlFor="" className={`text-nowrap ${assignedTo === 1 ? "fw-600" : "fw-400"}`}>Assigned To Me</label>
                            </div>
                        </div> */}
                        <Select
                          className="name-select"
                          classNamePrefix="select"
                          // isLoading={isLoading}
                          isSearchable={true}
                          name="color"
                          options={assignedOptions}
                          placeholder='Select'
                          onChange={(e)=>{
                            // setAssignedTo(e.value);    
                            if(e.value !==assignedTo)
                              dispatch(getGuestList({filter_type: currentTab-1, page: 1, assignedTo: e.value, filterChange: true}))
                        }}
                          value={{
                            label: assignedOptions?.find(item=> item.value=== assignedTo)?.label,
                            value: assignedTo
                          }}
                      />
                      </div>
                    </div>
                </div>
                <div className="guests-content">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} 
                            
                              onChange={handleTabSelect} 
                              aria-label="basic tabs example" 
                              variant="fullWidth"
                              TabIndicatorProps={{
                                  style: {
                                  backgroundColor: "#74613C",
                                  }
                              }}
                              >
                            {getPermissions("tentative_guest") === "full_write" && <StyledTab value={0} label="Tentative" {...a11yProps(0)} />}
                            <StyledTab value={1} label="UpComing" {...a11yProps(1)} />
                            <StyledTab value={2} label="Active" {...a11yProps(2)} />
                            <StyledTab value={3} label="Completed" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <GuestTab index={0} type={"tentative"}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <GuestTab index={1} type={"upcoming"}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <GuestTab index={2} type={"active"}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <GuestTab index={3} type={"completed"}/>
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
        </div>
    </>
    
  );
}