import React from 'react'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import styled from "@emotion/styled";
import { outlinedInputClasses } from '@mui/material';
import dayjs from 'dayjs';


const TimePickerStyled = styled(TimePicker)(({ height, width }) => ({
    '.MuiStack-root': {
        padding: 0
    },
    '.MuiInputBase-root':{
        fontSize: '13px',
        borderRadius: '10px',
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: '#F1F1F1',
        display: 'flex',
        alignItems: 'center',
        height: height || '38px',
        border: '1px solid #DDDDDD',

    },
    '.Mui-focused':{
        outline: 'none',
        border: '1px solid #cccccc',
        
    },

    [`.${outlinedInputClasses.root}`]: {
        width: width || '208px',
        
    },
    '& .MuiInputLabel-root': { 
        lineHeight: 1 
    },

    '.MuiButtonBase-root':{
        padding: '3px',
    },

    'input': {
        padding: 0,
        paddingLeft: '9px',
    },
    'input::placeholder': {
        color: '#AAAAAA !important'
    },
    
    
    'fieldset': {
        border: 'none',
    },
                    
    '.MuiOutlinedInput-notchedOutline:hover':{
        border: 'none'
    }

}))

export function TimeSelector ({width, height, ampm=false, name, minTime, maxTime, time, setTime, minutesStep, isDisabled}) {

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                    sx={{padding: 0, '.MuiTextField-root':{minWidth: '100px !important'}}}
                    components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                >
                    <TimePickerStyled
                        ampm={ampm}
                        value={dayjs(time)}
                        views={['hours', 'minutes']}   
                        {...(minTime && {minTime: dayjs(minTime)})}
                        {...(maxTime && {maxTime: dayjs(maxTime)})}
                        closeOnSelect={false}                             
                        minutesStep={minutesStep}
                        selectedSections={null}
                        timeSteps={{ hours: 1, minutes: minutesStep || 1, seconds: 1 }}
                        height={height}
                        width={width}
                        disabled={isDisabled}
                        slotProps={{
                            popper: {
                            sx: {
                                boxShadow: 'none',
                                "& .MuiList-root": {
                                },
                                "& .MuiMenuItem-root": {
                                    "&.Mui-selected": {
                                        backgroundColor: '#74613C'
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: '#74613C',
                                        color: 'white'
                                    },
                                },
                                "& .MuiMenuItem-root:hover": {
                                    color: 'black',
                                    backgroundColor: "#FDF2F3"
                                },
                                "& .MuiDialogActions-root button": {
                                    color: '#74613C'
                                },
                            },
                            },
                        }}
                        onChange={(value)=>{
                            setTime(value)
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <div className={`text-red-500 text-xs italic h-[16px]`}>
            </div>
        </div>
    )
}