import React, { useEffect, useState } from 'react'

function RadioButton({options, onSelect, answer, detectChange, setDetectChange}) {
  const [selected, setSelected] = useState(answer || '')

  useEffect(() => {
    if(answer){
      let realAnswer = options?.find(item => item === answer || item.ans === answer)
      setSelected(answer)
      onSelect(realAnswer || answer);
    }
  }, [])

  return (
    <div>
      {
          options.length
          &&
          options?.map((option, index)=>{
            let value = option?.ans || option

              return (
              <div className='d-flex mb-1' key={index}>
                <div>
                  <input type="radio" name="" id="" 
                    style={{accentColor: '#74613C', marginTop: "3px"}} className='me-2' 
                    checked={selected === value ? true : false}
                    onChange={()=>{
                      onSelect(option)
                      setSelected(value)
                      setDetectChange(!detectChange)
                    }}
                    
                  />
                </div>
                <label htmlFor="" style={{fontWeight: selected === value ? 600 : 400}}>{value}</label>
              </div>
            )
          })
      }
    </div>
  )
}

export default RadioButton