import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FormLayout from '../../components/From Layout/FormLayout'
import Button from '../../components/Form Components/Button'
import VerficationCodeInput from '../../components/Form Components/VerficationCodeInput/VerficationCodeInput'
import { loginResendOTP, loginVerifyOTP } from '../../redux/actions/AuthActions/LoginAction'
import { updateResendOTP, updateVerifyOTP } from '../../redux/actions/AuthActions/UpdateAction'
import { removeVerificationData } from '../../redux/actions/AuthActions/VerificationAction'
import './Verification.scss'
import * as routes from '../../routes/route'

function Verification() {
  const {otpFor, phone, email, success} = useSelector(state=> state.verificationReducer)
  const {userData, error} = useSelector(state=> state.loginReducer)
  const isLogin = localStorage.getItem("IsLogin")

  const [otp, setOTP] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const handleSubmit = (e)=>{
    e.preventDefault()
    if(otpFor==="email"){
      dispatch(loginVerifyOTP({otpFor, email, otp}))
    }else if(otpFor==="phone"){
      dispatch(loginVerifyOTP({otpFor, phone, otp}))
    }else{
      dispatch(updateVerifyOTP({otpFor:"update", email, phone, otp}))
    }
    setOTP('')
  }

  const resendOTP = ()=>{
    if(otpFor==="email"){
      dispatch(loginResendOTP({otp_for: "email", email_id: email}))
    }else if(otpFor==="phone"){
      dispatch(loginResendOTP({otp_for: "phone", phone_no: phone}))
    }else{
      dispatch(updateResendOTP({email, phone}))
    }
  }

  const changeButton = ()=>{
    navigate(-1)
  }

  useEffect(()=>{
    if(userData || isLogin === "true"){
      navigate("/")
    }else if(!otpFor && !userData){
      if(success)
        navigate(routes.LOGIN_WITH_MOBILE_ENDPOINT)
      else
        navigate(-1)
    }

    return()=>{
      dispatch(removeVerificationData())
    }
    
  },[otpFor, userData, error])

  
  if(isLogin==='true')
    return null;
  
  return (
    <div id="verification-form">
      <FormLayout title="Verification Code">
      
      <div className="muted-text">
        {
          (otpFor === "email" || otpFor === "update") && 
          <> 
            Please enter verification code sent to your email address ({email}) &nbsp;
            <a className='link-tag' href="#" onClick={changeButton}>change</a>
          </>}
        {
          otpFor === "phone" && 
          <>
            Please enter verification code sent to your mobile number ({phone}) &nbsp;
            <a className='link-tag' href="#" onClick={changeButton}>change</a>
          </>
        }
        </div>

        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="mx-auto">
              <VerficationCodeInput otp={otp} setOTP={setOTP}/>
            </div>
          </div>

          <div className="navigation">
              <div className="d-flex justify-content-between align-items-center mt-3">
                <a href="#"  className='link-tag' onClick={resendOTP}>Resend OTP</a>
                <Button content="verify" type="submit" arrow="none" disabled={otp.length === 5 ? false : true}/>
              </div>
          </div>
        </form>
        
      </FormLayout>
    </div>
  )
}

export default Verification