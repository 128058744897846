import {GET_STATUS_REPORT_FILTER_LIST, GET_WELLNESS_STATUS_REPORT} from '../../constants'

export function getWellnessStatusReport(data) {
    return {
        type: GET_WELLNESS_STATUS_REPORT,
        data
    };
}


export function getStatusReportFilterList(data) {
    return {
        type: GET_STATUS_REPORT_FILTER_LIST,
        data
    };
}