import React from 'react'
import './YesNoComponent.scss'
import { Field } from 'formik'

function YesNoComponent({name, handleClick, handleClickYes=undefined, yesTestID, noTestID}) {
  return (
    <div className="yes-no-radio-input d-flex">
        <div className="radio" onClick={handleClickYes}>
            <Field type="radio" data-testid={yesTestID} name={name} value={'1'} />
            <label htmlFor="male"> Yes </label>
        </div>
        <div className="radio">
            <Field type="radio" data-testid={noTestID} name={name} value={'0'}
              onClick={handleClick}
            />
            <label htmlFor="female"> No</label>
        </div>

    </div>
  )
}

export default YesNoComponent