import { 
    DEL_PREPARATORY_DIET_DATA, 
    GET_PREPARATORY_DIET_DATA, 
    UPDATE_PREPARATORY_DIET_DATA, 
    UPDATE_PREPARATORY_DIET_VISIBLE_STATUS
} from '../../constants';


export function getPreparatoryDiet(data) {
    return {
        type: GET_PREPARATORY_DIET_DATA,
        data
    };
}


export function updatePreparatoryDiet(data) {
    return {
        type: UPDATE_PREPARATORY_DIET_DATA,
        data
    };
}

export function deletePreparatoryDiet(data) {
    return {
        type: DEL_PREPARATORY_DIET_DATA,
        data
    };
}

export function updatePreparatoryDietVisibleStatus(data) {
    return {
        type: UPDATE_PREPARATORY_DIET_VISIBLE_STATUS,
        data
    };
}