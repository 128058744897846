// Country model:
// [
//    Country name,
//    Regions,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]
//
// Regions:
// ['america', 'europe', 'asia', 'oceania', 'africa']
//
// Sub-regions:
// ['north-america', 'south-america', 'central-america', 'carribean',
//  'eu-union', 'ex-ussr', 'ex-yugos', 'baltic', 'middle-east', 'north-africa']

const countries = [
  {"id":1,"iso":"IN","phonecode":"+91"},
  {"id":2,"iso":"AL","phonecode":"+355"},
  {"id":3,"iso":"DZ","phonecode":"+213"},
  {"id":4,"iso":"AS","phonecode":"+1684"},
  {"id":5,"iso":"AD","phonecode":"+376"},
  {"id":6,"iso":"AO","phonecode":"+244"},
  {"id":7,"iso":"AI","phonecode":"+1264"},
  {"id":8,"iso":"AQ","phonecode":"+0"},
  {"id":9,"iso":"AG","phonecode":"+1268"},
  {"id":10,"iso":"AR","phonecode":"+54"},
  {"id":11,"iso":"AM","phonecode":"+374"},
  {"id":12,"iso":"AW","phonecode":"+297"},
  {"id":13,"iso":"AU","phonecode":"+61"},
  {"id":14,"iso":"AT","phonecode":"+43"},
  {"id":15,"iso":"AZ","phonecode":"+994"},
  {"id":16,"iso":"BS","phonecode":"+1242"},
  {"id":17,"iso":"BH","phonecode":"+973"},
  {"id":18,"iso":"BD","phonecode":"+880"},
  {"id":19,"iso":"BB","phonecode":"+1246"},
  {"id":20,"iso":"BY","phonecode":"+375"},
  {"id":21,"iso":"BE","phonecode":"+32"},
  {"id":22,"iso":"BZ","phonecode":"+501"},
  {"id":23,"iso":"BJ","phonecode":"+229"},
  {"id":24,"iso":"BM","phonecode":"+1441"},
  {"id":25,"iso":"BT","phonecode":"+975"},
  {"id":26,"iso":"BO","phonecode":"+591"},
  {"id":27,"iso":"BA","phonecode":"+387"},
  {"id":28,"iso":"BW","phonecode":"+267"},
  {"id":29,"iso":"BV","phonecode":"+0"},
  {"id":30,"iso":"BR","phonecode":"+55"},
  {"id":31,"iso":"IO","phonecode":"+246"},
  {"id":32,"iso":"BN","phonecode":"+673"},
  {"id":33,"iso":"BG","phonecode":"+359"},
  {"id":34,"iso":"BF","phonecode":"+226"},
  {"id":35,"iso":"BI","phonecode":"+257"},
  {"id":36,"iso":"KH","phonecode":"+855"},
  {"id":37,"iso":"CM","phonecode":"+237"},
  {"id":38,"iso":"CA","phonecode":"+1"},
  {"id":39,"iso":"CV","phonecode":"+238"},
  {"id":40,"iso":"KY","phonecode":"+1345"},
  {"id":41,"iso":"CF","phonecode":"+236"},
  {"id":42,"iso":"TD","phonecode":"+235"},
  {"id":43,"iso":"CL","phonecode":"+56"},
  {"id":44,"iso":"CN","phonecode":"+86"},
  {"id":45,"iso":"CX","phonecode":"+61"},
  {"id":46,"iso":"CC","phonecode":"+672"},
  {"id":47,"iso":"CO","phonecode":"+57"},
  {"id":48,"iso":"KM","phonecode":"+269"},
  {"id":49,"iso":"CG","phonecode":"+242"},
  {"id":50,"iso":"CD","phonecode":"+242"},
  {"id":51,"iso":"CK","phonecode":"+682"},
  {"id":52,"iso":"CR","phonecode":"+506"},
  {"id":53,"iso":"CI","phonecode":"+225"},
  {"id":54,"iso":"HR","phonecode":"+385"},
  {"id":55,"iso":"CU","phonecode":"+53"},
  {"id":56,"iso":"CY","phonecode":"+357"},
  {"id":57,"iso":"CZ","phonecode":"+420"},
  {"id":58,"iso":"DK","phonecode":"+45"},
  {"id":59,"iso":"DJ","phonecode":"+253"},
  {"id":60,"iso":"DM","phonecode":"+1767"},
  {"id":61,"iso":"DO","phonecode":"+1809"},
  {"id":62,"iso":"EC","phonecode":"+593"},
  {"id":63,"iso":"EG","phonecode":"+20"},
  {"id":64,"iso":"SV","phonecode":"+503"},
  {"id":65,"iso":"GQ","phonecode":"+240"},
  {"id":66,"iso":"ER","phonecode":"+291"},
  {"id":67,"iso":"EE","phonecode":"+372"},
  {"id":68,"iso":"ET","phonecode":"+251"},
  {"id":69,"iso":"FK","phonecode":"+500"},
  {"id":70,"iso":"FO","phonecode":"+298"},
  {"id":71,"iso":"FJ","phonecode":"+679"},
  {"id":72,"iso":"FI","phonecode":"+358"},
  {"id":73,"iso":"FR","phonecode":"+33"},
  {"id":74,"iso":"GF","phonecode":"+594"},
  {"id":75,"iso":"PF","phonecode":"+689"},
  {"id":76,"iso":"TF","phonecode":"+0"},
  {"id":77,"iso":"GA","phonecode":"+241"},
  {"id":78,"iso":"GM","phonecode":"+220"},
  {"id":79,"iso":"GE","phonecode":"+995"},
  {"id":80,"iso":"DE","phonecode":"+49"},
  {"id":81,"iso":"GH","phonecode":"+233"},
  {"id":82,"iso":"GI","phonecode":"+350"},
  {"id":83,"iso":"GR","phonecode":"+30"},
  {"id":84,"iso":"GL","phonecode":"+299"},
  {"id":85,"iso":"GD","phonecode":"+1473"},
  {"id":86,"iso":"GP","phonecode":"+590"},
  {"id":87,"iso":"GU","phonecode":"+1671"},
  {"id":88,"iso":"GT","phonecode":"+502"},
  {"id":89,"iso":"GN","phonecode":"+224"},
  {"id":90,"iso":"GW","phonecode":"+245"},
  {"id":91,"iso":"GY","phonecode":"+592"},
  {"id":92,"iso":"HT","phonecode":"+509"},
  {"id":93,"iso":"HM","phonecode":"+0"},
  {"id":94,"iso":"VA","phonecode":"+39"},
  {"id":95,"iso":"HN","phonecode":"+504"},
  {"id":96,"iso":"HK","phonecode":"+852"},
  {"id":97,"iso":"HU","phonecode":"+36"},
  {"id":98,"iso":"IS","phonecode":"+354"},
  {"id":99,"iso":"AF","phonecode":"+93"},
  {"id":100,"iso":"ID","phonecode":"+62"},
  {"id":101,"iso":"IR","phonecode":"+98"},
  {"id":102,"iso":"IQ","phonecode":"+964"},
  {"id":103,"iso":"IE","phonecode":"+353"},
  {"id":104,"iso":"IL","phonecode":"+972"},
  {"id":105,"iso":"IT","phonecode":"+39"},
  {"id":106,"iso":"JM","phonecode":"+1876"},
  {"id":107,"iso":"JP","phonecode":"+81"},
  {"id":108,"iso":"JO","phonecode":"+962"},
  {"id":109,"iso":"KZ","phonecode":"+7"},
  {"id":110,"iso":"KE","phonecode":"+254"},
  {"id":111,"iso":"KI","phonecode":"+686"},
  {"id":112,"iso":"KP","phonecode":"+850"},
  {"id":113,"iso":"KR","phonecode":"+82"},
  {"id":114,"iso":"KW","phonecode":"+965"},
  {"id":115,"iso":"KG","phonecode":"+996"},
  {"id":116,"iso":"LA","phonecode":"+856"},
  {"id":117,"iso":"LV","phonecode":"+371"},
  {"id":118,"iso":"LB","phonecode":"+961"},
  {"id":119,"iso":"LS","phonecode":"+266"},
  {"id":120,"iso":"LR","phonecode":"+231"},
  {"id":121,"iso":"LY","phonecode":"+218"},
  {"id":122,"iso":"LI","phonecode":"+423"},
  {"id":123,"iso":"LT","phonecode":"+370"},
  {"id":124,"iso":"LU","phonecode":"+352"},
  {"id":125,"iso":"MO","phonecode":"+853"},
  {"id":126,"iso":"MK","phonecode":"+389"},
  {"id":127,"iso":"MG","phonecode":"+261"},
  {"id":128,"iso":"MW","phonecode":"+265"},
  {"id":129,"iso":"MY","phonecode":"+60"},
  {"id":130,"iso":"MV","phonecode":"+960"},
  {"id":131,"iso":"ML","phonecode":"+223"},
  {"id":132,"iso":"MT","phonecode":"+356"},
  {"id":133,"iso":"MH","phonecode":"+692"},
  {"id":134,"iso":"MQ","phonecode":"+596"},
  {"id":135,"iso":"MR","phonecode":"+222"},
  {"id":136,"iso":"MU","phonecode":"+230"},
  {"id":137,"iso":"YT","phonecode":"+269"},
  {"id":138,"iso":"MX","phonecode":"+52"},
  {"id":139,"iso":"FM","phonecode":"+691"},
  {"id":140,"iso":"MD","phonecode":"+373"},
  {"id":141,"iso":"MC","phonecode":"+377"},
  {"id":142,"iso":"MN","phonecode":"+976"},
  {"id":143,"iso":"MS","phonecode":"+1664"},
  {"id":144,"iso":"MA","phonecode":"+212"},
  {"id":145,"iso":"MZ","phonecode":"+258"},
  {"id":146,"iso":"MM","phonecode":"+95"},
  {"id":147,"iso":"NA","phonecode":"+264"},
  {"id":148,"iso":"NR","phonecode":"+674"},
  {"id":149,"iso":"NP","phonecode":"+977"},
  {"id":150,"iso":"NL","phonecode":"+31"},
  {"id":151,"iso":"AN","phonecode":"+599"},
  {"id":152,"iso":"NC","phonecode":"+687"},
  {"id":153,"iso":"NZ","phonecode":"+64"},
  {"id":154,"iso":"NI","phonecode":"+505"},
  {"id":155,"iso":"NE","phonecode":"+227"},
  {"id":156,"iso":"NG","phonecode":"+234"},
  {"id":157,"iso":"NU","phonecode":"+683"},
  {"id":158,"iso":"NF","phonecode":"+672"},
  {"id":159,"iso":"MP","phonecode":"+1670"},
  {"id":160,"iso":"NO","phonecode":"+47"},
  {"id":161,"iso":"OM","phonecode":"+968"},
  {"id":162,"iso":"PK","phonecode":"+92"},
  {"id":163,"iso":"PW","phonecode":"+680"},
  {"id":164,"iso":"PS","phonecode":"+970"},
  {"id":165,"iso":"PA","phonecode":"+507"},
  {"id":166,"iso":"PG","phonecode":"+675"},
  {"id":167,"iso":"PY","phonecode":"+595"},
  {"id":168,"iso":"PE","phonecode":"+51"},
  {"id":169,"iso":"PH","phonecode":"+63"},
  {"id":170,"iso":"PN","phonecode":"+0"},
  {"id":171,"iso":"PL","phonecode":"+48"},
  {"id":172,"iso":"PT","phonecode":"+351"},
  {"id":173,"iso":"PR","phonecode":"+1787"},
  {"id":174,"iso":"QA","phonecode":"+974"},
  {"id":175,"iso":"RE","phonecode":"+262"},
  {"id":176,"iso":"RO","phonecode":"+40"},
  {"id":177,"iso":"RU","phonecode":"+70"},
  {"id":178,"iso":"RW","phonecode":"+250"},
  {"id":179,"iso":"SH","phonecode":"+290"},
  {"id":180,"iso":"KN","phonecode":"+1869"},
  {"id":181,"iso":"LC","phonecode":"+1758"},
  {"id":182,"iso":"PM","phonecode":"+508"},
  {"id":183,"iso":"VC","phonecode":"+1784"},
  {"id":184,"iso":"WS","phonecode":"+684"},
  {"id":185,"iso":"SM","phonecode":"+378"},
  {"id":186,"iso":"ST","phonecode":"+239"},
  {"id":187,"iso":"SA","phonecode":"+966"},
  {"id":188,"iso":"SN","phonecode":"+221"},
  {"id":189,"iso":"CS","phonecode":"+381"},
  {"id":190,"iso":"SC","phonecode":"+248"},
  {"id":191,"iso":"SL","phonecode":"+232"},
  {"id":192,"iso":"SG","phonecode":"+65"},
  {"id":193,"iso":"SK","phonecode":"+421"},
  {"id":194,"iso":"SI","phonecode":"+386"},
  {"id":195,"iso":"SB","phonecode":"+677"},
  {"id":196,"iso":"SO","phonecode":"+252"},
  {"id":197,"iso":"ZA","phonecode":"+27"},
  {"id":198,"iso":"GS","phonecode":"+0"},
  {"id":199,"iso":"ES","phonecode":"+34"},
  {"id":200,"iso":"LK","phonecode":"+94"},
  {"id":201,"iso":"SD","phonecode":"+249"},
  {"id":202,"iso":"SR","phonecode":"+597"},
  {"id":203,"iso":"SJ","phonecode":"+47"},
  {"id":204,"iso":"SZ","phonecode":"+268"},
  {"id":205,"iso":"SE","phonecode":"+46"},
  {"id":206,"iso":"CH","phonecode":"+41"},
  {"id":207,"iso":"SY","phonecode":"+963"},
  {"id":208,"iso":"TW","phonecode":"+886"},
  {"id":209,"iso":"TJ","phonecode":"+992"},
  {"id":210,"iso":"TZ","phonecode":"+255"},
  {"id":211,"iso":"TH","phonecode":"+66"},
  {"id":212,"iso":"TL","phonecode":"+670"},
  {"id":213,"iso":"TG","phonecode":"+228"},
  {"id":214,"iso":"TK","phonecode":"+690"},
  {"id":215,"iso":"TO","phonecode":"+676"},
  {"id":216,"iso":"TT","phonecode":"+1868"},
  {"id":217,"iso":"TN","phonecode":"+216"},
  {"id":218,"iso":"TR","phonecode":"+90"},
  {"id":219,"iso":"TM","phonecode":"+7370"},
  {"id":220,"iso":"TC","phonecode":"+1649"},
  {"id":221,"iso":"TV","phonecode":"+688"},
  {"id":222,"iso":"UG","phonecode":"+256"},
  {"id":223,"iso":"UA","phonecode":"+380"},
  {"id":224,"iso":"AE","phonecode":"+971"},
  {"id":225,"iso":"GB","phonecode":"+44"},
  {"id":226,"iso":"US","phonecode":"+1"},
  {"id":227,"iso":"UM","phonecode":"+1"},
  {"id":228,"iso":"UY","phonecode":"+598"},
  {"id":229,"iso":"UZ","phonecode":"+998"},
  {"id":230,"iso":"VU","phonecode":"+678"},
  {"id":231,"iso":"VE","phonecode":"+58"},
  {"id":232,"iso":"VN","phonecode":"+84"},
  {"id":233,"iso":"VG","phonecode":"+1284"},
  {"id":234,"iso":"VI","phonecode":"+1340"},
  {"id":235,"iso":"WF","phonecode":"+681"},
  {"id":236,"iso":"EH","phonecode":"+212"},
  {"id":237,"iso":"YE","phonecode":"+967"},
  {"id":238,"iso":"ZM","phonecode":"+260"},
  {"id":239,"iso":"ZW","phonecode":"+263"}
]

  export default countries