import * as type from '../../constants';

const initialState = {
  activeQuery: {
    guestId: null,
    assigned_to: 0,
    chats: []
  },
  closedQuery: {
    guestId: null,
    assigned_to: 0,
    chats: []
  },
  messages: null,
  queryDetails: null,
  loading: false,
  updateLoder: null,
  error: null
}

export function queryListReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_QUERY_LIST:
      return {
        ...state,
        loading: true,
        ...(action.data.flag===1 && 
            {
              activeQuery: {
                ...state.activeQuery,
                assigned_to: action.data.assignedTo, 
                chats:  (state.activeQuery.guestId !== action.data.guestId) ? [] : state.activeQuery.chats,
                guestId: action.data.guestId,
              }
            }
          ),
          ...(action.data.flag===0 && 
            {
              closedQuery: {
                ...state.closedQuery,
                assigned_to: action.data.assignedTo, 
                chats:  (state.closedQuery.guestId !== action.data.guestId) ? [] : state.closedQuery.chats,
                guestId: action.data.guestId,
              }
            }
          )
      }
    case type.SET_ACTIVE_QUERY:
      return {
        ...state,
        loading: false,
        activeQuery:  {
          ...state.activeQuery,
          assigned_to: action.data.assignedTo, 
          guestId: action.data.guestId,
          total_pages: action.payload.total_pages,
          current_page_no: action.payload.current_page_no,

          chats:  (action.data.filterChange || (state.activeQuery.guestId !== action.data.guestId)) ? 
            action.payload?.chats
            :
            [...(state.activeQuery?.chats?.length ? state.activeQuery?.chats: []), ...action.payload?.chats]
        },
      }
    case type.SET_CLOSED_QUERY:
        return {
          ...state,
          loading: false,
          closedQuery: {
            ...state.closedQuery,
            assigned_to: action.data.assignedTo, 
            guestId: action.data.guestId,
            total_pages: action.payload.total_pages,
            current_page_no: action.payload.current_page_no,
  
            chats:  (action.data.filterChange || (state.closedQuery.guestId !== action.data.guestId)) ? 
              action.payload?.chats
              :
              [...(state.closedQuery?.chats?.length ? state.closedQuery?.chats: []), ...action.payload?.chats]
          },
        }
    case type.GET_QUERY_LIST_FALIURE:
      let {flag} = action.data
      if(flag===1){
        return {
          ...state,
          loading: false,
          activeQuery: {
            assigned_to: action.data.assignedTo,
            chats: [],
            guestId: state.activeQuery.guestId,
          },
        }
      }else{
        return {
          ...state,
          loading: false,
          closedQuery: {
            assigned_to: action.data.assignedTo,
            chats: [],
            guestId: state.closedQuery.guestId,
          },
        }
      }

    case type.GET_QUERY_MESSAGE:
    return {
        ...state,
        //loading: true,
    }
    case type.GET_QUERY_MESSAGE_SUCCESS:
    return {
        ...state,
        messages: action.payload,
        error: null
    }
    case type.GET_QUERY_MESSAGE_FALIURE:
    return {
        ...state,
        messages: null
    }

    case type.GET_QUERY_DETAILS:
        return {
            ...state,
            loading: true,
            messages:null
        }
    case type.GET_QUERY_DETAILS_SUCCESS:
        return {
            ...state,
            loading: false,
            queryDetails: action.payload,
            error: null
        }
    case type.GET_QUERY_DETAILS_FALIURE:
        return {
            ...state,
            loading: false,
            queryDetails: null
        }

    case type.UPDATE_QUERY_STATUS:
        return {
            ...state,
            updateLoder: true,
        }
    case type.UPDATE_QUERY_STATUS_SUCCESS:
        return {
            ...state,
            updateLoder: false,
            messages: action.payload.messages,
            queryDetails:action.payload.queryDetails
        }
    case type.UPDATE_QUERY_STATUS_FALIURE:
        return {
            ...state,
            updateLoder: false,
        }


    case type.SEND_MESSAGE_IN_QUERY:
        return {
            ...state,
            updateLoder: true,
        }
    case type.SEND_MESSAGE_IN_QUERY_SUCCESS:
        return {
            ...state,
            updateLoder: false,
            messages: action.payload.messages,
        }
    case type.SEND_MESSAGE_IN_QUERY_FALIURE:
        return {
            ...state,
            updateLoder: false,
        }


    default:
      return state
  }
}

