import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import arrowDownIcon from '../../../assets/images/arrow-down-white.svg'
import logoutIcon from '../../../assets/images/logout.svg'
import userIcon from '../../../assets/images/user.svg'
import ImageComponent from '../../ImageComponent/ImageComponent'
import {logout} from '../../../redux/actions/AuthActions/LogoutAction'
import './ProfileDropdown.scss'
import * as routes from '../../../routes/route'


export default function ProfileDropdown() {
    const UserData = JSON.parse(localStorage.getItem('UserData'))
    const [state, setState] = useState(false)
    const dropRef = useRef(null);
    const {logoutData} = useSelector(state=> state.logoutReducer)
    const dispatch = useDispatch();

    const handleLogout = (e)=>{
      e.preventDefault()
      dispatch(logout())
    }

    const outsideClickHandler = (e)=>{
        if(dropRef.current && state && !dropRef.current?.contains(e.target)){
            setState(false)
        }else if(!state && dropRef.current?.contains(e.target)){
            setState(true)
        }else if(state && dropRef.current?.contains(e.target)){
            setState(false)
        }
    }

    useEffect(()=>{
      document.addEventListener("click", outsideClickHandler);
      return () => {
          document.removeEventListener("click", outsideClickHandler);
        };
    })

    useEffect(() => {
      if (logoutData?.statusCode === 200) {
        localStorage.clear()
        window.location.href = routes.LOGIN_WITH_MOBILE_ENDPOINT;
      }
    }, [logoutData]);

    

  return (
    <div id='profile-dropdown'>
      <button type="button" className='d-flex justify-content-between align-items-center' ref={dropRef}>
        <div className='d-flex align-items-center w-100'>
          <ImageComponent className='profile-pic me-2 me-md-0' src={UserData && UserData.profile_pic ? UserData.profile_pic : ''} fetch={true}/>
          <span className='d-none d-md-block text-capitalize text-start overflow-hidden mx-3 user-name'>{UserData.first_name} {UserData.last_name}</span>
        </div>
        <div>
            <img src={arrowDownIcon} className={state? 'active arrow': 'arrow'} alt="" />
        </div>
      </button>
      {
        state 
        &&
        <div id="user-options">
          <Link to={routes.VIEW_PROFILE_ENDPOINT}><img src={userIcon} alt="" /> &nbsp; View Profile</Link>
          <a href="/#" onClick={handleLogout}><img src={logoutIcon} alt=""/> &nbsp; Sign Out</a>
        </div>
      }
    </div>
    
  );
}
