import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './GroupClassAndActivity.scss'
import { useDispatch, useSelector } from 'react-redux';
import backArrow from '../../assets/images/back-arrow.svg'
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import calendarIcon from '../../assets/images/calendarIcon.png'
import "react-datepicker/dist/react-datepicker.css";
import { clearActivityDeatils, getActivityDeatils, getActivityListByDate } from '../../redux/actions/GroupClassesAndActivityActions/GroupClassesAndActivityAction';
import Select from "react-select";
import { notify } from '../../utils/notification';
import { CircularProgress } from '@mui/material';
import { GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT, VIEW_GUEST_LIST_ENDPOINT } from '../../routes/route';


function GroupClassAndActivity() {
    const location = useLocation()
    const activityName = location?.state?.activityName;
    const selectedDate = location?.state?.selectedDate;
    const [name, setName] = useState(activityName || '');
    const [date, setDate] = useState(selectedDate || new Date());
    const {loading, activityList, activityDetails} = useSelector(state=> state.groupClassesAndActivityReducer)
    const [activitynameOptions, setActivityNameOptions] = useState([])
    const [frequency, setFrequency] = useState(null);

    const navigate = useNavigate()

    const handleSearch = ()=>{
        if(!name)
            notify('warning', 'Plase Select activity')
        else
        navigate({
            pathname: GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT,
        }, {state: {activityName: name,selectedDate: date},replace: true })
    }

    const dispatch = useDispatch()

    useEffect(()=>{
        let data = {
            date: moment(date).format('YYYY-MM-DD')
        }
        dispatch(getActivityListByDate(data))

        if(name){
            dispatch(getActivityDeatils({activityId: name?.value}))
        }

        return(()=>{
            dispatch(clearActivityDeatils())
        })
    },[])

    useEffect(()=>{
        if(activityDetails)
            setFrequency(activityDetails && activityDetails?.frequency && JSON.parse(activityDetails?.frequency))
    }, [activityDetails])

    useEffect(()=>{
        if(activityList?.length){
            let newArr = activityList.map((item) => {
                return { value: item.activity_id, label: item.title };
             });
            setActivityNameOptions(newArr)
        }else{
            setActivityNameOptions([])
        }
    },[activityList])
    
  return (
    <>
            <div className="group-activity-container">
                <div className="container h-100">
                    <div className="group-activity-heading">
                        <img className='back-arrow' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                        &nbsp;
                        Group Classes / Activity
                    </div>
                    <div className="pt-4 pb-5">
                        <div className="group-activity-content p-4">
                          <div className="d-flex filter">
                            <div className='d-flex'>
                                <div className='dateBlock'>
                                    {!date ?
                                        <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select Date</p></div>
                                        : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(date, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                                    }
                                    <div className='datePickerDiv'>
                                        <ReactDatePicker
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            selected={date}
                                            onChange={date => { setDate(date); setName(''); dispatch(getActivityListByDate({date: moment(date).format('YYYY-MM-DD')})) }}
                                            popperPlacement={"bottom"}
                                            customInput={
                                                <img src={calendarIcon} className="calIcon" alt="" />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="ms-2">
                                    <Select
                                        className="name-select"
                                        classNamePrefix="select"
                                        //isLoading={loading}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="color"
                                        options={activitynameOptions}
                                        placeholder='Select Activity'
                                        onChange={(e)=>{setName(e)}}
                                        value={name}
                                    />
                                </div>
                            </div>
                            <button className='search-btn' onClick={handleSearch} > SEARCH </button>
                         </div>
                         <hr />
                        <div className='activity-details mt-5'>
                            {
                                loading ?
                                <div className='text-center'><CircularProgress sx={{'color': '#74613C'}}/></div>
                                :
                                <> 
                                    {
                                        activityDetails && 
                                        <div>
                                            <div className="activity-title mb-4">
                                                {activityDetails?.title}
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Capacity:</div>
                                                <div className="value">{activityDetails?.no_of_capacity || '-'}</div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Book User:</div>
                                                <div className="value">{activityDetails?.booked_user?.toString() || '-'} {!!activityDetails?.booked_user && <Link to={VIEW_GUEST_LIST_ENDPOINT} state={{id: activityDetails.id}}>(View Guest List)</Link>} </div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Venue:</div>
                                                <div className="value">{activityDetails?.venue || '-'}</div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Date:</div>
                                                <div className="value">{moment(activityDetails?.activity_datetime).format('DD/MM/YYYY') || '-'}</div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Time:</div>
                                                <div className="value">{moment(activityDetails?.activity_datetime).format('HH:mm:ss') || '-'}</div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Repeat Activity:</div>
                                                <div className="value">{activityDetails?.is_repeated ==='1' ? 'Yes' :  'No'}</div>
                                            </div>
                                            {
                                                activityDetails?.is_repeated === '1'? 
                                                <div className="activity-row">
                                                    <div className="label">Frequency:</div>
                                                        {frequency?.key} &nbsp;
                                                        {
                                                            frequency?.key === "weekly"
                                                            ?
                                                            <>
                                                                
                                                                [{
                                                                    frequency?.value.map((item, index)=>(
                                                                        <React.Fragment>
                                                                            {item.value ? item.day : null}
                                                                            {item.value && index !== frequency?.value.length-1 ? ", " : null}
                                                                        </React.Fragment>
                                                                    ))
                                                                }]
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    <div className="value">
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            <div className="activity-row">
                                                <div className="label">Duration:</div>
                                                <div className="value">{`${activityDetails?.duration} Minutes` || '-'}</div>
                                            </div>
                                            <div className="activity-row">
                                                <div className="label">Notes:</div>
                                                <div className="value">{activityDetails?.notes || '-'}</div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                            
                        </div>
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default GroupClassAndActivity