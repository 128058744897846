import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Guest from '../Guest/Guest'
import { CircularProgress } from '@mui/material'
import {getGuestList} from '../../../redux/actions/GuestListActions/GuestListAction'
import './GuestTab.scss'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'

function GuestTab({index, type}) {
    const {loading} = useSelector(state => state.guestListReducer)
    const [searchText, setSearchText] = useState(localStorage.getItem('searchText') ||'')
    const dispatch = useDispatch()
    const guestData = useSelector(state=> {
      return index === 0
        ? state.guestListReducer.tentativeGuest
        : index===1
          ? state.guestListReducer.upcomingGuest
          : index === 2
              ? state.guestListReducer.activeGuest
              : state.guestListReducer.completedGuest
    })

    const fetchList = ()=>{
      if(!guestData?.total_pages || guestData?.current_page_no < guestData?.total_pages){
        dispatch(getGuestList({
          filter_type: index-1, 
          page: guestData?.current_page_no ? guestData?.current_page_no+1 : 1, 
          assignedTo: guestData?.assigned_to
        }))
      }
    }

    useEffect(()=>{
      fetchList()
    },[])


    useEffect(()=>{
      localStorage.setItem('searchText', searchText)
    },[searchText])

  return (
    <div className='guest-list'>
      <div>
        {/* <div className="guest-list-search">
          <input type="text" 
            value={searchText} 
            placeholder='Search Guest'
            onChange={(e)=>{setSearchText(e.target.value)}}
          />
        </div> */}

        {        
          loading &&
          <div className='text-center mb-3'><CircularProgress size={27} sx={{'color': '#74613C'}}/></div>
        }
        {
          !!guestData?.guest_list?.length ? 
          <InfiniteScroll
            dataLength={guestData?.guest_list?.length} //This is important field to render the next data
            next={fetchList}
            hasMore={!guestData?.total_pages || guestData?.current_page_no< guestData?.total_pages}
            loader={<><div className='text-center'><CircularProgress sx={{'color': '#5ebd78'}}/></div></>}
            endMessage={
              <></>
            }
            scrollThreshold={0.9}
            >
            {
            (searchText.length 
              ? guestData?.guest_list?.filter(item => {
                return item.full_name.toLowerCase().includes(searchText.toLowerCase()) 
                || item.program_title.toLowerCase().includes(searchText.toLowerCase())
                || item.status.toLowerCase().includes(searchText.toLowerCase())
                || moment(item?.check_in).format('MMM DD, dddd').toLowerCase().includes(searchText.toLowerCase())
                || moment(item?.check_out).format('MMM DD, dddd').toLowerCase().includes(searchText.toLowerCase());
              })
              : guestData)?.guest_list?.map((item, index)=>{
                return (
                  <Guest item={item} key={index} type={type}/>
                  )
                })
              }
          </InfiniteScroll>
          :
          <div>{!loading && <div className='text-center fw-normal'>No {type} guest</div>}</div>
        }

        
      </div>
    </div>
  )
}

export default GuestTab