import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {updateProfileValidation} from '../../validationSchema/updateProfileSchema'
import ImageComponent from '../../components/ImageComponent/ImageComponent';
import {notify} from "../../utils/notification"
import InputBox from '../../components/Form Components/InputBox/InputBox';
import { viewProfile } from '../../redux/actions/AuthActions/ViewProfileAction';
import {updateProfile} from '../../redux/actions/AuthActions/UpdateProfileAction'
import PhoneInput from '../../components/Form Components/PhoneInput/PhoneInput';
import cameraIcon from '../../assets/images/camera.svg'
import moment from 'moment';
import { app } from '../../firebase/firebaseConfig'
import { uploadBytes, getStorage, ref } from 'firebase/storage'
import './EditProfile.scss'
import { CircularProgress } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import calendarIcon from '../../assets/images/calendarIcon.png'


function EditProfile() {
  const {userData, loading} = useSelector(state => state.loginReducer);
  const [fetch, setFetch] = useState(true)
  const [imageSrc, setImageSrc] = useState(userData?.data?.profile_pic)
  const [countryData, setCountryData] = useState({"id":1,"iso":"IN","phonecode":"+91"});
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const uploadImage = ()=>{

    if(imageSrc !== userData?.data?.profile_pic){
      
      try {
        const storage = getStorage(app);
        const imageRef = ref(storage, `users/${imageSrc.name}`);
        uploadBytes(imageRef, imageSrc).then((snapshot) => {
          //console.log("sucess")
        });
      } catch (error) {
        
      }
    }
  }

  const handleSubmit = async(values,  { resetForm })=>{
    values.mobileNo = `${countryData.phonecode}-${values.mobileNo}`
    if(imageSrc !== userData?.data?.profile_pic)
      values = {...values, profile_pic: `users/${imageSrc.name}`}
    dispatch(updateProfile(values))
    uploadImage();
    navigate(-1)
  }

  useEffect(()=>{
    dispatch(viewProfile())
  },[])

  useEffect(()=>{
    if(userData){
      setImageSrc(userData?.data?.profile_pic)
    }
  },[userData])

  return (
      <>
        <div className='edit-profile-container'>
          <div className="container">
            <div className="edit-profile-heading">
              Edit Profile
            </div>
            {
              loading && !userData  ?
              <div className="loader">
                <CircularProgress sx={{'color': '#74613C'}}/>
              </div>
              :
              userData && <div className="edit-profile-content">
              <div className='d-flex align-items-end'>
                <div className="profile-pic">
                  <div className="profile-img-container">
                    {imageSrc && <ImageComponent className="edit-profile-img" src={imageSrc} fetch={fetch}/>}
                  </div>
                  
                  <div className="image-upload">
                    <div className="inner">
                      <img src={cameraIcon} alt="" />
                      <input type="file" name="profile_pic" accept="image/*" data-testid="profile_pic_uploader" id="upload-btn" onChange={(e) => { setImageSrc(e.target.files[0]); setFetch(false) }}/>
                    </div>
                  </div>
                </div>

                <div className='ms-4'>
                  <div className="name mb-3">{ userData?.data?.first_name} { userData?.data?.last_name}</div>
                </div>
              </div>
              <Formik
                initialValues={{ 
                first_name: userData?.data?.first_name,
                last_name: userData?.data?.last_name,
                email_id: userData?.data?.email_id,
                mobileNo:userData?.data?.phone_no.slice(userData?.data?.phone_no.indexOf('-')+1),
                DOB: userData?.data?.DOB ? moment(userData?.data?.DOB, "MM/DD/YYYY").format("YYYY-MM-DD") : "",
                gender: userData?.data?.gender,
                address: userData?.data?.address
              }}
                validationSchema={updateProfileValidation(countryData.iso)}
                onSubmit={handleSubmit}>
                {
                  (formik)=>{
                    return(
                      <Form>
                        <div className=" edit-profile-data">
                          <div className='row'>
                            <div className='col-6'>
                              <div className='title'>First Name</div>
                              <InputBox name="first_name" type="text" placeholder="First Name" testID={"first_name"}/>
                            </div>
                            <div className='col-6'>
                              <div className='title'>Last Name</div>
                              <InputBox name="last_name" type="text" placeholder="Last Name" testID={"last_name"}/>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className='title'>Mobile Number</div>
                              <PhoneInput countryData={countryData} setCountryData={setCountryData} testID={"phone_no"}/>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12'>
                              <div className='title'>Email Address</div>
                              <InputBox name="email_id" type="email" placeholder="Email Address" testID={"email_id"}/>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-8'>
                              <div className='title'>Date of Birth</div>
                              <div className='dateBlock my-2'>
                                {!formik.values.DOB ?
                                    <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Select Date Of Birth</p></div>
                                    : <div className="dayDateTextDiv" ><p className="dayDateText">{(moment(formik.values.DOB, "YYYY-MM-DD").format('DD/MM/YYYY'))}</p></div>
                                }
                                <div className='datePickerDiv'>
                                  <ReactDatePicker
                                      selected={formik.values.DOB}
                                      onChange={date => { formik.setFieldValue('DOB', date); }}
                                      popperPlacement={"bottom"}
                                      customInput={
                                          <img src={calendarIcon} className="calIcon" alt="" />
                                      }
                                      showYearDropdown={true}
                                      showMonthDropdown={true}
                                  />
                              </div>
                            </div>
                            </div>
                            <div className='col-4'>
                              <div className='title'>Age</div>
                              <div className="age-input">
                                <input name="age" type="text" value={formik.values.DOB ? `${moment().diff(moment(formik.values.DOB), 'year')}`: ""} placeholder="Age" disabled={true} data-testid="age"/>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12 '>
                              <div className='title'>Gender</div>
                              <div className="radio-input d-flex">
                                <div className="radio">
                                  <input type="radio" name='gender' value={"Male"} checked={formik.values.gender ==="Male" ? true: false}
                                    data-testid="gender_male"
                                    onChange={()=>formik.setFieldValue("gender", "Male")}
                                  />
                                  <label htmlFor="male"> Male</label>
                                </div>
                                <div className="radio">
                                  <input type="radio" name='gender' value={"Female"} checked={formik.values.gender ==="Female" ? true: false}
                                    data-testid="gender_female"
                                    onChange={()=>formik.setFieldValue("gender", "Female")}
                                  />
                                  <label htmlFor="female"> Female</label>
                                </div>
                                <div className="radio">
                                  <input type="radio" name='gender' value={"other"} checked={formik.values.gender ==="other" ? true: false}
                                    data-testid="gender_other"
                                    onChange={()=>formik.setFieldValue("gender", "other")}
                                  />
                                  <label htmlFor="other"> Other</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-12 '>
                              <div className='title'>Address</div>
                              <InputBox type="textarea" name="address" placeholder="Address" testID={"address"}/>
                            </div>
                          </div>

                          <div className="button-group d-flex justify-content-between py-5">
                            <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                            <button className='update' type='submit' 
                            onClick={()=> 
                              {
                                if (!formik.values.email_id || !formik.values.first_name || !formik.values.last_name || !formik.values.mobileNo || !formik.values.DOB || !formik.values.gender) {
                                  notify("warning", "Please fill all details")
                                }
                                else if (formik.errors.mobileNo) {
                                  notify("warning", "Please enter valid mobile number")
                                }
                                else if (formik.errors.email_id) {
                                  notify("warning", "Please enter valid email address")
                                }
                              }
                            }
                            >Update</button>
                          </div>
                        </div>
                      </Form>
                    )
                  }
                }
              </Formik>
            </div>
            }
          </div>
        </div>
      </>
  )
}

export default EditProfile