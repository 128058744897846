import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './InterestedGuestList.scss'
import { useDispatch, useSelector } from 'react-redux';
import backArrow from '../../assets/images/back-arrow.svg'
import { CircularProgress } from '@mui/material';
import { getIntersestedUserList } from '../../redux/actions/GroupClassesAndActivityActions/GroupClassesAndActivityAction';
import Guest from './Guest/Guest';


function InterestedGuestList() {
    const {loading, interestredUserList} = useSelector(state=> state.groupClassesAndActivityReducer)
    const location = useLocation()
    const {id} = location.state;
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getIntersestedUserList({id}))
    },[])

    
  return (
    <>
            <div className="interested-guest-list-container">
                <div className="container h-100">
                    <div className="interested-guest-list-heading">
                        <img className='back-arrow' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                        &nbsp;
                        Guest List
                    </div>
                    <div className="pt-4 pb-5">
                        <div className="interested-guest-list-content">
                            {
                                loading  ?
                                <div className="loader text-center">
                                    <CircularProgress sx={{'color': '#74613C'}}/>
                                </div>
                                :
                                <>
                                {
                                    interestredUserList?.map((item, index)=>(
                                            <Guest item={item} key={index}/>
                                        )
                                    )
                                }
                                </>
                            }
                        </div>
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default InterestedGuestList