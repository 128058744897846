import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGuestDetails, updateGuestDOB } from '../../redux/actions/GuestDataActions/GuestDetails'
import './GuestDetailsHeader.scss'
import editIcon from '../../assets/images/pen.svg'
import ChangeDOBModal from '../changeDOBModal/changeDOBModal'
import moment from 'moment'

function GuestDetailsHeader({guestId, editOption=false, docter_name}) {
    const dispatch = useDispatch()
    const {guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const [changeDOBModal, setChangeDOBModal] =useState(false)
    const [DOB, setDOB] =useState(false)

    useEffect(()=>{
        if(guestId){
            dispatch(getGuestDetails({guestId}))
        }
    },[])

    useEffect(()=>{
        if(guestDetails){
            setChangeDOBModal(!guestDetails?.DOB)
            setDOB(guestDetails?.DOB ? moment(guestDetails?.DOB, "MM/DD/YYYY").format('YYYY-MM-DD') : null)
        }
    }, [guestDetails])

    const updateDOB = ()=>{
        dispatch(updateGuestDOB({
            DOB : moment(DOB).format('MM/DD/YYYY'),
            user_id: guestId,
            onSuccessCallback: ()=>{
                setChangeDOBModal(false)
            }
        }))
    }

  return (
    <div className="guest-header-data">
        <div className="data-row text-capitalize">
            <div className="title">Full Name: </div>
            <div className="value">{guestDetails?.first_name} {guestDetails?.last_name}</div>
        </div>
        <div className="data-row">
            <div className="title">Phone Number: </div>
            <div className="value">{guestDetails?.phone_number || 'N/A'}</div>
        </div>
        <div className="row g-0">
            <div className="col">
                <div className="data-row">
                    <div className="title">Email Address: </div>
                    <div className="value">{guestDetails?.email_id || 'N/A'}</div>
                </div>
            </div>
            {
                docter_name &&
                <div className="col">
                    <div className="data-row">
                        <div className="title">Docter: </div>
                        <div className="value">{docter_name}</div>
                    </div>
                </div>
            }
        </div>
        <div className="row g-0">
            <div className="col ">
                <div className="data-row">
                    <div className="title">Gender: </div>
                    <div className="value">{guestDetails?.gender || 'N/A'}</div>
                </div>
            </div>
            <div className="col ">
                <div className="data-row">
                    <div className="title">Age: </div>
                    <div className="value">{guestDetails?.DOB ? moment().diff(moment(guestDetails?.DOB, "MM/DD/YYYY"), 'year') +" Years" : 'N/A'}</div>
                    {editOption && <div className='ms-3 edit' onClick={()=>setChangeDOBModal(true)}><img src={editIcon} alt="" /></div>}
                </div>
            </div>
        </div>

        <ChangeDOBModal
            warnModal={changeDOBModal} 
            setWarnModal={setChangeDOBModal} 
            onUpdate={updateDOB}
            selectedDate={DOB}
            setDOB={setDOB}
            />
        </div>
  )
}

export default React.memo(GuestDetailsHeader)