import React, { useEffect } from 'react'
import './ViewWellbeingReport.scss'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { viewWellbeingReport } from '../../../redux/actions/GuestDataActions/WellbeingReportActions'
import { CircularProgress } from '@mui/material'
import { getGuestDetails } from '../../../redux/actions/GuestDataActions/GuestDetails'
import moment from 'moment'
import GuestDetailsHeader from '../../../components/GuestDetailsHeader/GuestDetailsHeader'

function PrintViewWellbeingReport() {
  const {guestId, userProgramId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const {wellbeingReport, loading} = useSelector(state=> state.wellbeingReportReducer)
  const guestDetailsReducer = useSelector(state=>state.guestDetailsReducer)
  const guestDetails = guestDetailsReducer?.guestDetails

  useEffect(()=>{
    dispatch(getGuestDetails({guestId}))
    if(searchParams.get("assessmentId"))
      dispatch(viewWellbeingReport({guestId, userProgramId, user_self_assessment_id:searchParams.get("assessmentId")}))
    else
      dispatch(viewWellbeingReport({guestId, userProgramId}))
  },[])


  useEffect(()=>{
    if(!guestDetailsReducer.loading && !loading && wellbeingReport && Object.entries(wellbeingReport)?.length>0)
        window.print()
  }, [guestDetailsReducer.loading , loading])
   
  return (
    <div className="view-report-container mt-5 print-page">
      <div className="h-100 mx-auto" style={{width: '970px'}}>
        <div className="view-report-heading">
          Health Assessment Report
        </div>

        {   
          guestDetailsReducer.loading ?
          <div className="loader">
              <CircularProgress sx={{'color': '#74613C'}}/>
          </div>
          :
            <>
              <div className="pt-4 pb-5">
                <div className="view-report-content">
                  {
                    guestDetails 
                    &&
                    <GuestDetailsHeader />
                  }
                  <div className="guest-report-data">
                    <div className="prev-next-btn d-flex justify-content-center">
                      <div className='time'> {loading ? '' : wellbeingReport?.assessment_filled_datetime ? moment(wellbeingReport?.assessment_filled_datetime, "YYYY-MM-DD hh:mm:ss").format("MMM DD, YYYY") : ''}</div>
                    </div>

                    {
                      loading ?
                      <div className="loader">
                          <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                      </div>
                      :

                      <>
                        {
                          Object.keys(wellbeingReport).length === 0
                          ?
                          <div className="loader">
                            {/* Awaiting: Health Assessment to be done. */}
                          </div>
                          :
                          <>
                            <div className="status">
                              <div>
                                <div className="data-row">
                                  <div className="title">Prakruti:</div>
                                  <div className="value">{wellbeingReport?.prakruti}</div>
                                </div>
                              </div>

                              <div>
                                <div className="data-row">
                                  <div className="title">Agni (Digestive Capacity):</div>
                                  <div className="value">{wellbeingReport?.agni_type}</div>
                                </div>
                              </div>

                              <div>
                                <div className="data-row">
                                  <div className="title">Koshta (Gut Behaviour):</div>
                                  <div className="value">{wellbeingReport?.koshtha_type}</div>
                                </div>
                              </div>
                      
                          </div>

                          <div className="hr"></div>

                          <div className="last-update">
                            <div>
                              <div className="data-row-1">
                                <div className="title">Your overall Score: &nbsp;</div>
                                <div className="value">{wellbeingReport?.over_all_wellness_score}%</div>
                              </div>
                            </div>
                           
                          </div>

                          <div className="hr"></div>

                          <div className="data-row-2 heading">
                            <div className="title">Assessment Area</div>
                            <div className="value">Percentage</div>
                          </div>

                          {
                            wellbeingReport?.section_scores?.map((item, index)=>(
                              <React.Fragment key={index}>
                                <div className="data-row-2">
                                  <div className="title">{item.section_name}</div>
                                  <div className="value">{item.score}%</div>
                              </div>
                              <div className="hr"></div>
                              </React.Fragment>
                            ))
                          }
                          </>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
            </>
          }
      </div>
    </div>
  )
}

export default PrintViewWellbeingReport