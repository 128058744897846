import {
    getStorage,
    getDownloadURL,
    ref,
    uploadBytesResumable,
    deleteObject,
    uploadString,
  } from 'firebase/storage';
  import { app } from './firebaseConfig';
  import {notify} from '../utils/notification'

const storage = getStorage(app);

export const getFirebaseURL= async (filePath) => {
    const storage = getStorage(app);
    const dummyProfile = "";
    try {
        const imageRef = ref(storage, filePath);
        return new Promise(async (resolve, reject) => {
            try {
                await getDownloadURL(imageRef).then((fireBaseUrl) => {
                    resolve(fireBaseUrl);
                });
            } catch (e) {
                console.log(e)
                resolve("");
            }
      });
    } catch (e) {
        return dummyProfile;
    }
};

export const uploadImageOnFirebase = async (imgData, filePath, isBase64=false) => {
    const storage = getStorage(app);

    try {
        const metadata = { cacheControl: 'public, no-cache' };
        const storageRef = ref(storage, filePath);
        if(isBase64)
            await uploadString(storageRef, imgData, 'data_url');
        else
            await uploadBytesResumable(storageRef, imgData, metadata);
    } catch (e) {
    }
};

export const deleteImageFromFirebase = async (filePath) => {
    const storage = getStorage(app);
    try {
        const storageRef = ref(storage, filePath);
        await deleteObject(storageRef)
    } catch (e) {
        notify('error', "Error in deleting file");
    }
};


export const getFirebasePathFromURL= async (URL) => {
    const pathWithEncodedCharacters = URL.replace(/^.*?\.com\/v0\/b\/[^/]+\/o\/(.*)\?.*$/, '$1');
    const decodedPath = decodeURIComponent(pathWithEncodedCharacters);
    return `/${decodedPath}`
};