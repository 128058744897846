import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";

export const getActivityListByDateReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_ACTIVITY_LIST_BY_DATE_API}?date=${data?.date}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getActivityListByDate({data}){
    try{
        let result = yield call(getActivityListByDateReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_ACTIVITY_LIST_BY_DATE_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_ACTIVITY_LIST_BY_DATE_FALIURE})
        }
        
    }catch(error){
        console.log(error)
    }
}

export const getActivityDetailsReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_ACTIVITY_DETAILS_API}?activity_id=${data?.activityId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getActivityDetails({data}){
    try{
        let result = yield call(getActivityDetailsReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_ACTIVITY_DETAILS_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_ACTIVITY_DETAILS_FALIURE})
        }
        
    }catch(error){
        console.log(error)
    }
}

export const getInterestedUserListReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_INTERESTED_USER_LIST_API}?activity_id=${data?.id}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getInterestedUserList({data}){
    try{
        let result = yield call(getInterestedUserListReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_INTERESTED_USER_LIST_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_INTERESTED_USER_LIST_FALIURE})
        }
        
    }catch(error){
        console.log(error)
    }
}

function* groupClassesAndActiviytySaga(){
    yield takeLatest(types.GET_ACTIVITY_LIST_BY_DATE, getActivityListByDate);
    yield takeLatest(types.GET_ACTIVITY_DETAILS, getActivityDetails);
    yield takeLatest(types.GET_INTERESTED_USER_LIST, getInterestedUserList);

}

export default groupClassesAndActiviytySaga;