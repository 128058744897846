import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import FormLayout from '../../components/From Layout/FormLayout'
import Button from '../../components/Form Components/Button'
import PhoneInput from '../../components/Form Components/PhoneInput/PhoneInput'
import { mobileValidation } from '../../validationSchema/emailMobileSchema'
import { login } from '../../redux/actions/AuthActions/LoginAction'
import "./login.scss"
import * as routes from '../../routes/route'


function PhoneNoLogin() {
  const [countryData, setCountryData] = useState({"id":1,"iso":"IN","phonecode":"+91"});
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {otpFor} = useSelector((state)=> state.verificationReducer)

  useEffect(()=>{
    if(otpFor)
      navigate(routes.VERIFICATION_ENDPOINT);
  },[otpFor])


  return (
    <div className="login-form">
      <FormLayout title="Enter Your Mobile Number">
      
        <div className="muted-text">
          Please confirm your country code and enter your mobile number.
        </div>

        <Formik
          initialValues={{  mobileNo: ''}}
          validationSchema={mobileValidation(countryData.iso)}
          onSubmit={(values,  { resetForm })=>{
            dispatch(login({
              "otp_for": "phone",
              "phone_no": `${countryData.phonecode}-${values.mobileNo}`
            }))   
            resetForm()
          }}
        >
          {
            (formik)=>{
              return (
                <Form>
                  <div className="input-container">
                    <PhoneInput countryData={countryData} setCountryData={setCountryData}/>
                  </div>

                  <div className="navigation">
                    <div className="text-end">
                      <Link end="true" to={routes.LOGIN_WITH_EMAIL_ENDPOINT}>Login via Email</Link>
                    </div>
                    <div className="text-center">
                      <div className='d-flex justify-content-center my-4'>
                        <Button content="continue" type="submit" arrow="right"  disabled={!(formik.dirty && formik.isValid)}/>
                      </div>
                      <div className='mt-3'>
                        <span>Update your mobile number? </span>
                        <Link end="true" to={routes.UPDATE_MOBILE_NUMBER_ENDPOINT}>Click here</Link>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default PhoneNoLogin