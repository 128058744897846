import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import {viewProfile} from '../../redux/actions/AuthActions/ViewProfileAction'
import ImageComponent from '../../components/ImageComponent/ImageComponent'
import './Profile.scss'
import { CircularProgress } from '@mui/material'
import { viewGuestProfile } from '../../redux/actions/GuestListActions/GuestListAction'
import * as routes from '../../routes/route'

function Profile() {
  const location = useLocation()

  const {loading, userData} = useSelector(state => state.loginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    if(location?.state?.user_id)
      dispatch(viewGuestProfile({user_id: location?.state?.user_id}))
    else
      dispatch(viewProfile())
  },[])
  

  return (

      <>
        <div className='profile-container'>
          <div className="container">
            <div className="profile-heading" data-testid="profile-heading">
              Profile
            </div>
            {
              loading  ?
              <div className="loader">
                <CircularProgress sx={{'color': '#74613C'}}/>
              </div>
              :
              userData
              &&
              <div className="profile-content">
                <div className='d-flex align-items-end'>
                  <div className="profile-img-container">
                    <ImageComponent className="profile-img" src={userData?.data?.profile_pic || ''} fetch={true}/>
                  </div>
                  <div className='ms-4'>
                    <div className="name mb-3">{userData?.data?.first_name + " " + userData?.data?.last_name}</div>
                    {
                      !location?.state?.user_id
                      &&
                      <Button className='edit-btn' onClick={()=>{navigate(routes.EDIT_PROFILE_ENDPOINT)}}>Edit Profile</Button>
                    }
                  </div>
                </div>
                <div className=" profile-data">
                  <div className="data-block d-flex" data-testid="first_name">
                    <div className="title">First Name: </div>
                    <div className="value">{userData?.data?.first_name || '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="last_name">
                    <div className="title">Last Name: </div>
                    <div className="value">{userData?.data?.last_name || '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="phone_no">
                    <div className="title">Mobile Number: </div>
                    <div className="value">{userData?.data?.phone_no || userData?.data?.phone_number || '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="email_id">
                    <div className="title">Email Address: </div>
                    <div className="value">{userData?.data?.email_id || '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="DOB">
                    <div className="title">Date of Birth: </div>
                    <div className="age-muted-text">{userData?.data?.age && `(${userData?.data?.age} Years)`}</div>
                    <div className="value">{userData?.data?.DOB ? moment(userData?.data?.DOB, "MM/DD/YYYY").format("DD/MM/YYYY") : '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="gender">
                    <div className="title">Gender: </div>
                    <div className="value">{userData?.data?.gender || '-'}</div>
                  </div>
                  <div className="data-block d-flex" data-testid="address">
                    <div className="title">Address: </div>
                    <div className="value">{userData?.data?.address || '-'}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </>
  )
}

export default Profile