import moment from 'moment'
import { PhysioArrivalConsultationField, TCMArrivalConsultationField } from '../NoteFormsConstants'
import RadioButton from '../../../../components/RadioButton/RadioButton'
  

export const ViewPhysioArrivalConsultation = ({content})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{content.conducted_by || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>

                <div className='m-3'>
                    <div className='fw-semibold  text-secondary mb-3'>Numeric Pain Rating Scale (NPRS)  </div>
                    <table className='w-100'>
                        <tbody>
                            <tr className='fw-bold text-secondary'>
                                <td className="fw-semi-bold mr-2 flex-shrink-0 border p-2 col-3" style={{width: '230pxitem.labe'}}></td>
                                <td className="text-break white-space p-2 col-3 border">Pain Level</td>
                                <td className="text-break white-space p-2 col-6 border">Remarks</td>
                            </tr>
                            {
                                PhysioArrivalConsultationField?.numericPainRating?.map((item, index)=>{
                                    return(
                                        <tr key={index}>
                                            <td className="fw-semi-bold mr-2 flex-shrink-0 border p-2" style={{width: '230px'}}>{item.label}</td>
                                            <td className="text-break white-space p-2 border">{content.data?.numeric_pain_rating?.[item.key]?.value || '0'}</td>
                                            <td className="text-break white-space p-2 border">{content.data?.numeric_pain_rating?.[item.key]?.remarks || '-'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div>
                        <div className="sub-heading text-secondary text-uppercase fw-bold mt-5">
                        Pain Symptom (Highest score1) 
                        </div>
                        <div>
                            {
                                PhysioArrivalConsultationField?.painSymptoms?.map((item, index)=>{
                                    return (
                                        <div className='mt-3' key={index}>
                                            <div className="fw-semi-bold">{item.label}:</div>
                                            <div>
                                            {
                                                item.options?.map((subItem, subIndex)=>{
                                                    return (
                                                        <div className='d-flex' key={subIndex}>
                                                            <RadioButton
                                                                label={subItem}
                                                                name={item.key}
                                                                value={subItem}
                                                                checked={content?.data?.pain_symptom?.[item.key]===subItem}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='pt-3 row'>
                        <div className="col-3 fw-semi-bold">
                        Activities that relieves symptoms:
                        </div>
                        <div className='col-9'>
                            {content?.data?.activities_that_relives_symptoms || '-'}
                        </div>
                    </div>
                    <div className='pt-2 row'>
                        <div className="col-3 fw-semi-bold ">
                        Activities that increases symptoms: 
                        </div>
                        <div className='col-9'>
                        {content?.data?.activities_that_increases_symptoms || '-'}
                        </div>
                    </div>
                    <div>
                        {
                            PhysioArrivalConsultationField?.aboutPain?.map((item, index)=>{
                                return (
                                    <div className='mt-4' key={index}>
                                        <div className="fw-semi-bold mb-2">{item.label}:</div>
                                        <div>
                                        {
                                            item.options?.map((subItem, subIndex)=>{
                                                return (
                                                    <div className='d-flex mb-1' key={subIndex}>
                                                        <RadioButton
                                                            label={subItem}
                                                            name={item.key}
                                                            value={subItem}
                                                            checked={content?.data?.pain_symptom?.[item.key]===subItem}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                    <hr />
                    <div>
                        <div className="sub-heading text-secondary fw-semi-bold text-uppercase mt-3 mb-2">
                        Pain and Functional Assessment Details
                        </div>
                        <div>
                            {
                                PhysioArrivalConsultationField?.painAndFunctionalAssessment?.map((item, index)=>{
                                    return (
                                        <div className=' row' key={index}>
                                            <div className="col-3 fw-semi-bold mt-2">
                                            {item.label}:
                                            </div>
                                            <div className='col-9'>
                                            {content?.data?.pain_and_functional_assessment?.[item.key] || '-'}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>        
                    <hr />
                    <div>
                        <div className="sub-heading text-secondary fw-semi-bold text-uppercase mb-2">
                        Functional Assessment
                        </div>
                        <div>
                            {
                                PhysioArrivalConsultationField?.functionalAssessment?.map((item, index)=>{
                                    return (
                                        <div className=' row' key={index}>
                                            <div className="col-3 fw-semi-bold mt-2">
                                            {item.label}:
                                            </div>
                                            <div className='col-9 mt-2'>
                                            {content?.data?.functional_assessment?.[item?.key] || '-'}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>                

                </div>
            </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className="p-3">
                <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                {
                        content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                            return (
                                <div className='border mb-3' key={index}>
                                    <div className='fw-semibold p-2 text-center bg-light text-uppercase'> Objective {(index+1)?.toString()?.padStart(2,0)}: </div>
                                    <table className='p-2 w-100'>
                                        <tbody>
                                        {
                                            PhysioArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                <tr className='d-flex' key={subIndex}>
                                                    <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                    <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                </tr>
                                                ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
