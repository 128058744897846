import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import "./MultiSelect.scss";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label className={`d-inline-flex align-items-center w-100`} style={{cursor: 'pointer'}}>
            <span style={{width: '14px', height: '14px', border: '1px solid #CCC', backgroundColor: props.isSelected ? '#74613C': '#FFF', flexShrink: 0}} className={`mt-1 me-3`}>
                {props.isSelected && (
                <svg style={{marginTop: '-8px'}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                )}
            </span>
            <span className="mt-1">{props.label}</span>
        </label>
      </components.Option>
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  if (!props.hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = 1;
  const [chips, otherChildren] = children;
  const overflowCounter = props.getValue().length;
  const displayChips = chips?.slice?.(0, CHIPS_LIMIT);

  return (
    <components.ValueContainer {...props}>
        <div className="d-flex align-items-center justify-content-between w-100">
            <div >{displayChips}</div>
            {overflowCounter > CHIPS_LIMIT && <span style={{flexShrink: 0, minWidth:0, backgroundColor:'#74613C'}} className="text-light rounded px-1 ms-2">{overflowCounter > CHIPS_LIMIT && `+ ${overflowCounter - CHIPS_LIMIT}`}</span>}
        </div>
        {otherChildren}
    </components.ValueContainer>
  );
};

const MenuContainer = ({ children, ...props }) => {
  return (
    <components.Menu {...props}>
      {children}
      <div className="text-end m-2">
        <button onClick={()=>props.handleOk(props.selectProps.value)}>Ok</button>
      </div>
    </components.Menu>
  );
};


const MenuListContainer = ({ children, ...props }) => {
  const [allSelected, setAllSelected]= useState(false);

  const handleAllClick = ()=>{
    if(props.getValue()?.length< props.options.length){
      setAllSelected(true);
      props.setValue(props.options)
    }else{
      setAllSelected(false);
      props.setValue([])
    }
  }

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { setAllSelected })
  );

  useEffect(()=>{
    if(props.selectProps.value.length === props.options.length){
      setAllSelected(true)
    }else{
      setAllSelected(false)
    }
  }, [props.selectProps.value])

  return (
    <components.MenuList {...props}>
      <div onClick={handleAllClick}>
        <Option label='All' isSelected={allSelected} setAllSelected={setAllSelected} {...props} />
      </div>
      {childrenWithProps}
    </components.MenuList>
  );
};

const MultiValueRemoveContainer = ({ children, ...props }) => {

  return (
    <div className="d-none align-items-center w-100" onClick={()=>console.log('first')}>
      <components.MultiValueRemove {...props}>
        {children}
      </components.MultiValueRemove>
    </div>
  );
};

const MultiValueLabelContainer = ({ children, ...props }) => {

  return (
      <components.MultiValueLabel {...props}>
        {props.selectProps.value?.length === props.selectProps.options?.length ? "All" : children}
      </components.MultiValueLabel>
  );
};

export const MultiSelect = ({
  options,
  onSelect=undefined,
  width,
  height,
  placeholder,
  value,
  defaultValue,
  onClickOk

}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "0.5px solid #DDDDDD",
      minHeight: '38px',
      height: height || "43.6px",
      borderRadius: "10px",
      fontWeight: 400,
      color:' #AAAAAA',
      backgroundColor: '#F1F1F1',
      fontFamily: 'Montserrat'
    }),
    container: (provided,state) => ({
      ...provided,
      width: width || "200px",
      
    }),
    valueContainer: (provided,state) => ({
      ...provided,
      height: 'fit-content',
      margin: '0',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'none',
      'input[aria-readonly="true"]': {
        position: 'absolute',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      fontWeight: 600,
      color: 'black',
      wordBreak: 'break-word'
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0 8px ',

    }),
    menuList: (provided,state) => ({
      ...provided,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      
    }),
    multiValue: (provided,state) => ({
      ...provided,
      width: '95%',
      backgroundColor: '#f2d9ba'
      
    }),
  };

  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  const handleOk= (values)=>{
    setIsOpen(false)
    onClickOk?.(values)
  }

  const handleBlur= (values)=>{
    setIsOpen(false); 
    onClickOk(selectRef?.current?.getValue())
  }


  const renderCustomMenu  = (props) => (
    <MenuContainer isOpen={isOpen} handleOk={handleOk} {...props}/>
  )

  useEffect(()=>{
    selectRef?.current?.setValue(defaultValue)
  }, [defaultValue])

  return (
    <Select
      ref={selectRef}
      placeholder={placeholder}
      className="react-select"
      defaultValue={defaultValue}
      classNamePrefix="react-multi-select"
      isMulti={true}
      onChange={onSelect}
      options={options}
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        isFocused: "#DDD",
        colors: {
          ...theme.colors,
          primary25: "#f5f3ee",
          primary: "#f5f3ee",
        },
      })}
      closeMenuOnSelect={false}
      onBlur={handleBlur}
      hideSelectedOptions={false}
      menuIsOpen={isOpen}
      onMenuOpen={()=>{setIsOpen(true)}}
      components={{
        Option: Option,
        ValueContainer: CustomValueContainer,
        Menu:  renderCustomMenu,
        MenuList: MenuListContainer,
        MultiValueRemove: MultiValueRemoveContainer,
        MultiValueLabel: MultiValueLabelContainer
      }}
      
      isClearable={false}
      isSearchable={false}
    />
  );
};

export default MultiSelect;

