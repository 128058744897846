import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './SearchResult.scss'
import { useDispatch, useSelector } from 'react-redux';
import backArrow from '../../assets/images/back-arrow.svg'
import defaultUser from '../../assets/images/defaultUser.png'
import rightArrow from '../../assets/images/right-arrow.svg'
import playBtn from '../../assets/images/play.svg'
import reportIcon from '../../assets/images/report.svg'
import assessmentIcon from '../../assets/images/assessment.svg'
import dietIcon from '../../assets/images/prepratory-diet.svg'
import notestIcon from '../../assets/images/notes.svg'
import sessiontIcon from '../../assets/images/session.svg'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { app } from '../../firebase/firebaseConfig';
import {clearSearchedGuestListResult, getSearchedGuestListResult} from '../../redux/actions/GuestListActions/GuestListAction'
import Guest from './Guest/Guest'
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import * as routes from '../../routes/route'
import getPermissions from '../../utils/helpers/getPermissions';
import chatIcon from '../../assets/images/chat.png'

function SearchResult() {
    const {guestList, loading} = useSelector(state=> state.searchedGuestResult)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
        const data= location.state;
        if(!guestDetails)
            dispatch(getSearchedGuestListResult(data))
    },[])

    const {guestDetails, guestType, navigateToDetails} = location.state;
    const [imageUrl, setImageUrl] = useState(null);
    const [programMediaUrl, setProgramMediaUrl] = useState(null);
    const vidRef = useRef(null);

    const ITEMS = [
        {
            title: 'Pre Arrival Consultation',
            image: assessmentIcon,
            path: routes.VIEW_PRELIM_ASSESMENT_ENDPOINT,
            permissions: getPermissions("prelim_assessment")
        },
        {
            title: 'Health Assessment Report',
            image: reportIcon,
            path: routes.VIEW_WELLBEING_REPORT_ENDPOINT,
            permissions: getPermissions("wellbeing_report")
        },
        {
            title: 'Preparatory Diet',
            image: dietIcon,
            path: routes.VIEW_PREPARATORY_DIET_ENDPOINT,
            permissions: getPermissions("preparatory_diet")
        },
        {
            title: 'Consultation Recommendations',
            image: notestIcon,
            path: routes.VIEW_CONSULTATION_NOTES_ENDPOINT,
            permissions: getPermissions("consultation_notes")
        },
        {
            title: 'Sessions',
            image: sessiontIcon,
            path: routes.VIEW_SESSION_ENDPOINT,
            permissions: getPermissions("session_module")
        }
    ]
    

    const handleFullScreen=()=>{
        const elem = vidRef.current
        if(elem){ //checking if ref was initiated
            elem?.play?.()
            if (elem?.requestFullscreen) {
            elem?.requestFullscreen?.();
            } else if (elem?.webkitRequestFullscreen) { /* Safari */
            elem?.webkitRequestFullscreen?.();
            } else if (elem?.msRequestFullscreen) { /* IE11 */
            elem?.msRequestFullscreen?.();
            }
        }
    }

    useEffect(()=>{
        
        if(guestDetails?.profile_pic.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, guestDetails?.profile_pic);
    
                getDownloadURL(imageRef).then((url) => {
                    setImageUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){

            }
        }

        if(guestDetails?.program_media.length){
            try{
                const storage = getStorage(app);
                const imageRef = ref(storage, guestDetails?.program_media);

                getDownloadURL(imageRef).then((url) => {
                    setProgramMediaUrl(url);
                }).catch((error) => {
                console.error("Error getting download URL: ", error);
                });
            }catch(e){
            }
        }
        
    }, [guestDetails]);

    useEffect(() => {
        if(navigateToDetails && !loading && guestList?.[0]){
            let data = guestList?.[0]
                let guestType = ''
                if (moment().isSameOrBefore(moment(data?.check_in))) guestType = 'upcoming'
                else if (moment().isSameOrAfter(moment(data?.check_out))) guestType = 'complete'
                else guestType = 'active'
            navigate(routes.SEARCH_RESULT_ENDPOINT, {replace:true, state:{guestDetails: guestList?.[0], guestType}})
        }
    }, [guestList])


    useEffect(()=>{
        window.addEventListener('fullscreenchange', ()=>{
            const elem = vidRef.current

            if (document.exitFullscreen || document.webkitExitFullscreen || document.msExitFullscreen) { 
                vidRef?.current?.pause?.()
            }
            
            if ((document.webkitIsFullScreen  || document.mozFullScreen  || document.msFullscreenElement) && vidRef.current) { /* IE11 */
                vidRef.current.currentTime = 0;
                vidRef.current?.play?.()
            }
        })

        return(()=>{
            window.removeEventListener('fullscreenchange',()=>{
             
            })
            dispatch(clearSearchedGuestListResult())
        })
    }, [])
    
  return (
    <>
            <div className="search-result-container">
                <div className="container h-100">
                    <div className="d-flex justify-content-between">
                        <div className="search-result-heading">
                            <img className='back-arrow' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                            &nbsp;
                            Result
                        </div>
                        {
                            !loading && getPermissions("wellbeing_queries")!=='hide' &&
                            <a className='text-decoration-none search-btn me-2 ' href={`${routes.QUERY_ENDPOINT}/${guestList?.[0]?.user_id || guestDetails?.user_id}`}>
                                <img className='me-2'  src={chatIcon} width={'20px'} alt="" />
                                Queries
                            </a>
                        }
                    </div>
                    
                    <div className="pt-4 pb-5">
                        <div className="search-result-content">
                            {
                                guestDetails ?
                                <>
                                    <div className='guest-details'>
                                        <div className="d-flex">
                                            <div className='profile-img'>
                                                <img src={!guestDetails?.profile_pic ? defaultUser : imageUrl } alt=""  width={"100%"}/>
                                            </div> 
                                            <div className='profile-content'>
                                                <div className="guest-name">{guestDetails?.first_name} {guestDetails?.last_name}</div>
                                                <div className="guest-phone mb-1"><span className="text-muted">Phone Number</span> {guestDetails?.phone_number}</div>
                                                <div className='view-profile-btn text-primary' onClick={()=>{navigate(routes.VIEW_PROFILE_ENDPOINT, {state:{user_id: guestDetails?.user_id}})}}>View Profile</div>
                                            </div>
                                        </div>

                                        <div className="hr"></div>

                                        <div>
                                            <div className="heading">Program</div>
                                                <div className="d-flex">
                                                    <div className='program-video'>
                                                        {
                                                            guestDetails?.media_type === 'video'
                                                            ?
                                                            <>
                                                                <img className='play-btn' src={playBtn} alt="" onClick={handleFullScreen} />
                                                                <video ref={vidRef} src={!guestDetails?.program_media ? defaultUser : programMediaUrl+'#t=0.5' } alt=""  width={"100%"} ></video>
                                                            </>
                                                            :
                                                            <img src={!guestDetails?.program_media ? defaultUser : programMediaUrl } alt=""  width={"100%"}/>
                                                        }
                                                    </div> 
                                                    
                                                    <div className='program-details'>
                                                        <div className="program-title">{guestDetails?.title}</div>
                                                        <div className='d-flex'>
                                                            <div className="guest-check">Check-In: &nbsp; &nbsp; &nbsp;</div>
                                                            <div className="date-time">{moment(guestDetails?.check_in).format('MMM DD, dddd')}</div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className="guest-check">Check-Out: &nbsp;</div>
                                                            <div className="date-time">{`${moment(guestDetails?.check_out).format('MMM DD, dddd')} (${guestDetails?.no_of_nights} Nights)`}</div>
                                                        </div>  
                                                        <div className='d-flex'>
                                                            <div className="guest-check">Room No.: &nbsp;</div>
                                                            <div className="date-time">{guestDetails?.room_no || '-'}</div>
                                                        </div>  
                                                        <div className='d-flex'>
                                                            <div className="guest-check">Lead Doctor: &nbsp;</div>
                                                            <div className="date-time">{guestDetails.assign_lead_full_name || '-'}</div>
                                                        </div>  
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="d-flex justify-content-between mb-2 guest-login-data">
                                            <div>
                                                <span className='title'>Welcome Email Sent: </span>
                                                <span>{guestDetails?.welcome_email_sent ? moment(guestDetails?.welcome_email_sent).format('DD MMM, YYYY') : '-'}</span>
                                            </div>
                                            <div>
                                                <span className='title'>Last Login: </span>
                                                <span>{guestDetails?.last_login_date ? moment(guestDetails?.last_login_date).format('DD MMM, YYYY hh:mm A') : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="hr"></div>

                                        {
                                            ITEMS?.map((item, index)=>{
                                                return (
                                                    <React.Fragment key={index}>
                                                    <div className={`d-flex justify-content-between align-items-center report ${item.permissions==='hide' ? 'opacity-50' : ''}`} 
                                                        onClick={item.permissions==='hide' ? undefined : ()=>{navigate(item.path, {state: { guestId: guestDetails?.user_id, userProgramId: guestDetails?.user_program_id, type: guestType?.toLowerCase(), is_self_assessment_enabled: guestDetails?.is_self_assessment_enabled, is_self_assessment_active: guestDetails?.is_self_assessment_active}})}}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className="icon-box"><img src={item?.image} width={'15px'} alt="" /></div>
                                                            <span className={`fw-bold ${item.permissions==='hide' ? 'text-muted' : ''}`}>{item.title}</span>
                                                        </div>
                                                        <div>
                                                            <img src={rightArrow} width={'23px'} alt="" />
                                                        </div>
                                                    </div>
                                                    {ITEMS?.length !== index+1 && <div className="hr"></div>}
                                                    </React.Fragment>
                                                )
                                            })
                                        }


                                        <div className="hr"></div>

                                        <div className='payment-details'>
                                            <div className="d-flex justify-content-between">
                                                <div className="heading">Payment Status</div>
                                                <div className={guestDetails?.payment_detail?.payment_status === 'Not Recieved'? 'payment-status text-danger': 'payment-status text-success'} >{guestDetails?.payment_detail?.payment_status === 'Not Recieved' ? 'Not Recieved' : 'Recieved'}</div>
                                            </div>
                                            <div className="amount">Amount: ₹{guestDetails?.payment_detail?.revenue}</div>
                                            <div className='date'>Date: {moment(guestDetails?.payment_detail?.sales_date, 'YYYY-MM-DD hh:mm:ss(UTC)').format('DD MMM, YYYY')}</div>
                                        </div>

                                        <div></div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        loading  ?
                                        <div className="loader text-center">
                                            <CircularProgress sx={{'color': '#74613C'}}/>
                                        </div>
                                        :

                                        !navigateToDetails && guestList?.map((item, index)=>{
                                            return (
                                                <Guest item={item} key={index}/>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default SearchResult