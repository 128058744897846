import { useField } from 'formik'
import React, { memo } from 'react'
import './InputBox.scss'

function InputBox({name, type, icon, placeholder, disabled, testID}) {
    const props = type === 'textarea' ? {name} : {type, name}
    const [field, meta] = useField(props);
    

  return (
    <div className='form-input-container' >
        <div className='d-flex w-100 align-items-center inputbox' style={{height: type==="textarea" ? "200px": "50px"}}>
            {icon && <img src={icon} alt="" className='icon-style'/>}
            {
                type==='textarea'?
                <textarea name={name}  {...field} data-testid={testID}/>
                :
                type==="tel" ?
                <input 
                    type={"number"} 
                    name={name}  
                    placeholder={placeholder}  
                    maxLength="15" 
                    autoComplete='off'
                    {...field}
                    data-testid={testID}
                />
                :
                <input 
                    data-testid={testID}
                    type={type} 
                    name={name}  
                    placeholder={placeholder} 
                    autoComplete='off'
                    {...field}
                    disabled={disabled ? true: false}
                />
            }
            
        </div>
        <div className='error'>
             &nbsp; 
        </div>
    </div>
  )
}

export default memo(InputBox)