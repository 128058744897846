import * as type from '../../constants';

const initialState = {
  otpFor: null,
  phone: null,
  email: null,
  success: false,
  loading: false,
  error: null,
}

export default function verificationReducer(state = initialState, action) {
  switch (action.type) {
    case type.OTP_REQUEST_START:
      return {
        ...state,
        loading: true,
      }
    case type.OTP_REQUEST_END:
      return {
        ...state,
        loading: false,
      }
    case type.SET_VERIFICATION_DATA:
      return {
        ...state,
        loading: false,
        otpFor: action.payload.otpFor,
        phone: action.payload?.phone,
        email: action.payload?.email
      }

    case type.REMOVE_VERIFICATION_DATA:
      return {
        ...state,
        loading: false,
        otpFor: null,
        phone: null,
        email: null
      }
    
    case type.SET_VERIFY_SUCCESS:
      return {
        ...state,
        success: true,
        otpFor: null,
        loading: false,
        phone: null,
        email: null
    }


    case type.SET_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}