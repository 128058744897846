import { call, put, takeEvery } from "redux-saga/effects";
import { SET_USER_DATA, UPDATE_PROFILE } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import moment from "moment";
import {logOut} from '../../../../utils/helpers/logout'
import apiEndpoints from "../../../../axios/api";
export const updateProfileReq = async({data})=>{
    
    let BODY = {
        "email_id": data.email_id,
        "phone_no": data.mobileNo,
        "first_name": data.first_name,
        "last_name": data.last_name,
        "date_of_birth": moment(data.DOB, "YYYY-MM-DD").format("MM/DD/YYYY"),
        "gender": data.gender,
        "profile_pic": data?.profile_pic || '',
        "address": data.address
      }
    return axiosInstance.put(apiEndpoints.UPDATE_PROFILE_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateProfile({data}){
    try{
        let result = yield call(updateProfileReq, {data})
        if(result.statusCode === 200){
            notify('success', result.message)
            const oldData = JSON.parse(localStorage.getItem('UserData'))
            const newData =  {...result.data, access_token: oldData.access_token, permissions: oldData.permissions, user_role_id: oldData.user_role_id}
            
            result = {...result, data:newData}
            localStorage.setItem("UserData", JSON.stringify(newData))
            yield put({ type: SET_USER_DATA, payload: result});
        }      
    }catch(error){
    }
}


function* updateProfileSaga(){
    yield takeEvery(UPDATE_PROFILE, updateProfile)
}

export default updateProfileSaga;