import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from "@mui/material/styles";
import chatIcon from '../../assets/images/chat.png'
import './Query.scss'
import QueryTab from './QueryTab/QueryTab';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getQueryList } from '../../redux/actions/QueryActions/QueryAction';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import backArrow from '../../assets/images/back-arrow.svg'


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Query() {
  const params = useParams()
  let guestId=params?.guestId ? parseInt(params?.guestId) : ''

  const currentTab = [0, 1, 2].includes(parseInt(localStorage.getItem('activeQueryTab'))) ? parseInt(localStorage.getItem('activeQueryTab')) : 0
  const [value, setValue] = React.useState(currentTab);
  const assignedTo = useSelector(state=> {
    return currentTab === 0
      ? state.queryListReducer.activeQuery.assigned_to
          : state.queryListReducer.closedQuery.assigned_to
  })
  // const [assignedTo, setAssignedTo] = React.useState(guestId ? 1 : 0);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const StyledTab = styled(Tab)({
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "'Montserrat', sans-serif",

    "&.Mui-selected": {
      color: "#74613C",
    }
  });

  const handleTabSelect = (event, index) => {
    localStorage.setItem('activeQueryTab', index);
    setValue(index);
};

const assignedOptions=[
  {label: 'All Queries', value: 0},
  {label: 'Department Queries', value: 2},
  {label: 'Assigned To Me', value: 1},
]


  return (
    <>
        <div className='query-container'>
            <div className="container">
                <div className="query-heading">
                    <div>                            
                      <img className='back-arrow me-3' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                      <img className='me-2'  src={chatIcon} width={'30px'} alt="" />Queries
                    </div>
                    <div className="filter d-flex m-2 justify-content-end">
                  {
                    !guestId &&
                    <>
                      <div className="title me-3">Assigned To:</div>
                        <Select
                          className="name-select"
                          classNamePrefix="select"
                          // isLoading={isLoading}
                          isSearchable={true}
                          name="color"
                          options={assignedOptions}
                          placeholder='Select'
                          onChange={(e)=>{
                            // setAssignedTo(e.value);    
                            if(e.value !==assignedTo)
                              dispatch(getQueryList({flag: + !currentTab, page: 1, assignedTo: e.value, guestId, filterChange: true}))
                          }}
                          value={{
                            label: assignedOptions?.find(item=> item.value=== assignedTo)?.label,
                            value: assignedTo
                          }}
                      />
                    </>

                    }
                  </div>
                    </div>
                <div className="query-content">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} 
                            
                              onChange={handleTabSelect} 
                              aria-label="basic tabs example" 
                              variant="fullWidth"
                              TabIndicatorProps={{
                                  style: {
                                  backgroundColor: "#74613C",
                                  }
                              }}
                              >
                            <StyledTab  label="Active" {...a11yProps(0)} />
                            <StyledTab  label="Closed" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        <CustomTabPanel value={value} index={0}>
                            <QueryTab index={0} type={"active"} assignedTo={assignedTo} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <QueryTab index={1} type={"closed"} assignedTo={assignedTo} />
                        </CustomTabPanel>

                    </Box>
                </div>
            </div>
        </div>
    </>
    
  );
}