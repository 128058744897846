import { put, takeEvery } from "redux-saga/effects";
import {  REMOVE_VERIFICATION_DATA, CALL_REMOVE_VERIFICATION_DATA } from "../../../constants";


function* setVerifyStatusFalse(){
    yield put({ type: REMOVE_VERIFICATION_DATA })
}

function* verifynSaga(){
    yield takeEvery(CALL_REMOVE_VERIFICATION_DATA, setVerifyStatusFalse)
}

export default verifynSaga;