import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "./../../../axios/axiosInstance";
import { notify } from "./../../../utils/notification";
import moment from "moment";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";

export const getGuestListReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_GUEST_LIST_API}?filter_type=${data.filter_type}&datetime=${moment().format('YYYY-MM-DD HH:mm:ss')}${data.page ? `&page_no=${data?.page}` : ''}&assigned_users=${data?.assignedTo}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response?.data?.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getGuestList({data}){
    try{
        yield put({ type: types.GUEST_LIST_LOADING })
        let result = yield call(getGuestListReq, {data})
        if(result.statusCode === 200){
            if(data.filter_type === -1){
                yield put({ type: types.SET_TENTATIVE_GUEST, payload: result.data, data});
            }else if(data.filter_type === 0){
                yield put({ type: types.SET_UPCOMING_GUEST, payload: result.data, data});
            }else if (data.filter_type === 1){
                yield put({ type: types.SET_ACTIVE_GUEST, payload: result.data, data});
            }else if (data.filter_type === 2){
                yield put({ type: types.SET_COMPLETED_GUEST, payload: result.data, data});
            }
        }else{
            yield put({type: types.GUEST_LIST_FALIURE, data: data})
        }
        
    }catch(error){
        console.log(error)
    }
}



export const getGuestNameListReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_GUEST_NAME_LIST_API}?filter_type=${data.filter_type}&datetime=${moment().format('YYYY-MM-DD HH:mm:ss')}${data.page ? `&page_no=${data?.page}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getGuestNameList({data}){
    try{
        let result = yield call(getGuestNameListReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GUEST_NAME_LIST_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GUEST_NAME_LIST_FALIURE})
        }
        
    }catch(error){
        console.log(error)
    }
}


export const getSearchedGuestListResultReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_SEARCHED_GUEST_LIST_API}?filter_type=${data?.filter_type}${data?.user_program_id ? `&user_program_id=${data?.user_program_id}` : ''}${data?.room_details ? `&room_details=${encodeURIComponent(data?.room_details)}` : ''}${data?.email ? `&email=${encodeURIComponent(data?.email)}` : ''}${data?.phone_number ? `&phone_number=${encodeURIComponent(data?.phone_number)}` : ''}&datetime=${moment().format('YYYY-MM-DD HH:mm:ss')}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getSearchedGuestListResult({data}){
    try{
        let result = yield call(getSearchedGuestListResultReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.SEARCH_GUEST_LIST_RESULT_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.SEARCH_GUEST_LIST_RESULT_FALIURE})
        }
        
    }catch(error){
        console.log(error)
    }
}

export const viewGuestProfileReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.VIEW_GUEST_PROFILE_API}?user_id=${data.user_id}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400 ) {
                notify('error', "Unable to get data.");
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* viewGuestProfile({data}){
    yield put({ type: types.LOGIN_REQUEST_START});
    try{
        let result = yield call(viewGuestProfileReq, {data})
        if(result.statusCode === 200){
            
            yield put({ type: types.SET_USER_DATA, payload: result});
        }      
        yield put({ type: types.SET_USER_DATA, payload: result});
        yield put({ type: types.LOGIN_REQUEST_END});

    }catch(error){
        yield put({ type: types.LOGIN_REQUEST_END});

    }
}



function* guestListSaga(){
    yield takeEvery(types.GET_GUEST_LIST, getGuestList);
    yield takeEvery(types.GUEST_NAME_LIST, getGuestNameList)
    yield takeEvery(types.SEARCH_GUEST_LIST_RESULT, getSearchedGuestListResult)
    yield takeEvery(types.VIEW_GUEST_PROFILE, viewGuestProfile)

}

export default guestListSaga;