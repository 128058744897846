import {
    UPDATE_MOBILE,
    UPDATE_VERIFY_OTP,
    UPDATE_RESEND_OTP,
} from '../../constants';


export function update(data) {
    return {
        type: UPDATE_MOBILE,
        data,
    };
}

export function updateVerifyOTP(data) {
    return {
        type: UPDATE_VERIFY_OTP,
        data,
    };
}

export function updateResendOTP(data) {
    return {
        type: UPDATE_RESEND_OTP,
        data,
    };
}