import React, { useCallback, useEffect, useState } from 'react'
import './AddEditSessions.scss'
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EditorToolbar, { modules, formats }  from '../../../components/EditorToolbar/EditorToolbar'
import ReactQuill from 'react-quill';
import { CircularProgress } from '@mui/material';
import openEyeBtn from '../../../assets/images/eye-open.svg'
import closeEyeBtn from '../../../assets/images/eye-close.svg'
import { notify } from '../../../utils/notification';
import { addSessionNote, deleteSessionNote, viewSessionNotes } from '../../../redux/actions/GuestDataActions/SessionsActions';
import deleteBtn from '../../../assets/images/delete.svg'
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import ChangeVisibilityModal from '../../../components/ChangeVisibilityModal/ChangeVisibilityModal';

function AddEditSessions({action}) {
  const location = useLocation();
  const { guestId, sessionId, startTime, title} = location.state;
  const [eye, setEye] = useState(true)
  const [content, setContent] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sessionData = useSelector(state=> state.sessionReducer)
  const [deleteModal, setDeleteModal] = useState(false)
  const [visibilityModal, setVisibilityModal] =useState(false)

  const handleProcedureContentChange = (content, delta, source, editor) => {
    editor.getHTML(); // rich text
    editor.getText(); // plain text
    editor.getLength(); // number of characters
    setContent(content);
  };

  const deleteAssessment  = ()=>{
    dispatch(deleteSessionNote({sessionId}))
    setDeleteModal(false)
}

  const handleSubmit = ()=>{
    if(!content){
      notify('warning', 'Please Fill all Fields')
    }else{
      dispatch(addSessionNote({
        guestId,
        isVisible: eye,
        content,
        startTime,
        title,
        sessionId
      }))

    }
  }

  const handleEye = ()=>{
    setEye(prev=> !prev);
    setVisibilityModal(false)
}


  const handleBlur = useCallback(() => {
      setContent((prevText) => prevText);
  }, [content]);

  useEffect(()=>{
      dispatch(viewSessionNotes({sessionId}))
  }, [])

  useEffect(()=>{
    if(sessionData?.singleSession){
      setContent(sessionData?.singleSession?.session_notes)
      setEye(sessionData?.singleSession?.is_notes_guest_visible)
    }
  },[sessionData?.singleSession])

  return (
    <>
      <div className="add-edit-session-notes-container">
        <div className="container h-100">
            <div className="add-edit-session-notes-heading">
              Session Notes
            </div>
            {   
              sessionData?.loading && action !== 'add' ?
                <div className="loader">
                    <CircularProgress sx={{'color': '#74613C'}}/>
                </div>

                :
                <div className="pt-4 pb-5">
                  <div className="add-edit-session-notes-content">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <div className="heading">{action === 'add' ? 'Add' : (action === 'edit' ? 'Update' : 'View')} Note</div>
                      {
                        action !== 'view' &&
                        <div>
                          <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                          <button className="edit-btn" type='submit' onClick={handleSubmit}>
                            {
                              sessionData?.updateLoader ?
                              <CircularProgress sx={{'color': '#fff'}} size={15}/>
                              : (action === 'add' ? 'Add' : 'Update')
                            }
                          </button>
                        </div>
                      }
                    </div>

                    <div className="form p-3">
                      <div className="d-flex justify-content-end mb-3 ">
                        <div className='visible-btn' onClick={action === 'view' ? undefined : ()=>{setVisibilityModal(true)}}>
                          <img src={eye ? openEyeBtn : closeEyeBtn} alt="eye-btn" className="me-2"/>
                          <span className="title">{eye ? 'Visible to Guest' : 'Hide from Guest'}</span>
                        </div>
                      </div>
                      <div className='quilDiv'>
                        <div className={action==='view' ? 'd-none' : ''}><EditorToolbar /></div>
                        <ReactQuill
                          theme="snow"
                          value={content}
                          className={action==='view' ? 'view-quillMainDiv' : 'quillMainDiv'}
                          onChange={handleProcedureContentChange}
                          onBlur={handleBlur}
                          placeholder={action!=='view' && 'Enter Keywords ...'}
                          formats={formats}
                          modules={modules}
                          readOnly={action==='view'}
                        />
                      </div>
                      {
                        action!== 'add' &&
                        <div className="fw-bold d-flex justify-content-end mt-2 text-danger" style={{cursor: 'pointer'}}  onClick={()=>setDeleteModal(true)}>
                          <img src={deleteBtn} alt="del-btn" width={15}/>
                          &nbsp; Remove
                        </div>
                      }
                    </div>

                  </div>
                </div>
            }
          </div>
        </div>
        <ChangeVisibilityModal
          warnModal={visibilityModal} 
          setWarnModal={setVisibilityModal} 
          onYes={handleEye}
          hide={eye}
        />
        <DeleteModal
          deleteModal={deleteModal} 
          setDeleteModal={setDeleteModal}
          name="preparatory diet"
          onDelete = {deleteAssessment }
      />
      </>
  )
}

export default AddEditSessions