import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './SearchGuest.scss'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import backArrow from '../../assets/images/back-arrow.svg'
import { getGuestNameList } from '../../redux/actions/GuestListActions/GuestListAction';
import * as routes from  '../../routes/route'


function SearchGuest() {
    const [name, setName] = useState('');
    const [roomNo, setRoomNo] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [guestType, setGuestType] = useState(3);
    const guestList = useSelector(state=> state.guestNameListReducer.guestList)
    const [nameOptions, setNameOptions] = useState([])

    const navigate = useNavigate()

    const handleSearch = ()=>{
        navigate(routes.SEARCH_RESULT_ENDPOINT,  {state:{filter_type: guestType, phone_number: phoneNo, email, room_details: roomNo, user_program_id: name?.user_program_id}})
    }

    const dispatch = useDispatch()

    useEffect(()=>{
        let data = {
            filter_type: guestType
        }
        dispatch(getGuestNameList(data))
    },[guestType])

    useEffect(()=>{
        if(guestList.length){
            let newArr = guestList.map((item) => {
                return { user_program_id: item.user_program_id, value: item.guest_id, label: item.full_name };
             });
            setNameOptions(newArr)
        }else{
            setNameOptions([])
        }
        setName('')
    },[guestList])
    
  return (
    <>
            <div className="search-guest-container">
                <div className="container h-100">
                    <div className="search-guest-heading">
                        <img className='back-arrow' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                        &nbsp;
                        Search
                    </div>
                    <div className="pt-4 pb-5">
                        <div className="search-guest-content p-4">
                            <div className="data-row">
                                <div className="title">Select Guest</div>
                                <div className="value d-flex flex-wrap">
                                    <div className='d-flex mb-1 checkbox-input'>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2' 
                                                checked={guestType === 3 ? true : false}
                                                onChange={()=>{setGuestType(3); setName('')}}
                                                data-testid="all_sections"
                                            />
                                        </div>
                                        <label htmlFor="" className={guestType === 3 ? "fw-600" : "fw-400"}>All</label>
                                    </div>
                                    <div className='d-flex mb-1 checkbox-input'>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2' 
                                                checked={guestType === 1 ? true : false}
                                                onChange={()=>{setGuestType(1); setName('')}}
                                                data-testid="active_sections"
                                            />
                                        </div>
                                        <label htmlFor="" className={guestType === 1 ? "fw-600" : "fw-400"}>Active</label>
                                    </div>
                                    <div className='d-flex mb-1 checkbox-input'>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2' 
                                                checked={guestType === 0 ? true : false}
                                                onChange={()=>{setGuestType(0); setName('')}}
                                                data-testid="upcoming_sections"
                                            />
                                        </div>
                                        <label htmlFor="" className={guestType === 0 ? "fw-600" : "fw-400"}>Upcoming</label>
                                    </div>
                                    <div className='d-flex mb-1 checkbox-input'>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2'
                                                checked={guestType === 2 ? true : false}
                                                onChange={()=>{setGuestType(2); setName('')}}
                                                data-testid="completed_sections"
                                            />
                                        </div>
                                        <label htmlFor="" className={guestType === 2 ? "fw-600" : "fw-400"}>Completed</label>
                                    </div>
                                </div>
                            </div>

                            <div className="data-row">
                                <div className="title">Search By Name</div>
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    // isLoading={isLoading}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={nameOptions}
                                    placeholder='Select'
                                    onChange={(e)=>{setName(e)}}
                                    value={name}
                                />
                            </div>

                            <div className="data-row">
                                <div className="title">Search By Room No.</div>
                                <div className='date-input'>
                                    <div className="input">
                                        <input type="text" name="" id="" placeholder='Enter Keywords' value={roomNo} onChange={e=>setRoomNo(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="data-row">
                                <div className="title">Search By Phone Number</div>
                                <div className='date-input'>
                                    <div className="input">
                                        <input type="text" name="" id="" placeholder='Enter Keywords' value={phoneNo} onChange={e=>setPhoneNo(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="data-row">
                                <div className="title">Search By Email</div>
                                <div className='date-input'>
                                    <div className="input">
                                        <input type="email" autoComplete='off' name="" placeholder='Email' value={email} id="" onChange={e=>setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="text-end">
                                <button className='reopen-btn' onClick={handleSearch} disabled={!(email || name || phoneNo || roomNo)}> SEARCH </button>
                            </div>
                        </div>
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default SearchGuest