import React from 'react';

function CheckBox({ text, initialchecked, onChange, className, disabled=false }) {

  return (
    <label className={`${className} d-inline-flex align-items-center`}>
      <input
        disabled={disabled}
        type="checkbox"
        className="d-none"
        checked={initialchecked}
        onChange={onChange}
      />
      <span style={{width: '16px', height: '16px', backgroundColor: initialchecked ? '#74613C' : '#fff'}} className={`border border-black mr-2 flex-shrink-0`}>
        {initialchecked && (
          <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '-8px', width: '15px'}} fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
          </svg>
        )}
      </span>
      {text}
    </label>
  );
}

export default CheckBox;
