import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import successIcon from '../../assets/images/success.svg'
import './SuccessModal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function SuccessModal({successModal, setSuccessModal, onSuccess, guestName}) {
  const handleClose = () => setSuccessModal(false);

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={successModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="success-modal">
            <div className="icon">
              <img src={successIcon} alt="" />
            </div>
            <div className="my-4">
              <div className="title">Success</div>
                {`${guestName}'s Assessment form updated successfully.`}
              </div>
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <div className="button" onClick={()=>{onSuccess(); setSuccessModal(false)}}>Ok</div>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}