import React from 'react'
import './TextField.scss'
import { useField } from 'formik';

export function TextFieldFM({name, unit, type, placeholder, testID, rows='5'}) {
  const props =  type==="numeric" ? {name, type:"text"} : {name}
  const [field, meta] =useField(props)

  return (
    <div className='prelim-text-field'>
      <div className="input">
        {
          type=== "numeric"
          ?
          <input type="number"
            maxLength="10"
            autoComplete='off'
            name={name} 
            pattern="[0-9]*"
            placeholder={placeholder}
          {...field}
          data-testid = {testID}
           />
          :
          <textarea  
            name={name} 
            rows={rows} 
            placeholder={placeholder}
            {...field}
            data-testid = {testID}
          >
          
          </textarea>
        }
        <div className='end-text'>{unit}</div>
      </div>
    </div>
  )
}

export function TextField({name, type, unit, value, placeholder, disabled=false, onChange, width, backgroundColor}) {

  return (
    <div className='prelim-text-field'>
      <div className="input"  style={{width: width, backgroundColor}}>
        {
          type=== "numeric"
          ?
          <input type="number"
            maxLength="10"
            autoComplete='off'
            style={{backgroundColor}}
            name={name} 
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
           />
          :
          <input  
            type="text"
            autoComplete='off'
            style={{backgroundColor}}
            name={name} 
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
        }
        <div className='end-text'>{unit}</div>
      </div>
    </div>
  )
}