import React from 'react'
import './Home.scss'
import { homeData } from '../../data/homeData'
import { Link } from 'react-router-dom'
import getPermission from '../../utils/helpers/getPermissions'



function Home() {
  return (
    <div className='container'>
        <div className="home">
            <div className="row">
              {
                homeData?.slice(0, 4)?.map((item, index)=>(
                  (!item.permissionKey || getPermission(homeData[4].permissionKey)) ?
                  <div className="col-6" key={index}>
                    <Link to={item.path} className="block">
                      {item.icon}
                      {item.title}
                    </Link>
                  </div>
                  :
                  <></>
                ))
              }
              {
                getPermission(homeData[4].permissionKey) &&
                <div className="col-12">
                  <Link to={homeData[4].path} className="block">
                    {homeData[4].icon}
                    {homeData[4].title}
                  </Link>
                </div>
              }
            </div>
        </div>
    </div>
  )
}

export default Home