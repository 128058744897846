import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { ReactComponent as SortIcon} from '../../../assets/images/uparrow.svg'
import styled from "@emotion/styled";
import {ReactComponent as EmailIcon} from '../../../assets/images/email2.svg'
import {ReactComponent as LoginIcon} from '../../../assets/images/login.svg'
import {ReactComponent as HAIcon} from '../../../assets/images/report-data.svg'
import {ReactComponent as PACIcon} from '../../../assets/images/pac.svg'
import {ReactComponent as QueryIcon} from '../../../assets/images/Query.svg'
import {ReactComponent as SessionIcon} from '../../../assets/images/session2.svg'
import {ReactComponent as PSCIcon} from '../../../assets/images/psc.svg'
import {ReactComponent as FeedbackIcon} from '../../../assets/images/feedback.svg'
import {ReactComponent as TestimonialIcon} from '../../../assets/images/testimonial.svg'
import {
  stableSort,
  getComparator,
  validateDate,
} from "../../../utils/helpers/universalFunctions";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { STATUS_REPORT_ENDPOINT } from "../../../routes/route";
import { CircularProgress, TablePagination, tablePaginationClasses } from "@mui/material";
import { ReactComponent as arrowDownIcon} from '../../../assets/images/arrow-down-brown.svg'
import * as routes from '../../../routes/route'
import getPermissions from "../../../utils/helpers/getPermissions";
import moment from "moment";
const statusClasses=['fade', 'brown', 'green']


const ITEMS = [
    {
        title: 'Email',
        image: <EmailIcon/>,
        key: "welcome_email_sent",
        hideToNonWellness: false,
        dataKey: "welcome_email_sent_datetime",
        path: routes.SEARCH_RESULT_ENDPOINT
    },
    {
        title: 'Login',
        image: <LoginIcon/>,
        key: "is_login",
        hideToNonWellness: false,
        dataKey: "last_login_datetime",
    },
    {
        title: 'Health Assessment',
        image: <HAIcon/>,
        key: "assessment_status",
        hideToNonWellness: true,
        dataKey: "assessment_filled_date",
        path: routes.VIEW_WELLBEING_REPORT_ENDPOINT,
        permissions: getPermissions("wellbeing_report"),
      },
      {
        title: 'PAC',
        image: <PACIcon/>,
        key: "is_pac_complete",
        hideToNonWellness: true,
        dataKey: "pac_datetime",
        path: routes.VIEW_PRELIM_ASSESMENT_ENDPOINT,
        permissions: getPermissions("prelim_assessment"),
    },
    {
        title: 'Query',
        image: <QueryIcon/>,
        key: "is_query_done",
        hideToNonWellness: true,
        dataKey: "query_status",
        path: routes.QUERY_ENDPOINT,
    },
    {
        title: 'Session Review',
        image: <SessionIcon/>,
        key: "session_review_filled",    //
        hideToNonWellness: false,
        dataKey: "session_review_filled_count",
        path: routes.VIEW_SESSION_ENDPOINT,
        permissions: getPermissions("session_module"),
    },
    {
      title: 'Program Feedback',
      image: <FeedbackIcon/>,
      key: "program_feedback",
      hideToNonWellness: false,
      dataKey: "program_feedback",
    },
    {
      title: 'Testimonial',
      image: <TestimonialIcon/>,
      key: "testimonial",
      hideToNonWellness: false,
      dataKey: "testimonial",
    },
    {
        title: 'Post Stay Consultation',
        image: <PSCIcon/>,
        key: "post_stay_consultation_status",
        hideToNonWellness: true,
        dataKey: "post_stay_consultation_datetime",
        path: routes.VIEW_CONSULTATION_NOTES_ENDPOINT,
    },
]


const TableRowStyled = styled(TableRow)`
     
  &:nth-of-type(odd) td {
    border: none;
    padding-bottom: 0;
  },

  &:nth-of-type(4n-1) td {
    border-top: 2.5px solid #DDDDDD;
    background-color: #F5F5F5;
  },

  &:nth-of-type(4n-0) td {
    border-bottom: 2.5px solid #DDDDDD;
    background-color: #F5F5F5;
  },

  &:nth-of-type(4n-1) td:first-of-type, &:nth-of-type(4n-0) td:first-of-type  {
    border-left: 2px solid #DDDDDD;
  },

  &:nth-of-type(4n-1) td:last-child, &:nth-of-type(4n-0) td:last-child  {
    border-right: 2px solid #DDDDDD;
  },
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 550,
    fontFamily: 'Montserrat',
    zIndex: 0,
    color: "#AAAAAA",
    padding: '18px 8px 18px 5px',
    wordBreak: "break-word",
    fontSize: "13px",
    textTransform: 'uppercase',
    borderTop: "1px solid #DDDDDD"
  },
  

  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderLeft: '0.5px solid #74613C',
    padding: '18px 8px 18px 15px',
  },

  [`&.${tableCellClasses.head}:last-child`]: {
    borderRight: '0.5px solid #74613C'
  },
  
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    padding: '22px 8px 22px 9px',
    wordBreak: "break-word",
    fontSize: "14px",
  },

  [`&.${tableCellClasses.body}:first-of-type`]: {
    textTransform: 'capitalize',
    borderLeft: '0.5px solid #74613C',
    padding: '22px 8px 0px 15px',
  },
}));

const TablePaginationStyled = styled(TablePagination)(({ theme }) => ({
  fontSize: '14px',

  [`& .${tablePaginationClasses.select}`]: {
    padding: '4px 8px',
    borderRadius: '8px',
    backgroundColor: '#F5F3EC',
    border: '1px solid #DDDDDD'
  },

  [`& .${tablePaginationClasses.select}, & .${tablePaginationClasses.select}:focus`]: {
    borderRadius: '8px',
    border: '1px solid #DDDDDD'
  },

  [`& .${tablePaginationClasses.selectIcon}`]: {
    top: '12px',
    right: '7px',
    width: '9px'
  },

  [`& .${tablePaginationClasses.selectLabel}, &  .${tablePaginationClasses.displayedRows}`]: {
    margin: '0',
    fontSize: '14px'
  },

  [`& .${tablePaginationClasses.actions} button`]: {
    backgroundColor: '#F5F3EC',
    marginRight: '5px',
    padding: '3px 5px',
    border: '1px solid #DDDDDD'

  },

  [`& .${tablePaginationClasses.actions} svg`]: {
    width: '20px'
  },

}));

const StatusReportTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,
  filter,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const [order, setOrder] = useState(filter.order);
  const [orderBy, setOrderBy] = useState(filter.orderBy);

  const [selected, setSelected] = useState([]);
  const [action, setAction] = useState('');
  const dispatch = useDispatch()


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    
    searchParams.delete("order")
    searchParams.delete("orderBy")

      let newPair= [["order", newOrder], ["orderBy", property]];

      navigate({
        pathname: `${routes.STATUS_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ...newPair])}`,
      }, {replace: true})
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data?.map((item) => ({subscriberId: item.subscriberId, flatId: item.flatId})) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id, flatId) => {
    const newSelected = selected.some(item => item.subscriberId === id && item.flatId === flatId)
      ? selected.filter((selectedItem) => !(selectedItem.subscriberId === id && selectedItem.flatId === flatId))
      : [...selected, {subscriberId: id, flatId}];
    setSelected(newSelected);
  };

  const handleChangePage = (e, newPage) => {
    window.scrollTo(0, 0)
      searchParams.delete("page")
      navigate({
        pathname: `${STATUS_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
      })
  };

  const handleChangeRowsPerPage = (e) => {
    window.scrollTo(0, 0)
    let value=parseInt(e.target.value, 10);
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit")
      searchParams.delete("page")
      navigate({
        pathname: `${STATUS_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      })
    }
  };

  const handleProfileNavigation = (row)=>{
    let guestTypeID = 0;
    if (moment().isSameOrBefore(moment(row?.checkin_datetime))) guestTypeID = 0
    else if (moment().isSameOrAfter(moment(row?.checkout_datetime))) guestTypeID = 2
    else guestTypeID = 1

    const query = {filter_type: guestTypeID, phone_number: "", email: "", room_details:"", user_program_id: row.user_program_id, navigateToDetails: true}
    navigate(routes.SEARCH_RESULT_ENDPOINT, { state: query })
  }

  const handleIconNavigate = (path, row, box, ringNo)=>{
    let guestType = 'upcoming';
    if (moment().isSameOrBefore(moment(row?.check_in))) guestType = 'upcoming'
    else if (moment().isSameOrAfter(moment(row?.check_out))) guestType = 'completed'
    else guestType = 'active'

    let params ={
      guestId: row.user_id, 
      userProgramId: row.user_program_id, 
      user_id: row.user_id,
      is_self_assessment_enabled: row.is_self_assessment_enabled, 
      is_self_assessment_active: row.is_self_assessment_active,
      type: guestType,
      // ...(box.title=== 'Session Review' && {type: sessionType.REVIEW}),
      // ...(box.title=== 'Post Stay Consultation' && ringNo===2  && {type: sessionType.NOTES})
    }

    //query navigation
    if(path===routes.QUERY_ENDPOINT)
      navigate(`${path}/${row.user_id}`)
    //email navigation
    else if(path === routes.SEARCH_RESULT_ENDPOINT)
      handleProfileNavigation(row)
    //session review navigation
    else if(path===routes.VIEW_SESSION_ENDPOINT)
      navigate(`${path}?tab=2`, {state:{...params}})
    //other navigation
    else
      navigate(path, {state:{...params}})
  }


  const isSelected = (id, flatId) => selected.some(item => item.subscriberId === id && item.flatId === flatId);


  useEffect(()=>{      
    setSelected([])
  }, [searchParams])

  useEffect(()=>{
    setOrder(filter.order)
    setOrderBy(filter.orderBy)
  }, [filter])

  return (
    <Box
      sx={{ width: "100%", fontSize: "13px", paddingBottom: '10px' }}
      className="status-report-table-component"
    >
      <TableContainer
        sx={data.length || loading ? {minHeight: "500px"}: {}}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled size="small" sx={{pl:'0 !important', pr: 2, width: 36 }}>
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={data?.length > 0 && selected?.length === data?.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.sortingKey+ 'heading'}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.sortingKey ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    hideSortIcon={true}
                    active={orderBy === column.sortingKey}
                    direction={orderBy === column.sortingKey ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.sortingKey);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                  >
                    {column.label}
                    {orderBy === column.sortingKey ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
                loading ?
                <TableRow >
                  <TableCellStyled sx={{border: 'none'}} colSpan={columns?.length+1}><div className='text-center mb-3'><CircularProgress size={35} sx={{'color': '#74613C'}}/></div></TableCellStyled>
                </TableRow>
                :
                <>
                  {!!data?.length && data?.map((row, rowIndex) => {
                      const isRowSelected = isSelected(row?.subscriberId, row.flatId);

                      return (
                        <React.Fragment 
                          key={`${rowIndex}`}
                        >
                          <TableRowStyled
                            role="checkbox"
                            aria-checked={isRowSelected}
                            tabIndex={-1}
                            selected={isRowSelected}
                          >
                            {!!checkbox && (
                              <TableCellStyled size="small" sx={{pl:'0 !important', zIndex: 0}} onClick={(event) => handleClick(event, row?.subscriberId, row?.flatId)}>
                                <Checkbox
                                  checked={isSelected(row?.subscriberId, row.flatId)}
                                  onChange={(e) =>handleClick(e, row?.subscriberId, row?.flatId)}
                                  size="small"
                                  color="primary"
                                  inputProps={{
                                    "aria-labelledby": `generic-table-checkbox-${row?.sortingKey}`,
                                  }}
                                  sx={{
                                    p: 0,
                                    color: "#AAAAA",
                                    "&.Mui-checked": {
                                      color: "#CF001C",
                                    },
                                  }}

                                />
                              </TableCellStyled>
                            )}
                            {/* Serial Number */}
                            <TableCellStyled className="fw-bold" size="small" align="left" style={{cursor: 'pointer'}} onClick={(event) => handleProfileNavigation(row)}>
                              { row.name}
                            </TableCellStyled>

                            <TableCellStyled size="small" align="left">
                              {row?.program}
                            </TableCellStyled>

                            <TableCellStyled size="small" align="left">
                              {row?.checkin_datetime ? moment(row?.checkin_datetime).format("MMM DD, YYYY") : '-'}
                            </TableCellStyled>

                            <TableCellStyled size="small" align="left">
                              {row?.checkout_datetime ? moment(row?.checkout_datetime).format("MMM DD, YYYY") : '-'}
                            </TableCellStyled>

                            <TableCellStyled size="small" align="left">
                              {row.roomNo}
                            </TableCellStyled>

                            <TableCellStyled size="small" align="left" >
                              {row?.doctor} 
                            </TableCellStyled>
                          </TableRowStyled>
                          <TableRowStyled>
                            <TableCellStyled colSpan={columns.length}>
                              <div className='d-flex justify-content-center justify-content-xl-between flex-wrap task-container mb-4 mx-2 text-break'>

                              {
                                ITEMS.map((box, index)=>{
                                  let restrictNavigation = !box.path || (row[box.key]?.toString()?.length==1 && parseInt(row[box.key]?.toString())==0)
                                  let ringNo = row[box.key]?.toString()?.length>1 ? 2 :  parseInt(row[box.key]?.toString() || 0)
                                    return(
                                      <React.Fragment key={index}>
                                            <div className="icon-box" >
                                                <div 
                                                  className={`icon ${ restrictNavigation ? 'restrict' : ''} ${statusClasses[ringNo]}`}
                                                  onClick={restrictNavigation ? undefined : ()=>{handleIconNavigate(box.path, row, box, ringNo)}} 
                                                >
                                                    {box.image}
                                                </div>
                                                <div className={`title ${box.permissions === "hide" ? "opacity-50": ''}`}>{box.title}</div>
                                                <div className={` sub-title ${box.permissions === "hide" ? "opacity-50": ''} ${validateDate(row[box.dataKey]) ? '': 'fw-bold'}`}>{row[box.dataKey] && row[box.dataKey]?.length>1 ? row[box.dataKey] : '-'}</div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                              }
                            </div>
                            </TableCellStyled>
                          </TableRowStyled>
                        </React.Fragment>
                      );
                    })}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="m-4">
          <TablePaginationStyled
            disabled={loading}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount || 0}
            rowsPerPage={rowsPerPage || 10}
            page={currentPage || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: arrowDownIcon,
            }}
        />
        </div>
      )}
    </Box>
  );
};

StatusReportTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default StatusReportTable;
