import moment from 'moment'
import { AyurvedaDepartureConsultationField } from '../NoteFormsConstants'
  

export const ViewAyurvedaDepartureConsultation = ({content, guestName})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{content.conducted_by || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className=" w-100 rounded my-4">
                <div className="fw-semi-bold">
                    Dear {guestName},
                </div>
                Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Ayurvedic Physician during post-stay online follow-ups.  
            </div>
            <div className='border rounded overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                PROGRAMME & TREATMENT PLAN
                </div>
                <div className='p-3'>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Prakriti:</div>
                        <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Vikruti (to be balanced):</div>
                        <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                    </div>
                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className=' mt-4 ' key={index}>
                                        <div className='fw-semibold text-secondary p-2 text-uppercase'> Objective {(index+1)?.toString()?.padStart(2,0)}: </div>
                                        <table className='p-2 w-100'>
                                            <tbody>
                                            {
                                                AyurvedaDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        
                </div>
            </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                    HEALTH ASSESSMENT
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className="p-2 border col-3">Parameter</div>
                    <div className='p-2 border col-4'>
                        On Arrival
                    </div>
                    <div className='p-2 border col-5'>
                        On Departure
                    </div>
                </div>
                    {
                        AyurvedaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-4'>
                                        {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-5'>
                                        {content?.data?.health_assessment?.[item.key]?.value || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                RECOMMENDATIONS AND FOLLOW UPS
                </div>
                <div className=''>
                    <div className="row m-0 fw-semibold" >
                        <div className="p-2 border col-3">Daily Routine</div>
                        <div className='p-2 border col-4'>
                            Time
                        </div>
                        <div className='p-2 border col-5'>
                            Frequency
                        </div>
                    </div>
                        {
                            AyurvedaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                return (
                                    <div className="row w-100 m-0" key={index}>
                                        <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary">{item.label}</div>
                                        <div className='col-4 border border-bottom-0 p-2 '>
                                            {content?.data?.recommendations_and_follow_ups?.[item.key]?.time || '-'}
                                        </div>
                                        <div className='col-5 border border-bottom-0 p-2 '>
                                            <div className="data-input">
                                                {content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || '-'}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center py-2 fw-semibold">
                OTHER RECOMMENDATIONS
                </div>
                <div className='m-3 mb-5'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Dietary Recommendations</div>
                    {
                        AyurvedaDepartureConsultationField?.otherRecommendations?.dietaryRecommendation?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '260px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content?.data?.other_recommendations?.dietary?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
                <hr />
                <div className='m-3 mb-5'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Physical & Mental Activity</div>
                    {
                        AyurvedaDepartureConsultationField?.otherRecommendations?.physicalAndMentalActivity?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '260px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content?.data?.other_recommendations?.physical_mental?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
                <hr />
                <div className='m-3'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Medicines Recommended</div>
                    <div className="text-muted fw-semibold m-0 text-center row">
                        <div className="col-4 border p-2">Medicine</div>
                        <div className="col-4 border p-2">Dosage</div>
                        <div className="col-4 border p-2">Frequency</div>
                    </div>
                    {
                        content.data?.other_recommendations?.medicine?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className='col-4 border border-top-0 p-2'>
                                        {content?.data?.other_recommendations?.medicine?.[index]?.medicines || '-'}
                                    </div>
                                    <div className='col-4 border border-top-0 p-2'>
                                        {content?.data?.other_recommendations?.medicine?.[index]?.dosage || '-'}
                                    </div>
                                    <div className='col-4 border border-top-0 p-2 align-items-center d-flex'>
                                        <div className="flex-grow-1 me-3">
                                            {content?.data?.other_recommendations?.medicine?.[index]?.frequency || '-'}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border rounded my-3 overflow-hidden">
                <div className="bg-light text-center py-2 fw-semibold">
                FOLLOW UP PROGRAMME
                </div>
                <div className=' '>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{'Within 30 days of departure'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.programme || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.timeline || '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
