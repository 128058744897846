import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditYogaDepartureConsultation.scss'
import { ADD, YogaDepartureConsultationField } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { CircularProgress } from '@mui/material'
import RadioButton from '../../../../../components/RadioButton/RadioButton'



export const AddEditYogaDepartureConsultation = ({content, setContent, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
    const {consultationNotesData, loading} = useSelector(state=> state.consultationNotesReducer)

  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }


    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Yoga Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(notes_detail && formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.data.programme_and_treatment_plan = {
                "dosha_prakriti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_prakriti,
                "dosha_vikruiti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_vikruiti,
                "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
            }

            for (let key in notes_detail.data.yogic_assessment) {
                newData.data.health_assessment[key].arrival_reading = capitalize(notes_detail?.data.yogic_assessment[key] || "")
            }

            for (let key in notes_detail.data.others) {
                newData.data.health_assessment[key].arrival_reading = capitalize(notes_detail?.data.others[key] || "")
            }

            newData.data.health_assessment.breath_inhale_exhale_ratio.arrival_reading = notes_detail?.data?.breathing_pattern?.inhale_exhale_ratio
            return newData;
        })
        }
    },[consultationNotesData])

    return (
      <div className='yoga-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className="d-flex py-4 mb-2 border-top rounded justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Conducted By</div>
                    <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.                  </div>
                <div className="border w-100 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    PROGRAMME & TREATMENT PLAN
                    </div>
                    <div className='p-3'>
                        {
                            content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className=' mb-3 ' key={index}>
                                        <div className='fw-semibold text-secondary p-2 text-uppercase'> Objective {(index+1)?.toString()?.padStart(2,0)}: </div>
                                        <table className='p-2 w-100'>
                                            {
                                                YogaDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    HEALTH ASSESSMENT
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Arrival
                            </div>
                            <div className='col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                YogaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-3" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4 mt-2'>
                                                {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='col-5'>
                                                {
                                                    item.radio ?
                                                    <div>
                                                        <div className='row'>
                                                            <div className="col-4 text-center mt-3">
                                                                <RadioButton
                                                                    name={item.key}
                                                                    label={"Beginner"}
                                                                    value={"Beginner"}
                                                                    checked={content?.data?.health_assessment?.[item.key]?.value==="Beginner"}
                                                                    onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                />
                                                            </div>
                                                            <div className="col-4 text-center mt-3">
                                                                <RadioButton
                                                                    name={item.key}
                                                                    label={"Intermmediate"}
                                                                    value={"Intermediate"}
                                                                    checked={content?.data?.health_assessment?.[item.key]?.value==="Intermediate"}
                                                                    onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                />
                                                            </div>
                                                            <div className="col-4 text-center mt-3">
                                                                <RadioButton
                                                                    name={item.key}
                                                                    label={"Advanced"}
                                                                    value={"Advanced"}
                                                                    checked={content?.data?.health_assessment?.[item.key]?.value==="Advanced"}
                                                                    onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="data-input h-100">
                                                        <TextArea
                                                            row={3}
                                                            fullHeight={true}
                                                            placeholder={"Remarks "}
                                                            onChange={value =>setKey(["health_assessment", item.key, "value"], value)}
                                                            value={content?.data?.health_assessment?.[item.key]?.value || ''}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom mb-3" >
                            <div className="mt-2 col-2">Daily Routine</div>
                            <div className="mt-2 col-4">Details</div>
                            <div className="mt-2 col-2">Time of Practice</div>
                            <div className='col-2'>
                            Duration/Repetition
                            </div>
                            <div className='col-2'>
                                Frequency
                            </div>
                        </div>
                            {
                                YogaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-2" key={index}>
                                            <div className="title mt-2 col-2">{item.label}</div>
                                            <div className='col-4'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "details"], value)}
                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key]?.details || ''}/>
                                                </div>
                                            </div>
                                            <div className='col-2 pt-2'>
                                                <TimeSelector
                                                    width={'100%'}
                                                    ampm={true}
                                                    time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key]?.time) || ''}
                                                    setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, "time"], moment(new Date(value)).format('HH:mm A')) }
                                                    
                                                />
                                            </div>
                                            <div className='col-2'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "duration"], value)}
                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key]?.duration || ''}/>
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "frequency"], value)}
                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className=' '>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{'Within 30 days of departure'}</div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
            {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}