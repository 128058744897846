import * as Yup from "yup";
import "yup-phone-lite";

export const updateProfileValidation = (countryCode)=>{

    return Yup.object({
        email_id: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).required("Please enter email address"),
        first_name: Yup.string().required("Please enter first name."),
        last_name: Yup.string().required("Please enter last name."),
        DOB: Yup.date().required("Please enter age."),
        gender: Yup.string().required("Please enter age."),
        address: Yup.string(),
        mobileNo: Yup.string()
            .phone(countryCode, "Please enter a valid mobile number")
            .required("Mobile number is required"),
    })
} 