import * as type from '../../constants';

const initialState = {
  loading: false,
  updateLoder: null,
  activityList: null,
  activityDetails: null,
  interestredUserList: null,
  error: null
}

export function groupClassesAndActivityReducer(state = initialState, action) {
  switch (action.type) {

    case type.GET_ACTIVITY_LIST_BY_DATE:
    return {
        ...state,
        //loading: true,
    }
    case type.GET_ACTIVITY_LIST_BY_DATE_SUCCESS:
    return {
        ...state,
        loading: false,
        activityList: action.payload,
        error: null
    }
    case type.GET_ACTIVITY_LIST_BY_DATE_FALIURE:
    return {
        ...state,
        activityList: null
    }

    case type.GET_ACTIVITY_DETAILS:
        return {
            ...state,
            loading: true,
        }
    case type.GET_ACTIVITY_DETAILS_SUCCESS:
    return {
        ...state,
        loading: false,
        activityDetails: action.payload,
        error: null
    }
    case type.GET_ACTIVITY_DETAILS_FALIURE:
    return {
        ...state,
        activityDetails: null
    }
    case type.CLEAR_ACTIVITY_DETAILS:
        return {
            ...state,
            activityDetails: null
        }

    case type.GET_INTERESTED_USER_LIST:
        return {
            ...state,
            loading: true,
        }
    case type.GET_INTERESTED_USER_LIST_SUCCESS:
    return {
        ...state,
        loading: false,
        interestredUserList: action.payload,
        error: null
    }
    case type.GET_INTERESTED_USER_LIST_FALIURE:
    return {
        ...state,
        interestredUserList: null
    }
    default:
      return state
  }
}

