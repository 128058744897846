import React, { useEffect, useState } from 'react'
import  './SearchBar.scss'
import searchIcon from '../../assets/images/search.svg'

function SearchBar({onChange=undefined, onclick, onClear, value, width}) {
  const [search, setSearch] = useState(value|| '')

  const handleOnChange = (e)=>{
    setSearch(e.target.value); 
    onChange?.(e.target.value)
  }

  const handleEnterKey= (e)=>{
    if(e.key === "Enter"){
      onclick(search)
    }
  }

  useEffect(()=>{
      setSearch(value || '')
  }, [value])


  return (
    <div className="searchbar" style={{width: width || '260px'}}>
        <input type="text" name="" id="" placeholder='Search Keywords' value={search} onKeyPress={handleEnterKey} onChange={handleOnChange}/>
        <div className="d-flex">
          {
            search &&
            <span onClick={onClear}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </span>
          }
          <img src={searchIcon} width={'20px'} alt="" onClick={(e)=>{onclick(search)}} />
        </div>
    </div>
  )
}

export default SearchBar