import { call, put, takeEvery } from "redux-saga/effects";
import { OTP_REQUEST_END, OTP_REQUEST_START, SET_VERIFICATION_DATA, UPDATE_RESEND_OTP } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";

export const updateResendOTPReq = async({data})=>{
    
    const BODY = {
        "email_id": data.email,
        "phone_no": data.phone,
      }
        
    return axiosInstance.put(apiEndpoints.UPDATE_MOBILE_RESEND_OTP, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                console.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateResendOTP({data}){
    try{
        yield put({ type: OTP_REQUEST_START })
        let result = yield call(updateResendOTPReq, {data})
        if(result.statusCode === 200){
            notify('success', result.message)
            yield put({ type: SET_VERIFICATION_DATA, payload: {
                otpFor: "update",
                phone: data?.phone,
                email: data?.email
            } });   
            yield put({ type: OTP_REQUEST_END });
        }
    }catch(error){
    }
}


function* updateResendOTPSaga(){
    yield takeEvery(UPDATE_RESEND_OTP, updateResendOTP)
}

export default updateResendOTPSaga;