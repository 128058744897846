import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RangeDatePicker.scss";
import { ReactComponent as CalenderIcon } from "../../assets/images/calendar.svg";
import moment from "moment";

const RangeDatePicker = ({
  className,
  onStartDateChange,
  onEndDateChange,
  defaultStartValue,
  defaultEndValue,
  startMaxValue,
  startMinValue,
  endMaxValue,
  endMinValue,
  onClear,
  readOnly = false,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    startDateRef?.current?.setState((prev) => ({ ...prev, open: false }));
    if (onStartDateChange) {
      onStartDateChange(date.toISOString());
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    startDateRef?.current?.setState((prev) => ({ ...prev, open: false }));
    if (onEndDateChange) {
      onEndDateChange(date.toISOString());
    }
  };

  useEffect(() => {
    if (defaultStartValue) {
      setStartDate(defaultStartValue);
    }
  }, [defaultStartValue]);

  return (
    <div className="range-date-picker">
      <div
        className={`${className} range-date-picker-container d-flex justify-content-between align-items-center`}
      >
        <div
          className="position-relative cursor-text w-auto"
          onClick={() => {
            startDateRef?.current?.setOpen(true);
          }}
        >
          <div className="position-absolute py-1">
            {defaultStartValue ? (
              <span className="text-dark">
                {moment(new Date(defaultStartValue)).format("DD/MM/YYYY")}
              </span>
            ) : (
              <span className=" fst-italic">
                {"From"}
              </span>
            )}
          </div>
          <DatePicker
          maxDate={startMaxValue}
            className="outline-none text-xs border-none bg-[#F9FAFC] "
            placeholderText={"From"}
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="dd/MM/yyyy"
            startDate={startDate}
            readOnly={readOnly}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            ref={startDateRef}
            scrollableYearDropdown
            showYearDropdown
            dateFormatCalendar="MMMM"
            // yearDropdownItemNumber={15}
            showMonthDropdown
          />
        </div>
        <span className="px-1">-</span>
        <div
          className="relative  cursor-text pe-1.5"
          onClick={() => {
            endDateRef?.current?.setOpen(true);
          }}
        >
          <div className="position-absolute text-xs  py-1 bg-[#F9FAFC]">
            {defaultEndValue ? (
              <span className="text-dark">
                {moment(new Date(defaultEndValue)).format("DD/MM/YYYY")}
              </span>
            ) : (
              <span className=" text-[#AAAAAA] fst-italic">
                {"To"}
              </span>
            )}
          </div>
          <DatePicker
          maxDate={endMaxValue}
            className="outline-none text-xs border-none bg-[#F9FAFC] "
            placeholderText={"To"}
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(moment(startDate).add(1, "days"))}
            readOnly={readOnly}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            ref={endDateRef}
            scrollableYearDropdown
            showYearDropdown
            showMonthDropdown
            dateFormatCalendar="MMMM"
            disabled={!defaultStartValue}
            // yearDropdownItemNumber={15}
          />
        </div>
          <span className="cross" onClick={onClear}>
            {
              defaultStartValue
              &&
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            }
          </span>
          <span className="cal-icon">
            <CalenderIcon />
          </span>
      </div>
    </div>
  );
};

export default RangeDatePicker;
