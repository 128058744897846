import React, { memo } from 'react'
import {ReactComponent as AnandaLogo} from '../../../assets/images/ananda-logo-white.svg'
import {ReactComponent as AnandaLogoBrown} from '../../../assets/images/ananda-spa-logo-vector.svg'
import bellIcon from '../../../assets/images/bell.svg'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import './Header.scss'
import { NavLink } from 'react-router-dom'
import { sidebarData } from '../../../data/navbarData'
import {ReactComponent as CrossIcon} from '../../../assets/images/cross.svg'
import {ReactComponent as HamburgerIcon} from '../../../assets/images/Hamburger.svg'
import getPermissions from '../../../utils/helpers/getPermissions'

function Header({toggleBtn, hamburgerRef, state, navRef}) {
  return (
    <div id='header'>
        <div className='container d-flex justify-content-between align-items-center h-100'>
            <div className='d-flex align-items-center justify-content-between flex-grow-1  h-100'>
                <div className='d-flex align-items-center'>
                    <span className='d-inline d-lg-none me-2' ref={hamburgerRef} onClick={toggleBtn}><HamburgerIcon/></span>
                    <div className='logo'>
                        {<AnandaLogo />}
                    </div>
                </div>
                <div className="navbar d-flex " ref={navRef} style={{left: window.innerWidth >=992 ? "0px" : (state ? "0px": "-250px")}}>
                    <div className='text-end px-4 d-block d-lg-none'>
                        <CrossIcon onClick={toggleBtn}/>
                    </div>
                    <div className='logo p-3 d-inline-block d-lg-none p-lg-0'>
                        {<AnandaLogoBrown />}
                    </div>
                    {
                        sidebarData.map((item, index)=>{
                        return(
                            (!item.permissionKey || getPermissions(item.permissionKey) !== "hide") ?
                            <NavLink end to={item.path} className={({ isActive }) => isActive ? "link-box active" : "link-box"} onClick={toggleBtn} key={index}>
                            <div className="aside-link"> 
                                <span className="aside-icon">{item.icon}</span> 
                                <span>{item.title}</span>
                            </div>
                            </NavLink>
                            :
                            <></>
                        )
                        })
                    }

                </div>
            </div>
            <div className="d-flex">
                <div className="vhr"></div>
                <ProfileDropdown/>
            </div>
        </div>
    </div>
  )
}

export default memo(Header)