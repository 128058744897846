import { ADD_SESSION_NOTES, DELETE_SESSION_NOTES, GET_DAYWISE_USER_SCHEDULE, VIEW_SESSION_NOTES } from '../../constants';


export function getDayWiseUserSchedule(data) {
    return {
        type: GET_DAYWISE_USER_SCHEDULE,
        data
    };
}

export function viewSessionNotes(data) {
    return {
        type: VIEW_SESSION_NOTES,
        data
    };
}

export function deleteSessionNote(data) {
    return {
        type: DELETE_SESSION_NOTES,
        data
    };
}

export function addSessionNote(data) {
    return {
        type: ADD_SESSION_NOTES,
        data
    };
}