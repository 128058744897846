import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import * as types from "../../constants";

export const getPreparatoryDietReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.VIEW_PREPARATORY_DIET_API}?user_id=${data.guestId}&user_program_id=${data.userProgramId}&view_default_diet=${data.defaultDietFlag}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getPreparatoryDiet({data}){
    try{
        let result = yield call(getPreparatoryDietReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_PREPARATORY_DIET_DATA_SUCCESS, payload: result.data});  
        }  
    }catch(error){
        yield put({ type: types.GET_PREPARATORY_DIET_DATA_FALIURE, message: error.message });
    }
}



export const deletePreparatoryDietReq = async({data})=>{
    const BODY = {
        "user_program_id": data.userProgramId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_PREPARATORY_DIET_API, { data: {data: BODY} })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* deletePreparatoryDiet({data}){
    try{
        let result = yield call(deletePreparatoryDietReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.DEL_PREPARATORY_DIET_DATA_SUCCESS});
            yield put({ type: types.UPDATE_GUEST_LIST, payload: {type: data.type, user_program_id: data.userProgramId, value: 1, keyName: "is_preparatory_diet_completed"}});
            notify("success", result.message)
        }
    }catch(error){
    }
}

export const updatePreparatoryDietVisibleStatusReq = async({data})=>{
    const BODY = {
            "user_program_id": data.userProgramId,
            "visible_to_guest":  data.visibleStatus ? 1 : 0
    }
    console.log(BODY)
    return axiosInstance.put(apiEndpoints.UPDATE_VISIBLE_STATUS_PREPARATORY_DIET_API, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updatePreparatoryDietVisibleStatus({data}){
    try{
        yield call(updatePreparatoryDietVisibleStatusReq, {data})
    }catch(error){
    }
}


export const updatePreparatoryDietReq = async({data})=>{
    const BODY = {
        "user_program_id": data.userProgramId,
        "preparatory_diet": data.content
      }

    return axiosInstance.put(apiEndpoints.UPDATE_PREPARATORY_DIET_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updatePreparatoryDiet({data}){
    try{
        let result = yield call(updatePreparatoryDietReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.UPDATE_PREPARATORY_DIET_DATA_SUCCESS }); 
            window.history.back();
            notify('success', result.message);
            yield put({ type: types.UPDATE_GUEST_LIST, payload: {type: data.type, user_program_id: data.userProgramId, value: 2, keyName: "is_preparatory_diet_completed"}});
        }    
    }catch(error){
        yield put({ type: types.UPDATE_PREPARATORY_DIET_DATA_FALIURE }); 
    }
}

function* preparatoryDietSaga(){
    yield takeLatest(types.GET_PREPARATORY_DIET_DATA, getPreparatoryDiet);
    yield takeLatest(types.DEL_PREPARATORY_DIET_DATA, deletePreparatoryDiet);
    yield takeLatest(types.UPDATE_PREPARATORY_DIET_VISIBLE_STATUS, updatePreparatoryDietVisibleStatus);
    yield takeLatest(types.UPDATE_PREPARATORY_DIET_DATA, updatePreparatoryDiet);
}

export default preparatoryDietSaga;