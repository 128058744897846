import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import megaphoneIcon from '../../assets/images/megaphone.svg'
import './WarningModal.scss'
import { CircularProgress } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function WarningModal({warnModal, setWarnModal, onClose, buttonText="Close", text, loading}) {
  const handleClose = () => setWarnModal(false);

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={warnModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="warning-modal">
            <div className="icon">
              <img src={megaphoneIcon} alt="" />
            </div>
            <div className="my-4">
              <div className="title">Confirm</div>
                {text}
              </div>
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <div className="button" onClick={()=>{setWarnModal(false)}}>Cancel</div>
                <div className="vhr"></div>
                <div className="button close" onClick={onClose}>
                  {
                    loading ?
                    <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                    :
                    buttonText
                  }
                </div>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}