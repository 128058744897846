import { GET_QUERY_DETAILS, GET_QUERY_LIST, GET_QUERY_MESSAGE, SEND_MESSAGE_IN_QUERY, UPDATE_QUERY_STATUS } from "../../constants";


export function getQueryList(data) {
    return {
        type: GET_QUERY_LIST,
        data
    };
}

export function getQueryMessage(data) {
    return {
        type: GET_QUERY_MESSAGE,
        data
    };
}


export function sendMessageInQuery(data) {
    return {
        type: SEND_MESSAGE_IN_QUERY,
        data
    };
}


export function getQueryDetails(data) {
    return {
        type: GET_QUERY_DETAILS,
        data
    };
}


export function updateQueryStatus(data) {
    return {
        type: UPDATE_QUERY_STATUS,
        data
    };
}
