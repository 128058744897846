import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import megaphoneIcon from '../../assets/images/megaphone.svg'
import './ChangeVisibilityModal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  pb: 2
};

export default function ChangeVisibilityModal({warnModal, setWarnModal, onYes, text, hide}) {
  const handleClose = () => setWarnModal(false);

  return (
    <div>
      <Modal
       sx={{".MuiBox-root": {
                border: "none", borderRadius: "10px", outline: "none"
            }
        }}
        open={warnModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="changevisibility-modal">
            <div className="icon">
              <img src={megaphoneIcon} alt="" />
            </div>
            <div className="my-4">
              <div className="title">Change Visibility</div>
                {`Are you sure, you want this to be ${hide ? 'hidden from' : 'visible to'} guest?`}
              </div>
              <div className="hr"></div>
              <div className="d-flex justify-content-between">
                <div className="button text-success" onClick={onYes}>Yes</div>
                <div className="vhr"></div>
                <div className="button text-danger" onClick={()=>setWarnModal(false)}>No</div>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}