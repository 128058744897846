import React, { memo, useEffect, useState } from 'react'
import defaultUser from '../../assets/images/defaultUser.png'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { app } from '../../firebase/firebaseConfig'

function ImageComponent({src, className, fetch}) {
  const [imageUrl, setImageUrl] = useState(null);


  useEffect(()=>{
    if(fetch){
      if(src && src.length){
        try{
          const storage = getStorage(app);
          const imageRef = ref(storage, src);
    
          getDownloadURL(imageRef).then((url) => {
              setImageUrl(url);
          }).catch((error) => {
            console.error("Error getting download URL: ", error);
          });
        }catch(e){
          console.log(e)
        }
      }
    }else{
      if(src){
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImageUrl(reader.result);
        });
        reader.readAsDataURL(src);
      }
    }
  },[src])


  return (
    <div className={className}>
        <img src={src ? imageUrl : defaultUser} alt="" width={"100%"} height={"100%"}/>
    </div>
  )
}

export default memo(ImageComponent)