import * as type from '../../constants';

const initialState = {
  tentativeGuest: {
    assigned_to: 0,
    guest_list: []
  },
  upcomingGuest: {
    assigned_to: 0,
    guest_list: []
  },
  activeGuest: {
    assigned_to: 0,
    guest_list: []
  },
  completedGuest: {
    assigned_to: 0,
    guest_list: []
  },
  loading: false,
  error: null
}

export function guestListReducer(state = initialState, action) {
  switch (action.type) {
    case type.GUEST_LIST_LOADING:
      return {
        ...state,
        loading: true,
      }
      case type.SET_TENTATIVE_GUEST:
        return {
          ...state,
          tentativeGuest: action.data?.filterChange ? {assigned_to: action.data.assignedTo, ...action.payload} : {
            ...state.tentativeGuest,
            total_pages: action.payload.total_pages,
            current_page_no: action.payload.current_page_no,
            guest_list: [...(state.tentativeGuest?.guest_list?.length ? state.tentativeGuest?.guest_list: []), ...action.payload?.guest_list]
          },
          loading: false,
        }
    case type.SET_UPCOMING_GUEST:
      return {
        ...state,
        upcomingGuest: action.data?.filterChange ? {assigned_to: action.data.assignedTo, ...action.payload} : {
          ...state.upcomingGuest,
          total_pages: action.payload.total_pages,
          current_page_no: action.payload.current_page_no,
          guest_list: [...(state.upcomingGuest?.guest_list?.length ? state.upcomingGuest?.guest_list: []), ...action.payload?.guest_list]
        },
        loading: false,
      }

    case type.SET_ACTIVE_GUEST:
      return {
        ...state,
        activeGuest: action.data?.filterChange ? {assigned_to: action.data.assignedTo, ...action.payload} : {
          ...state.activeGuest,
          total_pages: action.payload.total_pages,
          current_page_no: action.payload.current_page_no,
          guest_list: [...(state.activeGuest?.guest_list?.length ? state.activeGuest?.guest_list: []), ...action.payload?.guest_list]
        },
        loading: false,
      }

    case type.SET_COMPLETED_GUEST:

        return {
          ...state,
          completedGuest: action.data?.filterChange ? {assigned_to: action.data.assignedTo, ...action.payload} : {
            ...state.completedGuest,
            total_pages: action.payload.total_pages,
            current_page_no: action.payload.current_page_no,
            guest_list: [...(state.completedGuest?.guest_list?.length ? state.completedGuest?.guest_list: []), ...action.payload?.guest_list]
          },
          loading: false,
        }
    case type.GUEST_LIST_FALIURE:
      let {filter_type} = action.data
      if(filter_type===-1){
        return {
          ...state,
          loading: false,
          tentativeGuest: {
            assigned_to: action.data.assignedTo,
            guest_list: []
          },
        }
      }else if(filter_type===0){
        return {
          ...state,
          loading: false,
          upcomingGuest: {
            assigned_to: action.data.assignedTo,
            guest_list: []
          },
        }
      }else if(filter_type===1){
        return {
          ...state,
          loading: false,
          activeGuest: {
            assigned_to: action.data.assignedTo,
            guest_list: []
          },
        }
      }else{
        return {
          ...state,
          loading: false,
          completedGuest: {
            assigned_to: action.data.assignedTo,
            guest_list: []
          },
        }
      }

    case type.UPDATE_GUEST_LIST:
      let guestType = action.payload?.type;
      let {user_program_id, value, keyName} = action.payload;

      if (guestType === 'upcoming') {
        let data = state.upcomingGuest?.guest_list
        let index = data?.findIndex((item) => item?.user_program_id === user_program_id)
        if (index > -1)
          data[index][keyName] = value

        return {
          ...state,
          upcomingGuest: {...state.upcomingGuest, guest_list: data}
        }
      } else if (guestType === 'active') {
        let data = state.activeGuest?.guest_list
        let index = data?.findIndex((item) => item?.user_program_id === user_program_id)
        if (index > -1)
          data[index][keyName] = value
        
          return {
          ...state,
          activeGuest: {...state.activeGuest, guest_list: data}
        }
      } else if (guestType === 'completed') {
        let data = state.completedGuest?.guest_list
        let index = data?.findIndex((item) => item?.user_program_id === user_program_id)
        if (index > -1)
          data[index][keyName] = value
        return {
          ...state,
          completedGuest: {...state.activeGuest, guest_list: data}
        }
      }

      return state;

    default:
      return state
  }
}

export function guestNameListReducer(state = {
  guestList: [],
  loading: false,
  error: null
}, action) {
  switch (action.type) {
    case type.GUEST_NAME_LIST:
      return {
        ...state,
        loading: true,
        guestList: [],
        error: null
      }
    case type.GUEST_NAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        guestList: action.payload,
        error: null
      }

    case type.GUEST_NAME_LIST_FALIURE:
        return {
          ...state,
          loading: false,
          guestList: [],
          error: action.payload
        }

    default:
      return state
  }
}

export function searchedGuestResult(state = {
  guestList: [],
  loading: false,
  error: null
}, action) {
  switch (action.type) {
    case type.SEARCH_GUEST_LIST_RESULT:
      return {
        ...state,
        loading: true,
        error: null
      }
    case type.SEARCH_GUEST_LIST_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        guestList: action.payload,
        error: null
      }

    case type.SEARCH_GUEST_LIST_RESULT_FALIURE:
        return {
          ...state,
          loading: false,
          guestList: [],
          error: action.payload
        }
    case type.CLEAR_SEARCH_GUEST_LIST_RESULT:
      return {
        ...state,
        guestList: [],
      }

    default:
      return state
  }
}