import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import backArrow from '../../../assets/images/back-arrow.svg'
import './Chat.scss'
import { getQueryDetails, getQueryMessage, sendMessageInQuery, updateQueryStatus } from '../../../redux/actions/QueryActions/QueryAction';
import moment from 'moment';
import { SEARCH_RESULT_ENDPOINT } from '../../../routes/route';
import ImageComponent from '../../../components/ImageComponent/ImageComponent';
import WarningModal from '../../../components/WarningModal/WarningModal';
import getPermissions from '../../../utils/helpers/getPermissions';


  const INFO_MESSAGES = {
    closed: [
        "You have closed this query.",
        "This query has been closed by wellness."
    ],
    cancelled: [
        "This query has been cancelled by guest.",
        "You have cancelled this query.",
    ],
    resolved: [
        "This query has been resolved by guest.",
        "You have resolved this query.",
    ],
    "Auto closed by system": [
        "The Query has been closed automatically since the guest hasn’t responded in the last 24 hours.",
        "The Query has been closed automatically since you haven’t responded in the last 24 hours.",
    ],
}

function Chat() {
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch();
  const {loading, updateLoader, messages, queryDetails} = useSelector(state => state.queryListReducer)
  const [modal, setModal] =useState(false)
  const [message, setMessage] =useState('')
  const { data} = location.state;
  const { chat_id, user_program_id } = data;
  let userId = JSON.parse(localStorage?.getItem('UserData'))?.user_id

  const checkDetails = () => {
    let guestTypeID = 0;
    if (moment().isSameOrBefore(moment(queryDetails?.check_in))) guestTypeID = 0
    else if (moment().isSameOrAfter(moment(queryDetails?.check_out))) guestTypeID = 2
    else guestTypeID = 1

    const query = {filter_type: guestTypeID, phone_number: "", email: "", room_details:"", user_program_id: user_program_id, navigateToDetails: true}
    
    navigate(SEARCH_RESULT_ENDPOINT, { state: query })
  }

const sendMsg = (msg) => {
  if(message){
    const payload = {
        chatId: chat_id,
        messageType: "text",
        message,
        datetime: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        onSuccessCallback: ()=>{
          setMessage('')
        }
    }
    dispatch(sendMessageInQuery(payload))
  }
}



  const getMessages = () => {
    dispatch(getQueryMessage({ chatId: chat_id }))
  }

  const getDetails = () => {
    dispatch(getQueryDetails({ chatId: chat_id }))
  }

  const closeTickit = (msg, isCancel) => {
    const payload = {
        chatId: chat_id,
        messageType: "text",
        status: isCancel ? "cancelled" : 'closed',
        message,
        datetime: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        onSuccessCallback: ()=>{
          setMessage("");
          setModal(false)
        }
    }

    dispatch(updateQueryStatus(payload))
}
  useEffect(() => {
    getMessages();
    getDetails();
    let id = setInterval(() => {
        getMessages();
    }, 10000)
    let idx = setInterval(() => {
        getDetails();
    }, 60000)
    return () => {
        clearInterval(id)
        clearInterval(idx)
    }
  }, []);

  return (
    <div className="chat-container">
      <div className="container h-100">
          <div className="chat-heading">
            <img className='back-arrow' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
            &nbsp;
              {data?.subject}
          </div>

          {   loading && (chat_id !== queryDetails?.chat_id) ?
              <div className="loader">
                  <CircularProgress sx={{'color': '#74613C'}}/>
              </div>

              :

              <div className="py-2 pb-5">
                  <div className="chat-content">
                    <div className="chat-guest-details p-3 pb-0 mb-3">
                      <div className="program">
                        {queryDetails?.program_title}
                      </div>
                      <div className='d-flex align-items-end justify-content-between guest-detail-data'>
                        <div>
                          <div className="data-row text-capitalize">
                              <div className="title">Guest Name: </div>
                              <div className="value">{queryDetails?.guest_name}</div>
                          </div>
                          <div className="data-row">
                              <div className="title">Check-In: </div>
                              <div className="value">{moment(queryDetails?.check_in).format('MMM DD, dddd') || 'N/A'}</div>
                          </div>
                          <div className="data-row">
                              <div className="title">Check-Out: </div>
                              <div className="value">{moment(queryDetails?.check_out).format('MMM DD, dddd') || 'N/A'}</div>
                          </div>
                        </div>
                        <div className='flex-shrink-0 details-link text-primary' onClick={checkDetails}>Check Details</div>
                      </div>
                    </div>
                    <div className="chat-box">
                      {
                        messages &&
                        <>
                        <div className="message-box">
                          {
                            messages?.sort((a, b) => a.message_id - b.message_id)?.map((item, index)=>{
                              const isCurrentUser = item?.sender_user_id === userId
                              return (
                                <Message 
                                isCurrentUser={isCurrentUser} 
                                item={item} 
                                key={index} 
                                queryDetails={queryDetails} 
                                index={index}
                                chatLength={messages?.length} />
                            )})
                          }
                        </div>
                        {
                          !!queryDetails?.is_active && getPermissions('wellbeing_queries')==='full_write' && 
                          <div>
                            <div className='form-input-container w-100 p-3 mb-0' >
                              <div className='d-flex align-items-center inputbox'>
                                <textarea placeholder='Write Here ...' onChange={(e)=>setMessage(e.target.value)}  />
                              </div>
                              <div className="button-group d-flex justify-content-between py-2">
                                <button className='cancel' type='button' onClick={()=>setModal(true)}>Close</button>
                                <button className='update' type='submit' disabled={!message || updateLoader} onClick={sendMsg} >Respond</button>
                              </div>
                            </div>
                          </div>
                        }
                        <WarningModal 
                          text={`Are you sure, you want to close this query?`}
                          warnModal={modal} 
                          setWarnModal={setModal} 
                          onClose={()=>{closeTickit({isCancel: true})}}
                         />
                        </>
                      }
                    </div>
                  </div>
              </div>
          }                
      </div>
  </div>
  )
}

export default Chat

const MessageBox = ({isCurrentUser, item, message})=>{

  return (
    <div className={`d-flex flex-grow-1 px-3 mb-3 ${!isCurrentUser ? 'justify-content-start' : 'justify-content-end'}`}>
      <div className='mb-1 w-50 d-flex flex-column'>
        <div className={`d-flex align-items-center ${!isCurrentUser ? '' : 'flex-row-reverse'}`}>
          <div className='d-flex align-items-center me-2'>
            <ImageComponent className={'user-profile overflow-hidden'} fetch={true} src={item?.profile_pic} /> 
            <div className='ms-2' style={{fontWeight: 500}}>
              {
                isCurrentUser ?
                'You'
                :
                `${item?.first_name} ${item?.last_name ? item?.last_name : ''}`
              }
              </div> 
          </div> 
          |
          <div className="mx-2 text-muted time">{moment(item.creation_datetime).format('H:mm A, MMM DD')}</div>
        </div>
        <div className={`message my-2 p-2 ${isCurrentUser ? 'align-self-end wellness-user' : 'align-self-start guest-user'}`}>
          {
            item?.message_type === 'json' ?
              <div className="heading"> <span className='program-name'>{message?.department_name}</span> | {message?.subject} </div>
            : null
          }
          <div>{message?.description}</div>
        </div>
      </div>
    </div>  
  )
  
}


const Message = ({isCurrentUser, item, queryDetails, index, chatLength})=>{
  const is_active = queryDetails?.is_active
  let message= '';
  if (item?.message_type === 'json') {
      message = JSON.parse(item?.message_body);
  } else {
      message = { description: item?.message_body }
  }
  return (
      <>
        {
          !is_active && INFO_MESSAGES[message?.description] && index === chatLength-1 ?
          <>
            {
              !!item.sender_user_id &&
              <MessageBox 
                item={item}
                isCurrentUser={isCurrentUser}
                message={message}
                />  
            }
            <div className='close-flag'>{INFO_MESSAGES[message?.description]?.[0]}</div>
          </>          
          :
            !is_active && index === chatLength-1 ?
            <>
              {
                !!item.sender_user_id &&
                <MessageBox 
                item={item}
                isCurrentUser={isCurrentUser}
                message={message}
                />
              }
              <div className='close-flag'>{INFO_MESSAGES?.resolved?.[0]}</div>
            </>
            :
            <MessageBox 
              item={item}
              isCurrentUser={isCurrentUser}
              message={message}
              />
        }
      </>
  )
}