import * as Yup from "yup";
import "yup-phone-lite";

export const emailValidation = ()=>{
    return Yup.object({
        email: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).required("Please enter email address"),
    })
} 

export const mobileValidation = (countryCode)=>{
    return Yup.object({
        mobileNo: Yup.string()
            .phone(countryCode, "Please enter a valid mobile number")
            .required("Mobile number is required"),

    })
} 

export const updateMobileValidation = (countryCode)=>{
    return Yup.object({
        email: Yup.string().email("*Please enter valid email address").required("Please enter email address"),
        mobileNo: Yup.string()
            .phone(countryCode, "Please enter a valid mobile number")
            .required("Mobile number is required"),
    })
} 