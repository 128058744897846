import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import './ReopenForGuest.scss'
import moment from 'moment';
import checkedBtn from '../../../assets/images/checked.svg'
import uncheckedBtn from '../../../assets/images/unchecked.svg'
import { useDispatch, useSelector } from 'react-redux';
import {getHealthAssessmentSections, reopenAssessmentForGuest} from '../../../redux/actions/GuestDataActions/WellbeingReportActions'

function DatePicker({dob, setDob, testID}){
    return <div className='date-input'>
            <div className="input">
                <input type="date" name="" id="" data-testid={testID}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    max={moment(new Date()).add(10, 'year').format('YYYY-MM-DD')}
                    value={dob}
                    onChange={(e)=>{
                        setDob(e.target.value)
                    }}/>
            </div>
        </div>
}

function ReopenForGuest() {
    const location = useLocation();
    const {guest_id, user_program_id} = location.state;
    const [dob, setDob] = useState('');
    const [openSections, setOpenSections] = useState('all');
    const [selectedSections, setSelectedSections] = useState([]);
    const sections = useSelector(state => state.wellbeingReportReducer.sections)
    const navigate = useNavigate()

    const handleReopenAssessment = ()=>{
        let data = {
            "user_program_id": user_program_id,
            "guest_user_id": guest_id,
            "flag": openSections,
            "fill_assessment_bydate": dob +" 00:00:00",
            "reopened_sections_ids": openSections==="all" ? [] : [... new Set(selectedSections.map((item)=>{return item.section_id}).sort(function(a, b){return a-b}))],
            "reopened_sections_positions": openSections==="all" ? [] : [...new Set(selectedSections.map((item)=>{return item.position}).sort(function(a, b){return a-b}))]
            }
            dispatch(reopenAssessmentForGuest(data))
    }

    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getHealthAssessmentSections())
    },[])

    useEffect(()=>{},[selectedSections])
    
  return (
    <>
            <div className="reopen-for-guest-container">
                <div className="container h-100">
                    <div className="reopen-for-guest-heading">
                        Reopen Assessment
                    </div>
                    <div className="pt-4 pb-5">
                        <div className="reopen-for-guest-content p-4">
                            <div className="data-row">
                                <div className="title">Wellness Reassessment Date</div>
                                <DatePicker dob={dob} setDob={setDob} testID={"select_date"}/>
                            </div>

                            <div className="data-row">
                                <div className="title">Open Sections</div>
                                <div className="value d-flex">
                                    <div className='d-flex mb-1 me-5' onClick={()=>{setOpenSections('all')}}>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2' 
                                                checked={openSections === 'all' ? true : false}
                                                data-testid="all_sections"
                                                value={'all'}
                                                onChange={()=>{setOpenSections('all')}}
                                            />
                                        </div>
                                        <label htmlFor="" className={openSections === 'all' ? "fw-600" : "fw-400"}>All</label>
                                    </div>
                                    <div className='d-flex mb-1 me-5' onClick={()=>{setOpenSections('specific')}}>
                                        <div>
                                            <input type="radio" name="" id="" 
                                                className='me-2'
                                                checked={openSections === 'specific' ? true : false}
                                                data-testid="specific_sections"
                                                value='specific'
                                                onChange={()=>{setOpenSections('specific')}}
                                            />
                                        </div>
                                        <label htmlFor="" className={openSections === 'specific' ? "fw-600" : "fw-400"}>Specific</label>
                                    </div>
                                </div>
                            </div>

                            {
                                openSections === "specific"
                                &&
                                <div className="data-row">
                                    <div className="title">Select Sections</div>
                                    <div className="value d-flex flex-wrap">
                                        {
                                            sections && sections?.map((item, index)=>{
                                                return (
                                                    <div className="checkbox-option d-flex mb-2" key={index}>
                                                        <span data-testid={`section-checkbox-${index+1}`} className='me-2 check-box' onClick={() => {selectedSections.includes(item) ? setSelectedSections([...selectedSections?.filter(e => e.section_name !=item.section_name)]): setSelectedSections([...selectedSections, item])}}>
                                                            {selectedSections.includes(item) ? <img src={checkedBtn}/>: <img src={uncheckedBtn}/> }
                                                        </span>
                                                        <label htmlFor="" className={selectedSections.includes(item) ? "fw-600" : "fw-400"}>{item.section_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <div className="text-end">
                                <button className='reopen-btn' onClick={handleReopenAssessment} disabled={!(dob.length && ((openSections==="specific" && selectedSections.length !==0) || openSections==="all"))}> REOPEN FOR GUEST </button>
                            </div>
                        </div>
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default ReopenForGuest