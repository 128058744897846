// export const noteTypes = [
//     { label: 'Dietary Notes', value: 'Dietary Notes' },
//     { label: 'Ayurveda Notes', value: 'Ayurveda Notes' },
//     { label: 'Yoga Notes', value: 'Yoga Notes' },
//     { label: 'Physio Notes', value: 'Physio Notes' },
//     { label: 'Fitness Notes', value: 'Fitness Notes' },
//     { label: 'Consultation Notes', value: 'Consultation Notes' },
//     { label: 'Reception Notes', value: 'Reception Notes' },
//     { label: 'Doctors Notes', value: 'Doctors Notes' },
// ]

export const durationOptions = [
    { value: '15', label: '15 Minutes' },
    { value: '30', label: '30 Minutes' },
    { value: '45', label: '45 Minutes' },
    { value: '60', label: '60 Minutes' },
    { value: '90', label: '90 Minutes' },
    { value: '120', label: '120 Minutes' },
    { value: '150', label: '150 Minutes' },
    { value: '180', label: '180 Minutes' },
    { value: '210', label: '210 Minutes' },
    { value: '240', label: '240 Minutes' },
    { value: '270', label: '270 Minutes' },
    { value: '300', label: '300 Minutes' },
];

export const intensityOptions = [
    { label: 'NA', value: null },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },

]

export const periodOptions = [
    { label: '3 months to 2 years ago', value: '3 months to 2 years ago' },
    { label: '3 - 5 Years Ago', value: '3 - 5 Years Ago' },
    { label: '5 year Ago', value: '5 year Ago' },
]

export const guestStatusOptions =[
    {label: "All", value: '3'},
    {label: "Upcoming", value: '0'},
    {label: "Active", value: '1'},
    {label: "Completed", value: '2'},
]

export const statusOptions =[
    {label: "All", value: ''},
    {label: "Pending", value: 'pending'},
    {label: "Complete", value: 'completed'},
]


export const activityTypeOptions =[
    {label: "All", value: ''},
    {label: "Wellness Programme", value: 'w'},
    {label: "Non Wellness Programme", value: 'nw'},
]