import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import './QueryTab.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getQueryList } from '../../../redux/actions/QueryActions/QueryAction'
import QueryBox from '../QueryBox/QueryBox'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CHAT_ENDPOINT } from '../../../routes/route'

function QueryTab({index, type}) {
    const {loading} = useSelector(state => state.queryListReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    let guestId=params?.guestId ? parseInt(params?.guestId) : null    
    const queryData = useSelector(state=> {
      return index === 0
        ? state.queryListReducer.activeQuery
            : state.queryListReducer.closedQuery
    })

    const fetchList = ()=>{
      if(queryData.guestId != guestId){
        dispatch(getQueryList({flag: + !index, assignedTo: guestId ? 1 : 0, page: 1, guestId}))
      }else if(!queryData?.total_pages || parseInt(queryData?.current_page_no) < queryData?.total_pages){
        dispatch(getQueryList({flag: + !index, assignedTo: guestId ? 1 : queryData?.assigned_to, page: parseInt(queryData?.current_page_no) ? parseInt(queryData?.current_page_no)+1 : 1, guestId}))
      }
    }

    useEffect(()=>{
      fetchList()
    },[])


  return (
    <div className='query-list'>
      <div>

        {        
          loading &&
          <div className='text-center mb-3'><CircularProgress size={27} sx={{'color': '#74613C'}}/></div>
        }
        {
          !!queryData?.chats?.length ? 
          <InfiniteScroll
            dataLength={queryData?.chats?.length} //This is important field to render the next data
            next={fetchList}
            hasMore={!queryData?.total_pages || parseInt(queryData?.current_page_no)< queryData?.total_pages}
            loader={<><div className='text-center'><CircularProgress sx={{'color': '#5ebd78'}}/></div></>}
            endMessage={
              <></>
            }
            scrollThreshold={0.6}
          >
            {
              (queryData)?.chats?.map((item, index)=>{
                  return (
                      <div onClick={()=> navigate(CHAT_ENDPOINT, {state: {data: item}})}>
                        <QueryBox item={item} key={index} type={type} />
                      </div>
                  )
              })
          }
          </InfiniteScroll>
          :
          <div>{!loading && <div className='text-center fw-normal'>No {type} query</div>}</div>
        }
        
      </div>
    </div>
  )
}

export default QueryTab