import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import { GET_STATUS_REPORT_FILTER_LIST, GET_STATUS_REPORT_FILTER_LIST_FALIURE, GET_STATUS_REPORT_FILTER_LIST_SUCCESS, GET_WELLNESS_STATUS_REPORT, GET_WELLNESS_STATUS_REPORT_FALIURE, GET_WELLNESS_STATUS_REPORT_SUCCESS } from "../../constants";
import moment from "moment";

export const getWellnessStatusReportReq = async({data})=>{   
    let BODY={
        "from_date": data.startDate,
        "datetime": moment().format("YYYY-MM-DD hh:mm:ss"),
        "program_type": data.programType,
        "to_date": data.endDate,
        "status_filter": data.category,
        "status_sub_filter": data.status,
        "guest_status": data.guestStatus,
        "search_by": data.searchText,
        "sort_name": data.orderBy,
        "sort_type": data.order,
        "page": data.page,
        "limit": data.pageLimit
      }

    return axiosInstance.post(`${apiEndpoints.GET_WELLNESS_STATUS_REPORT_API}`, {data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getWellnessStatusReport({data}){
    try{
        let result = yield call(getWellnessStatusReportReq, {data})
        if(result.statusCode === 200){
            yield put({ type: GET_WELLNESS_STATUS_REPORT_SUCCESS, payload: result.data});  
        }  
    }catch(error){
        yield put({ type: GET_WELLNESS_STATUS_REPORT_FALIURE, message: error.message });
        console.log(error)

    }
}


export const getStatusReportFilterListReq = async({data})=>{   

    return axiosInstance.get(`${apiEndpoints.GET_STATUS_REPORT_FILTER_LIST_API}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                console.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getStatusReportFilterList({data}){
    try{
        let result = yield call(getStatusReportFilterListReq, {data})
        if(result.statusCode === 200){
            yield put({ type: GET_STATUS_REPORT_FILTER_LIST_SUCCESS, payload: result.data});  
        }  
    }catch(error){
        yield put({ type: GET_STATUS_REPORT_FILTER_LIST_FALIURE, message: error.message });
        console.log(error)

    }
}

function* StatusReportSaga(){
    yield takeLatest(GET_WELLNESS_STATUS_REPORT, getWellnessStatusReport)
    yield takeLatest(GET_STATUS_REPORT_FILTER_LIST, getStatusReportFilterList)
}

export default StatusReportSaga;