import { UPDATE_PROFILE } from '../../constants';


export function updateProfile(data) {
    return {
        type: UPDATE_PROFILE,
        data
    };
}

